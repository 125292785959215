import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

const EditVehicleModal = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { value, Mode, view } = state || {};
  const { id } = useParams();

  //   value?.VariantName || ""

  const convertName = (NAME) => {
    const pattern = /^([\d\sA-Za-z]+)/;
    const match = NAME.match(pattern);

    let MyName;
    if (match) {
      MyName = match[1];
    } else {
      MyName = NAME;
    }

    return MyName || "";
  };
  const [VariantName, setVariantName] = useState(value?.VariantName || "");
  const [FuelType, setFuelType] = useState(value?.FuelType || "");
  const [Seater, setSeater] = useState(value?.Seater || "");
  const [Engine, setEngine] = useState(value?.Engine || "");
  const [FuelTypeData, setFuelTypeData] = useState([]);

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/VehicleModal/${id}`, {
        VariantName,
        FuelType,
        Seater,
        Engine,
        _id: value._id,
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/VehicleModal/data");
      } else {
        ToastSusses(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getFuelType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/MakeModal/getAllFuelType");

      setFuelTypeData(
        data[0]["FuelType"].filter((ele) => {
          return ele !== null;
        }) ?? []
      );
      // setInsuranceTypeData(data);
    } catch (error) {}
  };
  useEffect(() => {
    getFuelType();
  }, []);
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="card-body">
              <h4 className="card-title">
                <h5>Edit VehicleModal</h5>
              </h4>
              <hr />
              <br />

              <div data-repeater-item="" className="outer">
                <div
                  className="col-md-6 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Variant Name</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Name"
                    value={VariantName}
                    onChange={(e) => setVariantName(e.target.value)}
                    placeholder="Enter VehicleModal Name"
                    required //done
                    disabled={view}
                  />
                </div>

                <div
                  className="col-md-6 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>FuelType</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setFuelType(e.target.value)}
                    value={FuelType}
                    required
                  >
                    <option value={""}>Select Fuel Type</option>
                    {FuelTypeData &&
                      FuelTypeData.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                    {/* <option value="self">self</option> */}
                  </select>
                </div>

                <div
                  className="col-md-6 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Seater</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Name"
                    value={Seater}
                    onChange={(e) => setSeater(e.target.value)}
                    placeholder="Enter Seater Data"
                    required //done
                    disabled={view}
                  />
                </div>

                <div
                  className="col-md-6 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Engine</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Engine"
                    value={Engine}
                    onChange={(e) => setEngine(e.target.value)}
                    placeholder="Enter Engine "
                    required //done
                    disabled={view}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/VehicleModal/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => handleEdit()}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default EditVehicleModal;
