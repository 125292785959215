import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
const CreateLedgerEntry = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [OpeningBalance, setOpeningBalance] = useState(value?.OpeningBalance || "");

  const [Group, setGroup] = useState(value?.Group?._id || "");

  const [GroupOpt, setGroupOpt] = useState();

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/LedgerEntry", {
        Name,
        Group,
        OpeningBalance
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/masters/LedgerEntry/data");
      } else {
        ToastSusses(message);
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
      // ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/LedgerEntry/${value?._id}`, {
        Name,
        Group,
        OpeningBalance
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/LedgerEntry/data");
      } else {
        ToastSusses(message);
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const getData = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/LedgerGroup");

      setGroupOpt(data.filter((e) => e._id !== value?._id));
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              zoom: "90%",
            }}
          >
            <h5>
              {view
                ? "View  Ledger Entry"
                : Mode
                ? "Edit Ledger Entry"
                : "Create New Ledger Entry"}
            </h5>
            <hr />

            <div className="row gap-3">
              <div
                className="col-4"
                style={{ float: "left", marginTop: "15px" }}
              >
                <label className="form-label" htmlFor="formname">
                  Name
                </label>
                <input
                  className="form-control "
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter  Name"
                  required //done
                  disabled={view}
                />
              </div>
              <div
                className="col-4"
                style={{ float: "left", marginTop: "15px" }}
              >
                <label className="form-label" htmlFor="formname">
                Opening Balance
                </label>
                <input
                  className="form-control "
                  type="Number"
                  name="OpeningBalance"
                  value={OpeningBalance}
                  onChange={(e) => setOpeningBalance(e.target.value)}
                  placeholder="Enter  Opening Balance"
                  required //done
                  disabled={view}
                />
              </div>
              <div
                className="col-4"
                style={{ float: "left", marginTop: "15px" }}
              >
                <label className="form-label" htmlFor="formemail">
                  Group
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setGroup(e.target.value)}
                  value={Group}
                  required
                  disabled={view}
                >
                  <option value={""}>Select Group</option>
                  {GroupOpt &&
                    GroupOpt.map((data) => (
                      <option value={data._id}>{data.Name}</option>
                    ))}
                  {/* <option value="self">self</option> */}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => navigate("/admin/masters/LedgerEntry/data")}
              >
                Go Back
              </button>{" "}
              &nbsp;
              {!view && (
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={() => {
                    Mode ? handleEdit() : handleCreate();
                  }}
                >
                  Submit
                </button>
              )}
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateLedgerEntry;
