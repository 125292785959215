import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableTransition from "../TableTransition/TableTransition";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import Select from "react-select";
import ExcelExport from "../utils/ExcelExport/ExcelExport";

const Rto = () => {
  const [data, setData] = useState();
  const [AllKey, setAllKey] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [firstRender, setFirstRender] = useState(false);
  const [inputData, setInput] = useState("");
  const [StateOpt, setStateOpt] = useState([]);
  const [State, setState] = useState({
    _id: "",
    StateName: "-- Select State --",
  });

  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };
  const getData = async (StateID) => {
    setLoading(true);
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/RTO?page=${
          pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}&State=${
          StateID ? StateID : ""
        }`
      );

      let Keys = [];
      if (data[0]) {
        Keys = Object.keys(data[0]);
      }
      setTotalDocs(totalDocs);
      setAllKey(
        Keys.filter(
          (e) =>
            e !== "_id" &&
            e !== "createdAt" &&
            e !== "__v" &&
            e !== "updatedAt" &&
            e !== "State"
        )
      );
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteRTO = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/RTO/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
      } else {
        ToastError(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const getState = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/State`);

      setStateOpt(data);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
    getState();
  }, []);

  useEffect(() => {
    if (!firstRender) {
      return;
    } else {
      const delayDebounceFn = setTimeout(getData, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [inputData]);
  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    }
    getData();
  }, [pagination, rowsPerPage]);

  const getExcelData = async () => {
    try {
      let rows = [];

      const {
        data: { success, data },
      } = await axiosInstance.get(
        `/RTO/getRTOForExcel?inputData=${inputData}&State=${
          State?._id ? State?._id : ""
        }`
      );

      if (success && data.length > 0) {
        const responseData = data;

        const updatedData = responseData.map((item) => ({
          ...item,
          State: item?.State?.StateName,
        }));

        rows = updatedData;
      }

      const columns = [
        { header: "RTO Name", key: "RTOName", width: 20 },
        { header: "RTO Code", key: "RTOCode", width: 20 },
        { header: "State", key: "State", width: 20 },
      ];

      return { columns, rows, fileName: "RTO List" };
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, RTOName, RTOCode"
      />
      <div
        className="container-fluid"
        style={{ marginTop: "70px", zoom: "85%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      // justifyContent:"space-between"
                    }}
                  >
                    <SearchInputFilter
                      setInput={setInput}
                      inputData={inputData}
                      PlaceHolder="Search by Name,Email,PosCode"
                    />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          StateName: "-- Select State --",
                        },
                        ...StateOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["StateName"]}
                      onChange={(ele) => {
                        getData(ele._id);
                        setState(ele);
                      }}
                      value={State}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <ExcelExport getData={getExcelData} />
                    <Link
                      className="btn mybtn"
                      to="/admin/masters/RTO"
                      state={{
                        Mode: false,
                        value: "",
                      }}
                    >
                      Add RTO
                    </Link>
                    {/* <Link
                      className="btn mybtn"
                      to="/admin/RTO/Duplicated"
                      state={{
                        Mode: false,
                        value: "",
                      }}
                    >
                      Check Duplicated RTO
                    </Link> */}
                    {/* <a
                        href="RTOCreate.html"
                        className="btn btn-primary inner mt-2 mt-sm-0"
                      >
                        Create
                      </a> */}
                  </div>
                </div>

                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    <TableTransition
                      AllKey={AllKey}
                      data={data}
                      editUrl={"/admin/masters/RTO"}
                      Mode={true}
                      deleteFunction={deleteRTO}
                      pagination={pagination}
                      rowsPerPage={rowsPerPage}
                      isRto={true}
                    />
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      >
                        <TablePagination
                          component="div"
                          className="text-white"
                          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                          count={totalDocs ?? 0}
                          page={pagination}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rto;
