import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableTransition from "../TableTransition/TableTransition";
import Select from "react-select";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { FaEye } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import AsyncSelect from "react-select/async";
const VehicleModal = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [inputData, setInput] = useState("");
  const [FuelTypeData, setFuelTypeData] = useState([]);
  const [MakeModal, setMakeModal] = useState({
    _id: "",
    Name: "-- Select Make Modal --",
  });
  const [FuelType, setFuelType] = useState("");

  const [DefaultMakeModalOptions, setDefaultMakeModalOption] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPagination(0);
  };
  const getData = async () => {
    setLoading(true);
    let que = {};
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    const insuranceType = SelectedInsuranceTypeData.length - 1;
    if (SelectedInsuranceTypeData[insuranceType]) {
      que["InsuranceType"] = SelectedInsuranceTypeData[insuranceType];
    }
    if (inputData) {
      que["inputData"] = inputData;
    }
    if (FuelType) {
      que["FuelType"] = FuelType;
    }
    if (MakeModal["_id"]) {
      que["MakeModal"] = MakeModal["_id"];
    }

    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.post(`/VehicleModal/getVehicleModal`, {
        page: pagination + 1,
        limit: rowsPerPage,
        ...que,
      });
      setTotalDocs(totalDocs);
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getDefaultMakeModalOption = async (
    insuranceType = "",
    reset = false
  ) => {
    try {
      if (!insuranceType) {
        setDefaultMakeModalOption((pre) => {
          return [{ _id: "", Name: "-- Select Make Modal --" }];
        });
      }
      const {
        data: { data = [] },
      } = await axiosInstance.get(
        `/MakeModal/getMakeModelSelectBox?InsuranceType=${insuranceType}&limit=${600}`
      );

      setDefaultMakeModalOption((pre) => {
        return [{ _id: "", Name: "-- Select Make Modal --" }, ...data];
      });
    } catch (error) {}
  };

  const EraserHandler = () => {
    setDefaultMakeModalOption([]);
   
  };
  // const MakeModalPromiseOptions = async (inputValue) => {
  //   try {
  //     const {
  //       data: { data },
  //     } = await axiosInstance.get(
  //       `/MakeModal/getMakeModelSelectBox?inputData=${inputValue}&InsuranceType=${insuranceType}`
  //     );
  //     return data;
  //   } catch (error) {}
  // };

  const deleteVehicleModal = async (MakeModal, _id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(
        `/VehicleModal/delete?MakeModal=${MakeModal}&_id=${_id}`
      );

      if (success) {
        getData();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    }
  };
  const getFuelType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/MakeModal/getAllFuelType");

      setFuelTypeData(
        data[0]["FuelType"].filter((ele) => {
          return ele !== null;
        }) ?? []
      );
      // setInsuranceTypeData(data);
    } catch (error) {}
  };

  useEffect(() => {
    callInsuranceData([]);
    getFuelType();
    // getDefaultMakeModalOption();
  }, []);
  useEffect(() => {
    getData();
  }, [pagination, rowsPerPage, inputData]);
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10" style={{ float: "left" }}>
                      <div className="ListDesigncol">
                        <h4 className="card-title">Vehicle Modal</h4>
                        <p className="card-title-desc">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                      </div>
                    </div>
                    <div className="col-2" style={{ float: "left" }}>
                      <Link className="btn mybtn" to="/admin/VehicleModal">
                        Add VehicleModal
                      </Link>
                    </div>
                  </div>

                  <div
                    // className=" p-2 container filters"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "unset",
                      flexDirection: "row",
                      gap: "10px",
                      zoom: "75%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <SearchInputFilter
                        setInput={setInput}
                        inputData={inputData}
                        PlaceHolder="Variant Name"
                      />
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setFuelType(e.target.value)}
                        value={FuelType}
                        required
                      >
                        <option value={""}>Select Fuel Type</option>
                        {FuelTypeData &&
                          FuelTypeData.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        {/* <option value="self">self</option> */}
                      </select>
                      <div>
                        <Select
                          style={{ backgroundColor: "#F5F5F6" }}
                          className="basic-single"
                          classNamePrefix="select"
                          getOptionValue={(option) => option["_id"]}
                          getOptionLabel={(option) => option["Name"]}
                          options={DefaultMakeModalOptions}
                          value={MakeModal}
                          onChange={(ele) => {
                            setMakeModal(ele);
                          }}
                        />
                      </div>

                      {/* For API Search */}
                      {/* <AsyncSelect
                        cacheOptions
                        defaultOptions={DefaultMakeModalOptions}
                        // loadOptions={MakeModalPromiseOptions}
                        onChange={(ele) => {
                          setMakeModal(ele);
                        }}
                        value={MakeModal}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                      /> */}
                    </div>

                    <div className="d-flex gap-2">
                      <div
                        className="searchBox"
                        style={{
                          width: "77px",
                          cursor: "pointer",
                        }}
                        onClick={() => getData()}
                      >
                        <MdSend />
                      </div>
                      {/* <div
                        className="searchBox"
                        style={{
                          width: "77px",
                          cursor: "pointer",
                        }}
                        onClick={() => EraserHandler()}
                      >
                        <BsEraser />
                      </div> */}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "unset",
                      flexDirection: "row",
                      gap: "10px",
                      zoom: "75%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {SelectedInsuranceType &&
                        SelectedInsuranceType?.length > 0 &&
                        SelectedInsuranceType.map(
                          (data, index) =>
                            data &&
                            data?.data?.length > 0 && (
                              <MultiInsurance
                                key={data.value}
                                Options={data}
                                setSelectedInsuranceType={
                                  setSelectedInsuranceType
                                }
                                SelectedInsuranceType={SelectedInsuranceType}
                                index={index}
                                OptionsValue={data.value}
                                IsFilter={true}
                                getMakeModal={getDefaultMakeModalOption}
                              />
                            )
                        )}
                    </div>
                  </div>
                  {loading ? (
                    <div
                      className=" p-2 container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                      }}
                    >
                      <TableSkeleton />
                    </div>
                  ) : (
                    <div
                      style={{
                        zoom: "70%",
                      }}
                    >
                      {/* <TableTransition
                        AllKey={AllKey}
                        data={data}
                        editUrl={"/admin/VehicleModal"}
                        Mode={true}
                        deleteFunction={d
                          eleteVehicleModal}
                      /> */}

                      {data && (
                        <table class="table table-striped   table-bordered  overflow-x mt-3">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Modal Name</th>
                              <th scope="col">Variant Name</th>
                              <th scope="col">FuelType</th>
                              <th scope="col">Insurance Type</th>
                              <th scope="col">EXTRA_OPTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.length !== 0 &&
                              data.map((data, index) => (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{data?.Name}</td>
                                  <td>{data?.Variant?.VariantName}</td>
                                  <td>{data?.Variant?.FuelType}</td>

                                  <td>{data?.InsuranceType?.InsuranceType}</td>

                                  <th scope="row">
                                    {" "}
                                    <div className="col-md-2">
                                      <div className="d-flex gap-3">
                                        <div>
                                          <Link
                                            className=""
                                            state={{
                                              view: true,
                                            }}
                                            to={`/admin/VehicleModal/${data._id}`}
                                          >
                                            <FaEye size={25} color="" />{" "}
                                          </Link>
                                        </div>
                                        <div className="">
                                          <Link
                                            className=""
                                            state={{
                                              value: data?.Variant,
                                            }}
                                            to={`/admin/VehicleModal/${data._id}`}
                                          >
                                            <BiEdit size={25} color="blue" />{" "}
                                          </Link>
                                        </div>
                                        <div className="">
                                          <AiFillDelete
                                            color="red"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            size={25}
                                            onClick={() => {
                                              const isTrue = window.confirm(
                                                "Do you want to delete!"
                                              );
                                              if (isTrue) {
                                                deleteVehicleModal(
                                                  data?._id,
                                                  data?.Variant?._id
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}

                      {
                        <div
                          style={{
                            backgroundColor: "#1a2e45d7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            marginTop: "5px",
                          }}
                        >
                          <TablePagination
                            component="div"
                            className="text-white"
                            rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                            count={totalDocs ?? 0}
                            page={pagination}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      }
                    </div>
                  )}
                </div>
              </div>

              {/* end col */}
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default VehicleModal;
