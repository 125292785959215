import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/Footer";
import { BiEdit } from "react-icons/bi";
import Header from "../Header/Header";
import Select from "react-select";
const CreateContra = () => {
  const MASTER_DB = ["CASH", "BANK"];

  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const Mode = Boolean(queryParams.get("Mode"));

  const [EntryDate, setEntryDate] = useState(lastDay);

  const [Remark, setRemark] = useState("");

  const [PartyAccount, setPartyAccount] = useState("");
  const [PartyAccountOpt, setPartyAccountOpt] = useState([]);
  const [Account, setAccount] = useState("");
  const [RefNumber, setRefNumber] = useState("");

  const [Contra, setContra] = useState([]);
  // value?.Contra
  const [FromModal, setFromModal] = useState();
  const [From, setFrom] = useState("");
  const [PartyAccountOptFor, setPartyAccountOptFor] = useState([]);
  const [CashBankAccount, setCashBankAccount] = useState();
  // const [PartyAccountOptFor2, setPartyAccountOptFor2] = useState([]);

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const que = {};

      if (FromModal === "BANK") {
        if (!CashBankAccount._id) {
          return ToastError("BANK is required");
        }
        que["CashBankAccount"] = CashBankAccount._id;
      } else if (FromModal === "CASH") {
        if (!CashBankAccount._id) {
          return ToastError("LedgerGroup is required");
        }
        que["LedgerEntry"] = CashBankAccount._id;
      }
      const {
        data: { success, message },
      } = await axiosInstance.post("/Contra", {
        ...que,
        EntryDate,
        // CashBankAccount,
        Remark,
        Contra,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Contra/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const que = {};

      if (FromModal === "BANK") {
        if (!CashBankAccount._id) {
          return ToastError("BANK is required");
        }
        que["CashBankAccount"] = CashBankAccount._id;
      } else if (FromModal === "CASH") {
        if (!CashBankAccount._id) {
          return ToastError("LedgerGroup is required");
        }
        que["LedgerEntry"] = CashBankAccount._id;
      }
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Contra/${_id}`, {
        ...que,
        EntryDate,
        // CashBankAccount,
        Remark,
        Contra,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Contra/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const removeHandler = (index) => {
    const ContraArr = Contra;
    ContraArr.splice(index, 1);
    setContra([...ContraArr]);
  };

  const editHandler = (data, index) => {
    if (Account || RefNumber || Account) {
      return ToastWarn("Please Add To List First");
    }

    if (data.From === "CASH") {
      if (data?.LedgerEntry) {
        setPartyAccount({ _id: data?.LedgerEntry, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    if (data.From === "BANK") {
      if (data?.PartyAccount) {
        setPartyAccount({ _id: data?.PartyAccount, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    setFrom(data.From);
    getDebitAccount(data.From);

    setAccount(data.Account);
    setRefNumber(data.RefNumber);
    const ContraArr = Contra;
    ContraArr.splice(index, 1);

    setContra([...ContraArr]);
  };
  const addHandler = () => {
    if (!PartyAccount || !Account) {
      return ToastWarn("Please enter a valid field");
    }

    const que = {};
    const PartyAccountData = PartyAccount;

    if (From === "BANK") {
      if (!PartyAccountData?._id) {
        return ToastWarn("Please enter a Party Account");
      }
      que["PartyAccount"] = PartyAccountData._id;
    } else if (From === "CASH") {
      if (!PartyAccountData?._id) {
        return ToastWarn("Please enter a Party Account");
      }
      que["LedgerEntry"] = PartyAccountData._id;
    }

    setContra([
      ...Contra,
      {
        // PartyAccount: PartyAccountData._id,
        Account: Number(Account),
        RefNumber,
        Name: PartyAccountData.Name,
        From: From,
        ...que,
      },
    ]);
    setPartyAccountOpt([]);
    setPartyAccount("");
    setAccount("");
    setRefNumber("");
    setFrom("");
  };

  const getPosPartyAccount = async (isData) => {
    try {
      if (isData === "BANK") {
        const {
          data: { data },
        } = await axiosInstance.get("/Bank");

        // setCashBankAccountOpt([...data, ...LedgerGroup]);
        setPartyAccountOptFor(data);
      }

      if (isData === "CASH") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry/getCashAccount");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOptFor(LedgerGroup);
      }
    } catch (error) {}
  };
  const getDebitAccount = async (isData) => {
    try {
      if (isData === "BANK") {
        const {
          data: { data },
        } = await axiosInstance.get("/Bank");

        // setCashBankAccountOpt([...data, ...LedgerGroup]);
        setPartyAccountOpt(data);
      }

      if (isData === "CASH") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry/getCashAccount");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
    } catch (error) {}
  };

  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/Contra/single/${_id}`);

      getPosPartyAccount(value?.FromModal);
      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      setContraHandler(value?.Contra);
      setEntryDate(value?.EntryDate);
      setCashBankAccountHandler(value);
    } catch (error) {}
  };

  const setContraHandler = (Contra) => {
    const myArr = Contra?.map((ele) => {
      const { LedgerEntry: Entry, PartyAccount: Account, _id, ...rest } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }

      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      return obj;
    });

    setContra(myArr ?? []);
  };
  const setCashBankAccountHandler = (value) => {
    const { LedgerEntry: Entry, CashBankAccount: Bank } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (Bank) {
      const { _id, Name } = Bank;
      obj = {
        _id,
        Name,
      };
    }
    setCashBankAccount(obj);
  };
  useEffect(() => {
    if (_id) {
      getDataById();
    }
  }, [_id]);
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>{Mode ? "Edit Contra" : "Create Contra"}</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter Contra "
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  onChange={(e) => {
                    setFromModal(e.target.value);
                    getPosPartyAccount(e.target.value);
                    setCashBankAccount({
                      _id: "",
                      Name: "-- Select --",
                    });
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>Credit Account</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOptFor}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setCashBankAccount}
                  value={CashBankAccount}
                />
                {/* <select
                 
                  class="form-select"
                  aria-label="Default select example"
                  name="CashBankAccount"
                  value={CashBankAccount}
                  onChange={(e) => setCashBankAccount(e.target.value)}
                  required
                >
                  <option value="">
                    -------------Select Bank-------------
                  </option>
                  {CashBankAccountOpt &&
                    CashBankAccountOpt.map((data) => (
                      <option value={data._id}>{data.Name}</option>
                    ))}
           
                </select> */}
              </div>
              <div className="col-md-3 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={From}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    getDebitAccount(e.target.value);
                    setPartyAccount({
                      _id: "",
                      Name: "-- Select --",
                    });
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>Debit Account</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOpt}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setPartyAccount}
                  value={PartyAccount}
                />
              </div>
              <div className="col-md-3 align-items-center">
                <label>Account</label>
                <input
                  className="form-control "
                  type="number"
                  name="Account"
                  value={Account}
                  onChange={(e) => setAccount(e.target.value)}
                  placeholder="Amount"
                  required //done
                />
              </div>
              <div className="col-md-3 align-items-center">
                <label>Ref Number</label>
                <input
                  className="form-control "
                  type="text"
                  name="Ref Numbe"
                  value={RefNumber}
                  onChange={(e) => setRefNumber(e.target.value)}
                  placeholder="Ref Numbe"
                  required //done
                />
              </div>
              <div
                className="col-md-2 align-items-center"
                style={{
                  paddingTop: "1.4rem",
                }}
              >
                <button
                  className="btn mybtn"
                  onClick={() => {
                    addHandler();
                  }}
                >
                  Add to list
                </button>
              </div>
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Debit Account</th>

                        <th scope="col">Amount</th>
                        <th scope="col">Reff. No.</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Contra &&
                        Contra.length > 0 &&
                        Contra?.map((ele, index) => (
                          <tr>
                            <td scope="row">{index + 1}</td>
                            <td>{ele?.Name || ele?.PartyAccount?.Name}</td>
                            <td>{ele?.Account}</td>
                            <td>{ele?.RefNumber}</td>
                            <td>
                              <BiEdit
                                size={32}
                                style={{
                                  backgroundColor: "green",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => editHandler(ele, index)}
                              />
                              &nbsp;
                              <AiFillDelete
                                size={32}
                                style={{
                                  backgroundColor: "#A50406",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => {
                                  const isTrue = window.confirm(
                                    "Do you want to delete!"
                                  );
                                  if (isTrue) {
                                    removeHandler(index);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <th scope="row"></th>
                        <td>Total</td>
                        <td>
                          {Contra &&
                            Contra.length > 0 &&
                            Contra.reduce((sum, a) => sum + a.Account, 0)}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                className="btn mybtn "
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateContra;
