import React, { useEffect, useState } from "react";
import { ToastError } from "../../Utils/Tost";
import { axiosInstance } from "../../config";
import { Chart } from "react-google-charts";
import "./Dashboard.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import EmployeeReport from "./EmployeeReport";
const Dashboard = () => {
  const [InsuranceTypes, setInsuranceTypes] = useState([]);
  const [InsuranceCompanies, setInsuranceCompanies] = useState([]);
  const [PolicyStatue, setPolicyStatue] = useState([]);
  const getInsurancecompanies = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/getPolicyReport?dbName=insurancecompanies&localFieldName=InsuranceCompany&asToSave=InsuranceCompany&groupUpKey=InsuranceCompany.AliasName`
      );

      setInsuranceCompanies([["Company Name", "Insurance"], ...data]);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const getStatus = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/getPolicyReportByFiledName?groupUpKey=Status`
      );
      const length = data.length;

      data.forEach((ele, index) => {
        ele["percent"] = ((ele.count / length) * 100).toFixed(0);
      });
      // let myStatus = {
      //   PENDING: 0,
      //   APPROVED: 0,
      //   HOLD: 0,
      //   REJECTED: 0,
      // };
      // [
      //   { _id: 'APPROVED', count: 5190, percent: '259500' },
      //   { _id: 'PENDING', count: 381, percent: '19050' }
      // ]

      setPolicyStatue(data);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getInsuranceTypes = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/getPolicyReport?dbName=insurancetypes&localFieldName=InsuranceType&asToSave=InsuranceType&groupUpKey=InsuranceType.InsuranceType`
      );

      setInsuranceTypes([["", ""], ...data]);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const options = {
    title: "Insurance Types Report",
    is3D: true,
  };

  const CompaniesOptions = {
    chart: {
      title: "Company Performance",
      // subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };

  useEffect(() => {
    getInsuranceTypes();
    getInsurancecompanies();
    getStatus();
  }, []);

  const downloadReport = async () => {
    try {
      const response = await axiosInstance.get("Excel/getExcelReport", {
        responseType: "blob", // Ensures the response is treated as a Blob
      });

      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "report.xlsx";

      // Create a link element to trigger the download
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  return (
    <>
      <Header />
      <div data-topbar="dark" data-layout="horizontal" data-layout-size="boxed">
        <div className="page-content">
          {/* <button className="mybtn btn" onClick={downloadReport}>
            Download Report
          </button> */}
          <div className="container-fluid">
            <br />
            <div className="row">
              {PolicyStatue.map((data, i) => (
                <div className="col-xl-3 col-md-6">
                  <div className="card mini-stat cardBgColor text-white">
                    <div className="card-body">
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img
                            src="https://img.freepik.com/premium-photo/image-colorful-galaxy-sky-generative-ai_791316-9864.jpg?w=2000"
                            alt=""
                          />
                        </div>
                        <h5 className="font-size-16 text-uppercase text-white-50">
                          {data?._id}
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {data?.count}
                          <i className="mdi mdi-arrow-up text-success ms-2" />
                        </h4>
                        <div className={`mini-stat-label ${data?._id}`}>
                          <p className="mb-0"> {data?.percent ?? 0}%</p>
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <a href="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5" />
                          </a>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* end row */}
            <div className="row mt-3">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Pie Chart</h4>
                    <div
                      style={{
                        height: "300px",
                      }}
                    >
                      {InsuranceTypes.length === 0 ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={"300px"}
                          />
                        </>
                      ) : (
                        <Chart
                          chartType="PieChart"
                          data={InsuranceTypes}
                          options={options}
                          width={"100%"}
                          height={"300px"}
                        />
                      )}
                    </div>

                    {/* <canvas id="pie" height={260} /> */}
                  </div>
                </div>
              </div>
              {/* end col */}
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Bar Chart</h4>
                    {/* <canvas id="doughnut" height={260} /> */}
                    <div
                      style={{
                        height: "300px",
                      }}
                    >
                      {InsuranceCompanies.length === 0 ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={"300px"}
                          />
                        </>
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="300px"
                          data={InsuranceCompanies}
                          options={CompaniesOptions}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
