import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";

import Footer from "../Footer/Footer";

import Header from "../Header/Header";
import Select from "react-select";
const ViewCreditNotes = () => {
  const MASTER_DB = ["LedgerGroup", "POS", "Broker"];
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const { id } = useParams();

  const [EntryDate, setEntryDate] = useState(lastDay);

  const [PartyAccountLedger, setPartyAccountLedger] = useState({
    _id: "",
    Name: "-- Select --",
  });

  const [Remark, setRemark] = useState("");
  const [FromModal, setFromModal] = useState("");

  const [CreditNotes, setCreditNotes] = useState([]);

  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/CreditNotes/single/${id}`);

      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      setCreditNotesHandler(value?.CreditNotes);
      setEntryDate(value?.EntryDate);
      setPaymentListHandler(value);
    } catch (error) { }
  };
  const setCreditNotesHandler = (CreditNotes = []) => {
    const myArr = CreditNotes?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        _id,
        ...rest
      } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }
      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      return obj;
    });
    setCreditNotes(myArr);
  };

  const setPaymentListHandler = (value) => {
    const {
      LedgerEntry: Entry,
      PartyAccount: Account,
      Broker: BrokerDetails,
    } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (BrokerDetails) {
      const { _id, Name } = BrokerDetails;
      obj = {
        _id,
        Name,
      };
    }
    if (Account) {
      const { _id, Name } = Account;
      obj = {
        _id,
        Name,
      };
    }
    setPartyAccountLedger(obj);
  };
  useEffect(() => {
    if (id) {
      
      getDataById();
    }
  }, [id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>View Credit Notes</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  //   onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter CreditNotes "
                  required //done
                />
              </div>

              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-4 align-items-center">
                <label>Party Account Ledger</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  //   options={PartyAccountOptFor}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  //   onChange={setPartyAccountLedger}
                  value={PartyAccountLedger}
                />
                {/* <select
                 
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccountLedger"
                  value={PartyAccountLedger}
                //   onChange={(e) => {
                    setPartyAccountLedger(e.target.value);
                  }}
                  required
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOptFor &&
                    PartyAccountOptFor.map((data) => (
                      <option value={data._id}>{data.Name}</option>
                    ))}
                 
                </select> */}
              </div>
              <div className="col-md-4 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  //   onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">PartyAccount</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Narration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CreditNotes.map((ele, index) => (
                        <tr>
                          <td scope="row">{index + 1}</td>
                          <td>{ele?.Name}</td>
                          <td>{ele?.Account}</td>
                          <td>{ele?.RefNumber}</td>
                        </tr>
                      ))}
                      <tr>
                        <th scope="row"></th>
                        <th>Total</th>
                        <th>
                          {CreditNotes.reduce((sum, a) => sum + a.Account, 0)}
                        </th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>


          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <Link className="btn mybtn" to="/admin/CreditNotes/data">
              Go Back
            </Link>
            <Link
              className="btn mybtn"
              to={`/admin/CreditNotes?_id=${id}&Mode=true`}
            >
              Edit
            </Link>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewCreditNotes;
