import React, { useState } from "react"; import { ToastError, ToastSusses, ToastInfo, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

const CreatePincode = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [Pincode, setPincode] = useState(value?.Pincode || "");
  const [PincodeLocation, setPincodeLocation] = useState(
    value?.PincodeLocation || ""
  );
  const [CityCode, setCityCode] = useState(value?.CityCode || "");
  const [StateCode, setStateCode] = useState(value?.StateCode || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Pincode", {
        Pincode,
        PincodeLocation,
        CityCode,
        StateCode,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/Pincode/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Pincode/${value?._id}`, {
        Pincode,
        PincodeLocation,
        CityCode,
        StateCode,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/Pincode/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="col-lg-12">

              <div className="">
                <h4 className="card-title">
                  {view ? "View Pincode" : Mode ? "Edit Pincode" : "Create Pincode"}
                </h4>
                <hr />
                <div className="">

                  <div
                    className="col-md-6 align-items-center"
                    style={{ float: "left", padding: 15 }}
                  >
                    <label>Pincode</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="Pincode"
                      value={Pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      placeholder="Enter Pincode Number"
                      required //done
                    />
                  </div>
                  <div
                    className="col-md-6 align-items-center"
                    style={{ float: "left", padding: 15 }}
                  >
                    <label>PincodeLocation</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="PincodeLocation"
                      value={PincodeLocation}
                      onChange={(e) => setPincodeLocation(e.target.value)}
                      placeholder="Pincode Location Name"
                      required //done
                    />
                  </div>
                  <div
                    className="col-md-6 align-items-center"
                    style={{ float: "left", padding: 15 }}
                  >
                    <label>CityCode</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="CityCode"
                      value={CityCode}
                      onChange={(e) => setCityCode(e.target.value)}
                      placeholder="Enter City Code"
                      required //done
                    />
                  </div>
                  <div
                    className="col-md-6 align-items-center"
                    style={{ float: "left", padding: 15 }}
                  >
                    <label>StateCode</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="StateCode"
                      value={StateCode}
                      onChange={(e) => setStateCode(e.target.value)}
                      placeholder="Enter State Code"
                      required //done
                    />
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="d-flex gap-3 ">

            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/Pincode/data")}
            >
              Go Back
            </button>
            {!view && <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => {
                Mode ? handleEdit() : handleCreate();
              }}
            >
              Submit
            </button>}


          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreatePincode;
