import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
// import bloodIcon from "../../assets/loginPageImg/blood-donation.png";
import { axiosInstance } from "../../config";
import { useDispatch, useSelector } from "react-redux";
const Login = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [OTP, setOTP] = useState("");
  const [isEmailEntered, setisEmailEntered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [User, setUser] = useState({});

  const loginHandler = async () => {
    setLoading(true);
    if (!PhoneNumber) {
      return ToastError("Please enter a phone number");
    }

    try {
      const {
        data: { success, message, user },
      } = await axiosInstance.post("/Employee/EmployeeLogin", {
        PhoneNumber,
      });

      if (success) {
        ToastSusses(message);
        setisEmailEntered(true);
        setUser(user);
      } else {
        ToastSusses(message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToastError(error?.response?.data?.message);
    }
  };
  const loginValidateHandler = async () => {
    if (!OTP) {
      return ToastError("Please enter a OTP");
    }
    setLoading(true);
    dispatch({
      type: "LoginRequest",
    });
    try {
      const {
        data: { success, message, user },
      } = await axiosInstance.post(
        "/Employee/ValidateLogin",
        {
          OTP,
          _id: User._id,
        },
        { withCredentials: true }
      );

      if (success) {
        ToastSusses(message);
        dispatch({
          type: "LoginSuccess",
          payload: user,
        });
      } else {
        dispatch({
          type: "LoginFailure",
          // payload: user
        });

        ToastSusses(message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToastError(error?.response?.data?.message);
    }
  };
  const { isAuthenticated } = useSelector((store) => store.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin/Dashboard");
    }
  }, [isAuthenticated]);
  return (
    <div
      className="login-container"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isEmailEntered ? (
        <>
          <div class="form-container-login">
            <div class="form-login">
              <div class="title-otp">OTP Verification Code</div>{" "}
              <p class="message-otp">
                We have sent a verification code at your registered email id
              </p>
              <ShowEmail Email={User?.Email} />
              <div class="form-group-login">
                <label className="Login-label" required>
                  Enter OTP
                </label>
                <input
                  type="number"
                  id="OTP"
                  name="OTP"
                  placeholder="Enter your 6 Digits OTP code"
                  className="login-PhoneNumber"
                  onChange={(e) => setOTP(e.target.value)}
                  value={OTP}
                />
              </div>
              <div className="Otp-send-button">
                {loading ? (
                  <button class="form-submit-btn">Loading...</button>
                ) : (
                  <button
                    class="form-submit-btn"
                    onClick={() => {
                      loginValidateHandler();
                    }}
                  >
                    Verify
                  </button>
                )}
                <button
                  class="form-submit-btn"
                  onClick={() => {
                    setisEmailEntered(false);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div class="form-container-login">
          <div class="logo-container">Login/Signup</div>

          <div class="form-login">
            <div class="form-group-login">
              {/* <label className="Login-label" required>
                Phone Number
              </label> */}
              <input
                type="number"
                id="PhoneNumber"
                name="PhoneNumber"
                placeholder="Enter your Phone Number"
                className="login-PhoneNumber"
                required=""
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={PhoneNumber}
              />
            </div>

            {loading ? (
              <button class="form-submit-btn">Loading...</button>
            ) : (
              <button
                class="form-submit-btn"
                onClick={() => {
                  loginHandler();
                }}
              >
                Send OTP
              </button>
            )}
          </div>

          {/* <p class="signup-link">
            Don't have an account?
            <Link class="signup-link link"> Sign up now</Link>
          </p> */}
        </div>
      )}
    </div>
  );
};

export default Login;

const ShowEmail = ({ Email = "" }) => {
  const emailData = String(Email).split("@");

  return (
    <p class="message-otp">
      {emailData[0].slice(0, 6)}******@{emailData[1] ?? ""}
    </p>
  );
};

// {isEmailEntered ? (
//   <input
//     class="form-control"
//     type="number"
//     onChange={(e) => setOTP(e.target.value)}
//     value={OTP}
//     placeholder="Enter Your Otp "
//   />
// ) : (
//   <input
//     class="form-control"
//     type="number"
//     onChange={(e) => setPhoneNumber(e.target.value)}
//     value={PhoneNumber}
//     placeholder="Enter Your Phone Number"
//   />
// )}

// {loading ? (
//   <button type="button" class="btn btn-danger">
//     Loading...
//   </button>
// ) : (
//   <button
//     type="button"
//     class="btn btn-danger"
//     onClick={() => {
//       if (isEmailEntered) {
//         loginValidateHandler();
//       } else {
//         loginHandler();
//       }
//     }}
//   >
//     Login
//   </button>
// )}
