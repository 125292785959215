import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Preloader from "../Skeleton/Preloader";
import Login from "../backend/Login/Login";
const ProtectedRoute = () => {
  const { isAuthenticated, isLoadingRequest } = useSelector(
    (store) => store.user
  );

  if (isLoadingRequest) return <Preloader />;

  if (!isAuthenticated && !isLoadingRequest) {
    return <Login />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
