import React, { useState } from "react";
import {
  ToastError,
} from "../../Utils/Tost";
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const VehicleRcNumber = () => {
  const [VehicleRcNumber, setVehicleRcNumber] = useState();
  const [VehicleRcInformation, setVehicleRcInformation] = useState({});

  const getVehicleRcNumber = async () => {
    try {
      const { data } = await axios.post(
        "https://vehicle-rc-information.p.rapidapi.com/",
        {
          VehicleNumber: String(VehicleRcNumber).toUpperCase(),
        },
        {
          headers: {
            "content-type": "application/json",
            "X-RapidAPI-Key":
              "dd251d62bcmsh8e7b3ec187157aap1c9db3jsnf3c6bcc87d02",
            "X-RapidAPI-Host": "vehicle-rc-information.p.rapidapi.com",
          },
        }
      );
      
      if (data?.result) {
        // ToastSusses(message);
        // navigate("/admin/Manufacturer/data");
        setVehicleRcInformation(data.result);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  // manufacturer_model ,fuel_type, registration_date, variant, insurance_name,state
  const onChangeSetValue = (e) => {
    setVehicleRcInformation({
      ...VehicleRcInformation,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12">
              <div className="">
                <div className="container">
                  <div
                    className="row mx-2 CreateCard"
                    style={{
                      padding: "1.5rem",
                      minHeight: "30vh",
                    }}
                  >
                    <h5>Enter Vehicle Number</h5>
                    {/* Employee Information */}
                    <div className="col-md-6 align-items-center">
                      <label>Enter Vehicle Number</label>
                      <input
                        className="form-control "
                        type="text"
                        name="VehicleRcNumber"
                        value={VehicleRcNumber}
                        onChange={(e) => setVehicleRcNumber(e.target.value)}
                        placeholder="Enter Vehicle number"
                        required //done
                      />
                    </div>
                    <div className="col-md-6 align-items-center">
                      <button
                        className="btn m-4"
                        style={{
                          backgroundColor: "#03254d",
                          borderRadius: "7px",
                          color: "white",
                        }}
                        onClick={() => {
                          getVehicleRcNumber();
                        }}
                      >
                        Get Vehicle Details
                      </button>
                    </div>
                  </div>

                  <div
                    className="row mx-2 CreateCard"
                    style={{
                      padding: "1.5rem",
                      // minHeight:"30vh"
                    }}
                  >
                    {/*  */}
                    <h5>View Vehicle Rc-information</h5>
                    {/* Employee Information */}
                    <div className="col-md-6 align-items-center">
                      <label>manufacturer_model</label>
                      <input
                        className="form-control "
                        type="text"
                        name="manufacturer_model"
                        value={VehicleRcInformation?.manufacturer_model}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder=" manufacturer_model "
                        required //done
                      />
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label>fuel_type</label>
                      <input
                        className="form-control "
                        type="text"
                        name="fuel_type"
                        value={VehicleRcInformation?.fuel_type}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="fuel type "
                        required //done
                      />
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label>registration_date</label>
                      <input
                        className="form-control "
                        type="text"
                        name="registration_date"
                        value={VehicleRcInformation?.registration_date}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="registration date"
                        required //done
                      />
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label>variant</label>
                      <input
                        className="form-control "
                        type="text"
                        name="variant"
                        value={VehicleRcInformation?.variant}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="variant"
                        required //done
                      />
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label>insurance_name</label>
                      <input
                        className="form-control "
                        type="text"
                        name="insurance_name"
                        value={VehicleRcInformation?.insurance_name}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="insurance_name"
                        required //done
                      />
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label>State/Rto</label>
                      <input
                        className="form-control "
                        type="text"
                        name="state"
                        value={VehicleRcInformation?.state}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="state"
                        required //done
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default VehicleRcNumber;
