import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

const CreatePolicyType = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};

  const [PolicyTypeName, setPolicyTypeName] = useState(
    value?.PolicyTypeName || ""
  );
  const [formData, setFormData] = useState({
    ODApplicable: "No",
    TPApplicable: "No",
    LLApplicable: "No",
  });

  useEffect(() => {
    if (value) {
      setFormData(value);
    }
  }, [value]);

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/PolicyType", {
        PolicyTypeName,
        ...formData,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/PolicyType/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/PolicyType/${value?._id}`, {
        PolicyTypeName,
        ...formData,
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/masters/PolicyType/data");
      } else {
        ToastSusses(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="card-body">
              <h4 className="card-title">
                <h5>
                  {view
                    ? "View Policy Type"
                    : Mode
                    ? "Edit PolicyType"
                    : "Create PolicyType"}
                </h5>
              </h4>
              <hr />
              <div>
                <div className="col-lg-4" style={{ float: "left" }}>
                  <div className="col-lg-11">
                    <label>Policy Type Name</label>
                    <input
                      className="form-control "
                      type="text"
                      name="PolicyTypeName"
                      value={PolicyTypeName}
                      onChange={(e) => setPolicyTypeName(e.target.value)}
                      placeholder="Enter PolicyType Name"
                      required //done
                      disabled={view}
                    />
                  </div>
                </div>
                <div className="col-lg-4" style={{ float: "left" }}>
                  <div className="col-lg-11">
                    <label>Is OD Prem. Applicable</label>
                    <div className="">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="ODApplicable"
                        value={formData?.ODApplicable}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            ODApplicable: e.target.value,
                          }))
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ float: "left" }}>
                  <div className="col-lg-11">
                    <label>Is TP Prem. Applicable</label>
                    <div className="">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="TPApplicable"
                        value={formData?.TPApplicable}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            TPApplicable: e.target.value,
                          }))
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ float: "left" }}>
                  <div className="col-lg-11">
                    <label>Is LL Prem. Applicable</label>
                    <div className="">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="TPApplicable"
                        value={formData?.LLApplicable}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            LLApplicable: e.target.value,
                          }))
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/PolicyType/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreatePolicyType;
