import React, { useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

const AddMake = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};

  const [Make, setMake] = useState(value?.Make || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Make", {
        Make,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Make/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Make/${value?._id}`, {
        Make,
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/masters/Make/data");
      } else {
        ToastSusses(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="card-body">
              <h4 className="card-title">
                <h5>
                  {view ? "View Make" : Mode ? "Edit Make" : "Create Make"}
                </h5>
              </h4>
              <hr />
              <div data-repeater-item="" className="outer">
                <div className="col-6" style={{ float: "left", padding: 15 }}>
                  <label>Make Name</label>
                  <input
                    className="form-control "
                    type="text"
                    name="MakeName"
                    value={Make}
                    onChange={(e) => setMake(e.target.value)}
                    placeholder="Enter Make Name"
                    required //done
                    disabled={view}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/Make/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default AddMake;
