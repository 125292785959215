import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { ToastError, ToastSusses } from "../../Utils/Tost";
const RemoveRtos = () => {

    const { id } = useParams()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [DeleteValue, setDeleteValue] = useState("");

    const getData = async () => {
        setLoading(true);
        try {
            const {
                data: { data },
            } = await axiosInstance.get(`/RTO/getDataByRtoCode/${id}`);

            setData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const updateData = async () => {
        setLoading(true);
        try {

            const updateId = data.filter(ele => ele._id !== DeleteValue)[0]._id

            await axiosInstance.get(`/RTO/deleteDuplicate?updateId=${updateId}&DeleteValue=${DeleteValue}`);
            ToastSusses("Delete successfully")
            navigate("/admin/RTO/Duplicated")
            // setData(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };




    useEffect(() => {

        getData();
    }, []);
    return (
        <>
            <Header
                // setInput={setInput}
                // inputData={inputData}
                placeHolder="Search by, RTOName, RTOCode"
            />
            <div
                className="container-fluid"
                style={{ marginTop: "70px", zoom: "85%" }}
            >
                <div className="row CreateCard">
                    <div className="col-lg-12">
                        <div className="card-body">

                            {loading ? (
                                <div
                                    className=" p-2 container"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <TableSkeleton />
                                </div>
                            ) : (
                                <>
                                    <table class="table table-striped table-bordered overflow-x mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ textAlign: "center" }}>
                                                    #
                                                </th>
                                                <th scope="col" style={{ textAlign: "center" }}>
                                                    Name
                                                </th>
                                                <th scope="col" style={{ textAlign: "center" }}>
                                                    RTO CODE
                                                </th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length !== 0 &&
                                                data.map((ele, index) => <>
                                                    <tr>

                                                        <th scope="row" className="selectBoxForTable">
                                                            <input
                                                                type="checkbox"
                                                                id="vehicle2"
                                                                name="vehicle2"
                                                                value="Car"
                                                                key={ele._id}
                                                                checked={DeleteValue === ele._id}
                                                                style={{
                                                                    width: "23px",
                                                                    height: "23px",
                                                                }}
                                                                onClick={(e) => {
                                                                    setDeleteValue(ele._id);
                                                                }}


                                                            />
                                                        </th>

                                                        <td> <Link to={`/admin/RTO`} state={{
                                                            value: ele,
                                                            Mode: true,
                                                        }}
                                                        >{ele.RTOName}</Link></td>
                                                        <td>{ele.RTOCode}</td>
                                                    </tr>
                                                </>)}
                                        </tbody>
                                    </table>

                                    <div className="d-flex gap-3 justify-content-center">
                                        <Link
                                            type="submit"
                                            className="btn mybtn"
                                            // style={{ marginTop: 28 }}
                                            to={"/admin/RTO/Duplicated"}
                                        >
                                            Go Back
                                        </Link>

                                        {
                                            <button
                                                type="submit"
                                                className="btn bg-danger text-white"
                                                onClick={() => updateData()}
                                            >
                                                Delete
                                            </button>
                                        }
                                    </div>
                                </>
                            )}
                        </div>

                        {/* end col */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default RemoveRtos;
