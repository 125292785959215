import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Footer from "../Footer/Footer";

import Header from "../Header/Header";
import Select from "react-select";
const ViewReceipt = () => {
  const { id } = useParams();

  const MASTER_DB = ["CASH", "BANK"];
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);

  const [EntryDate, setEntryDate] = useState(lastDay);

  const [CashBankAccount, setCashBankAccount] = useState();

  const [Remark, setRemark] = useState("");
  const [FromModal, setFromModal] = useState("");

  const [Receipt, setReceipt] = useState([]);

  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/Receipt/single/${id}`);

      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      convertReceipt(value?.Receipt);
      setEntryDate(value?.EntryDate);
      setCashBankAccountHandler(value);
      setCashBankAccount(value?.CashBankAccount);
    } catch (error) { }
  };

  const convertReceipt = (Receipt = []) => {
    const myArr = Receipt?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        Employee: EmployeeDetails,
        _id,
        ...rest
      } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }
      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      if (EmployeeDetails) {
        const { _id: Employee, Name } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name,
        };
      }
      return obj;
    });

    setReceipt(myArr);
  };

  const setCashBankAccountHandler = (value) => {
    if (!value) {
      return;
    }
    const { LedgerEntry: Entry, CashBankAccount: Bank } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (Bank) {
      const { _id, Name } = Bank;
      obj = {
        _id,
        Name,
      };
    }

    setCashBankAccount(obj);
  };

  useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body "
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5> View Receipt</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  placeholder="Enter Receipt "
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label key="lev1"></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>CashBankAccount</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  value={CashBankAccount}
                />
              </div>
              <div className="col-md-3 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body" style={{ zoom: "90%" }}>
            <div className="row ">
              <div className="col-md-12 align-items-center">
                {
                  <table class="table table-striped table-bordered overflow-x">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">PartyAccount</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Narration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Receipt.map((ele, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{ele?.Name}</td>
                          <td>{ele?.Account}</td>
                          <td>{ele?.RefNumber}</td>
                        </tr>
                      ))}
                      <tr>
                        <th scope="row"></th>
                        <td>
                          <b>Total:-</b>
                        </td>
                        <td>
                          <b>
                            {Receipt.reduce((sum, a) => sum + a?.Account, 0)}
                          </b>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <Link className="btn mybtn" to="/admin/Receipt/data">
              Go Back
            </Link>
            <Link
              className="btn mybtn"
              to={`/admin/Receipt?_id=${id}&Mode=true`}
            >
              Edit
            </Link>
          </div>


        </div>
      </div>

      <Footer />
    </>
  );
};

export default ViewReceipt;
