import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Select from "react-select";
import { coverAmountIntoCurrent } from "../../Utils/usefullFunction";
const ViewPayment = () => {
  const MASTER_DB = ["CASH", "BANK"];
  const today = new Date();

  const lastDay = today.toISOString().substr(0, 10);
  const { id } = useParams();

  const [EntryDate, setEntryDate] = useState(lastDay);
  const [CashBankAccount, setCashBankAccount] = useState();
  const [Remark, setRemark] = useState("");

  const [PaymentList, setPaymentList] = useState([]);
  const [FromModal, setFromModal] = useState();

  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/PaymentList/single/${id}`);
      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      setPaymentListHandler(value?.PaymentList);
      setEntryDate(value?.EntryDate);
      setCashBankOptHandler(value);
    } catch (error) {}
  };

  const setPaymentListHandler = (PaymentList = []) => {
    const myArr = PaymentList?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        Employee: EmployeeDetails,
        _id,
        ...rest
      } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }

      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }

      if (EmployeeDetails) {
        const { _id: Employee, Name } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name,
        };
      }
      return obj;
    });

    setPaymentList(myArr);
  };
  const setCashBankOptHandler = (value) => {
    const { LedgerEntry: Entry, CashBankAccount: Bank } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (Bank) {
      const { _id, Name } = Bank;
      obj = {
        _id,
        Name,
      };
    }

    setCashBankAccount(obj);
  };

  useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>View PaymentList</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              {/* Employee Information */}
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control"
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  placeholder="Enter PaymentList "
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>CashBankAccount</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  value={CashBankAccount}
                />
              </div>
              <div className="col-md-3 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">PartyAccount</th>
                        <th scope="col">Bank/Account No/IFSC</th>

                        <th scope="col">TDS Account</th>
                        <th scope="col">Section</th>
                        <th scope="col">TDS Percent</th>
                        <th scope="col">Amount</th>

                        <th scope="col">TDS Amount</th>
                        <th scope="col">Without TDS</th>

                        <th scope="col">Narration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PaymentList &&
                        PaymentList.length >= 0 &&
                        PaymentList.map((ele, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <td>{ele?.Name}</td>
                            </td>
                            <td>
                              {ele?.BankDetailName
                                ? ele?.BankDetailName
                                : ele?.BankDetail?.Name &&
                                  `${ele?.BankDetail?.AccountHolderName ?? ""}/${ele?.BankDetail?.Name ?? ""}/${ele?.BankDetail?.IFSC ?? ""}/${ele?.BankDetail?.BankAccountNo ?? ""}`}
                            </td>
                            <td>{ele?.TDSAccount?.Name}</td>
                            <td>{ele?.Section}</td>
                            <td>{ele?.TDSPercent}%</td>
                            <td>{coverAmountIntoCurrent(ele?.Account)}</td>

                            <td>{coverAmountIntoCurrent(ele?.TDSAmount)}</td>
                            <td>
                              {coverAmountIntoCurrent(
                                ele?.Account - ele?.TDSAmount
                              )}
                            </td>
                            <td>{ele?.RefNumber}</td>
                          </tr>
                        ))}
                      <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total:-</td>
                        <td>
                          {coverAmountIntoCurrent(
                            PaymentList.reduce((sum, a) => sum + a?.Account, 0)
                          )}
                        </td>
                        <td>
                          {coverAmountIntoCurrent(
                            PaymentList.reduce(
                              (sum, a) => sum + a?.TDSAmount,
                              0
                            )
                          )}
                        </td>
                        <td></td>

                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

       
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
          <Link className="btn mybtn" to="admin/PaymentList/data">
                Go Back
              </Link>
            <Link
              className="btn mybtn"
              to={`/admin/PaymentList?_id=${id}&Mode=true`}
            >
              Edit
            </Link>
            {/* /admin/PaymentList?_id=6543849befae2c932c3cb1f0&Mode=true */}
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewPayment;
