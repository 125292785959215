import React, { useState } from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import { REACT_APP_FileURL } from "../../config";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { reversDate } from "../../Utils/usefullFunction";
const TDSExcel = ({ PaymentList = [] }) => {
  const [loading, setLoading] = useState();

  const KeyArray = [
    "Bank",
    "PartyAccount",
    "EntryDate",
    "Beneficiary Name",
    "Beneficiary Bank Name",
    "Beneficiary Account No",
    "Beneficiary IFSC Code",
    "Pan Number",
    "Section",
    "TDS Percent",
    "Amount",
    "TDS Amount",
    "Without TDS",
  ];
  const downloadFile = async () => {
    try {
      setLoading(true);

      const Policy = PaymentList;

      const newArr = [];
      let bankAccountNumber = "";
      const createInitialKey = () => {
        const obj = {};
        let startingIndex = 65;
        let addon = 0;
        KeyArray.forEach((ele, i) => {
          if (startingIndex > 90) {
            startingIndex = 65;
            addon++;
          }

          if (ele === "Beneficiary Account No") {
            bankAccountNumber = ReturnCharCode(startingIndex, addon);
          }
          obj[ReturnCharCode(startingIndex, addon)] = ele;
          startingIndex++;
        });

        newArr.push(obj);
      };
      createInitialKey();
      const createValues = async () => {
        await Policy.forEach(async (ele) => {
          const obj = {};
          let startingIndex = 65;
          let addOn = 0;
          for (const key of KeyArray) {
            if (startingIndex > 90) {
              startingIndex = 65;
              addOn++;
            }

            if (key === "Bank") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.Bank?.Name;
              startingIndex++;
              continue;
            }

            if (key === "PartyAccount") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.Name;
              startingIndex++;
              continue;
            }
            if (key === "EntryDate") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                ele?.EntryDate
              );
              startingIndex++;
              continue;
            }

            //   "Beneficiary Name",
            // "Beneficiary Bank Name",
            // "Beneficiary Account No",
            // "Beneficiary IFSC Code",
            if (key === "Beneficiary Name") {
              obj[ReturnCharCode(startingIndex, addOn)] = `${
                ele?.BankDetail?.AccountHolderName ?? ""
              }`;
              startingIndex++;
              continue;
            }
            if (key === "Beneficiary Bank Name") {
              obj[ReturnCharCode(startingIndex, addOn)] = `${
                ele?.BankDetail?.Name ?? ""
              }`;
              startingIndex++;
              continue;
            }
            if (key === "Beneficiary Account No") {
              obj[ReturnCharCode(startingIndex, addOn)] = `${
                ele?.BankDetail?.BankAccountNo ?? ""
              }`;
              startingIndex++;
              continue;
            }
            if (key === "Beneficiary IFSC Code") {
              obj[ReturnCharCode(startingIndex, addOn)] = `${
                ele?.BankDetail?.IFSC ?? ""
              }`;
              startingIndex++;
              continue;
            }
            if (key === "Pan Number") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.BankDetail
                ?.PanNumber
                ? ele?.BankDetail?.PanNumber
                : ele?.PanNumber;
              startingIndex++;
              continue;
            }

            if (key === "Section") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.Section;
              startingIndex++;
              continue;
            }
            if (key === "TDS Percent") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.TDSPercent ?? 0;
              startingIndex++;
              continue;
            }
            if (key === "Amount") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.Account;
              startingIndex++;
              continue;
            }
            if (key === "TDS Amount") {
              obj[ReturnCharCode(startingIndex, addOn)] = ele?.TDSAmount;
              startingIndex++;
              continue;
            }
            if (key === "Without TDS") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                ele?.Account - ele?.TDSAmount;
              startingIndex++;
              continue;
            }
          }

          newArr.push(obj);
        });
      };
      await createValues();

      const sheet = XLSX.utils.json_to_sheet(newArr, {
        skipHeader: true,
      });

      const wb = XLSX.utils.book_new();

      for (let index = 1; index < newArr.length; index++) {
        sheet[`${bankAccountNumber}${index}`].z = "0";
      }

      XLSX.utils.book_append_sheet(wb, sheet, "Sales_Bill_List");
      const workbookBlob = workbook2blob(wb);
      var headerIndexes = [];
      newArr.forEach((data, index) =>
        data["A"] === "Sl No." ? headerIndexes.push(index) : null
      );

      const dataInfo = {
        titleCell: "A1",
        //   titleRange: "A1:AG2",
        tbodyRange: `A1:S${newArr.length}`,
        theadRange:
          headerIndexes?.length >= 1
            ? `A${headerIndexes[0] + 1}:S${headerIndexes[0] + 1}`
            : null,
      };
      setLoading(false);

      return addStyle(workbookBlob, dataInfo, bankAccountNumber).then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute("download", `TDS-Report`);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const addStyle = (workbookBlob, dataInfo, bankAccountNumber) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("B").width(30);
        sheet.column(bankAccountNumber).width(30);
        // sheet.range(dataInfo.titleRange).merged(true).style({
        //   bold: true,
        //   horizontalAlignment: "center",
        //   verticalAlignment: "center",
        // });

        // if (dataInfo.tbodyRange) {
        //   sheet.range(dataInfo.tbodyRange).style({
        //     horizontalAlignment: "center",
        //   });
        // }

        // sheet.range(dataInfo.theadRange).style({
        //   bold: true,
        //   horizontalAlignment: "center",
        // });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wboutput = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wboutput)], {
      type: "application/octet-stream",
    });

    return blob;
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  return (
    <>
      {loading ? (
        <img
          src={require("../utils/UiImages/Download.gif")}
          alt="My GIF"
          style={{
            width: "35px",
            height: "34px",
          }}
        />
      ) : (
        <AiOutlineFileExcel
          onClick={downloadFile}
          style={{
            backgroundColor: "green",
            color: "#fff",
            padding: "5px",
            borderRadius: "5px",
            marginTop: "2px",
            fontSize: "2rem",
          }}
        />
      )}
    </>
  );
};

const ReturnCharCode = (startingIndex, addOn) => {
  if (addOn === 0) {
    return String.fromCharCode(startingIndex);
  }

  return `${String.fromCharCode(64 + addOn)}${String.fromCharCode(
    startingIndex
  )}`;
};

export default TDSExcel;
