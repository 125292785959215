import axios from "axios";

export const axiosInstance = axios.create({
  withCredentials: true,
  // baseURL: "http://localhost:8000/api/v1",
  baseURL: "http://103.139.58.183/api/v1",
});

export const REACT_APP_FileURL = "http://103.139.58.183";
// export const REACT_APP_FileURL = "http://localhost:8000";
