import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { FaEye } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import PaymentExvelNew from "../../Utils/PaymentExvelNew";
import {
  convertDateStringToDate,
  coverAmountIntoCurrent,
  reversDate,
} from "../../Utils/usefullFunction";
import DateRange from "../DateRangeModule/DateRange";
const PaymentList = () => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  const getData = async (lastDay, firstDay) => {
    try {
      setLoading(true);
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/PaymentList?page=${pagination + 1}&limit=${rowsPerPage}&StartDate=${
          firstDay ?? StartDate
        }&EndDate=${lastDay ?? EndDate}`
      );

      setTotalDocs(totalDocs);

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deletePaymentList = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/PaymentList/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };
  useEffect(() => {
    getData();
  }, [pagination, rowsPerPage]);

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, Narration"
      />
      <div
        className="container-fluid"
        style={{ marginTop: "80px", zoom: "80%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div className="row">
                  <div className="col-10" style={{ float: "left" }}>
                    <div className="ListDesigncol">
                      <h4 className="card-title">Payment</h4>
                      <p className="card-title-desc">
                        Payment is a report of cash and bank transactions during
                        a period.
                      </p>
                    </div>
                  </div>
                  <div className="col-2" style={{ float: "left" }}>
                    <div>
                      <Link className="btn mybtn" to="/admin/vouchers/PaymentList">
                        Add PaymentList
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className=" p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="form-control "
                        type="date"
                        name="StartDate"
                        value={StartDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required //done
                      />

                      <p>TO</p>
                      <input
                        className="form-control "
                        type="date"
                        name="EndDate"
                        value={EndDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required //done
                      />
                    </div>
                    <DateRange setDateHandler={setDateHandler} />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => getData()}
                    >
                      <MdSend />
                    </div>
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEndDate(lastDay);
                        setStartDate(firstDay);
                        getData(lastDay, firstDay);
                      }}
                    >
                      <BsEraser />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    {
                      <table class="table table-striped table-bordered overflow-x mt-3">
                        <thead>
                          <tr>
                            <th scope="col" style={{ textAlign: "center" }}>
                              #
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              EntryDate
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Bank Name
                            </th>

                            <th scope="col" style={{ textAlign: "center" }}>
                              Amount
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Narration
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length !== 0 &&
                            data.map((ele, index) => {
                              let { CashBankAccount, LedgerEntry } = ele;
                              let dataId;
                              if (LedgerEntry) {
                                dataId = LedgerEntry;
                              }
                              if (CashBankAccount) {
                                dataId = CashBankAccount;
                              }

                              return (
                                <tr>
                                  <td scope="row">{index + 1}</td>
                                  <td>{reversDate(ele?.EntryDate)}</td>
                                  <td>{dataId?.Name}</td>
                                  <td style={{ textAlign: "right" }}>
                                    {coverAmountIntoCurrent(
                                      ele?.PaymentList.reduce(
                                        (sum, a) => sum + a.Account,
                                        0
                                      )
                                    )}
                                  </td>
                                  <td>{ele?.Remark}</td>

                                  <th scope="row">
                                    {" "}
                                    <div className="col-md-2">
                                      <div className="d-flex gap-3">
                                        <div>
                                          <Link
                                            className=""
                                            to={`/admin/view/Payment/${ele._id}`}
                                          >
                                            <FaEye
                                              size={35}
                                              style={{
                                                backgroundColor: "#E8960F",
                                                color: "#fff",
                                                padding: "7px",
                                                borderRadius: "5px",
                                              }}
                                            />{" "}
                                          </Link>
                                        </div>
                                        <div className="">
                                          <Link
                                            className=""
                                            // state={{
                                            //   value: ele,
                                            //   Mode: true,
                                            // }}
                                            to={`/admin/vouchers/PaymentList?_id=${ele._id}&Mode=true`}
                                          >
                                            <BiEdit
                                              size={35}
                                              style={{
                                                backgroundColor: "green",
                                                color: "#fff",
                                                padding: "7px",
                                                borderRadius: "5px",
                                              }}
                                            />{" "}
                                          </Link>
                                        </div>
                                        <div className="">
                                          <AiFillDelete
                                            size={35}
                                            style={{
                                              backgroundColor: "#A50406",
                                              color: "#fff",
                                              padding: "7px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const isTrue = window.confirm(
                                                "Do you want to delete!"
                                              );
                                              if (isTrue) {
                                                deletePaymentList(ele?._id);
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="">
                                          <PaymentExvelNew
                                            _id={ele?._id}
                                            value={ele}
                                            Name={dataId?.Name}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    }
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      >
                        <TablePagination
                          component="div"
                          className="text-white"
                          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                          count={totalDocs ?? 0}
                          page={pagination}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default PaymentList;
