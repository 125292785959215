import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
const DuplicatedRto = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);




  const [inputData, setInput] = useState("");



  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/RTO/getDuplicateRtoState`);

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };



  useEffect(() => {

    getData();
  }, []);
  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, RTOName, RTOCode"
      />
      <div
        className="container-fluid"
        style={{ marginTop: "70px", zoom: "85%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="card-body">

              {loading ? (
                <div
                  className=" p-2 container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TableSkeleton />
                </div>
              ) : (
                <>
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          #
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          RTO CODE
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Count
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 &&
                        data.map((ele, index) => <>
                          <tr>
                            <td>{index + 1}</td>
                            <td><Link to={`/remove/duplicate/${ele._id}`}>{ele._id}</Link></td>
                            <td>{ele.count}</td>
                          </tr>
                        </>)}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            {/* end col */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DuplicatedRto;
