import React, { useEffect, useState } from "react";
import { ToastError, ToastWarn } from "../../Utils/Tost";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import Select from "react-select";
import { RxReset } from "react-icons/rx";
import { BiSearchAlt } from "react-icons/bi";
import { AiFillFilePdf } from "react-icons/ai";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import {
  convertDateStringToDate,
  coverAmountIntoCurrent,
  getPreviousDay,
} from "../../Utils/usefullFunction";
import DateRange from "../DateRangeModule/DateRange";

const Ledger = () => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [inputData, setInput] = useState();
  const [loading, setLoading] = useState(false);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);

  const [OpeningBalance, setOpeningBalance] = useState(0);
  const [PartyAccountOpt, setPartyAccountOpt] = useState([]);
  const [Pos, setPos] = useState({
    _id: "",
    Name: "-- Select --",
  });

  const [Data, setData] = useState([]);
  const [TotalSum, setTotalSum] = useState();
  const [TotalDr, setTotalDr] = useState(0);
  const [TotalCr, setTotalCr] = useState(0);

  const MASTER_DB = ["Bank", "LedgerGroup", "POS", "Broker"];
  const getPos = async (isData) => {
    try {
      if (isData === "POS") {
        const {
          data: { data: posDetails },
        } = await axiosInstance.post("/Pos/getPosByField", {
          showFiled: {
            Name: 1,
            Salutation: 1,
            MiddleName: 1,
            LastName: 1,
            WDAddress: 1,
            WDLocality: 1,
            WDCity: 1,
            WDState: 1,
            WDPincode: 1,
            PanNumber: 1,
            GSTINNumber: 1,
            _id: 1,
          },
        });

        const POS = posDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
            ...rest
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
              rest: rest,
            };
          }
        );

        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...POS,
        ]);
      }
      if (isData === "Broker") {
        const {
          data: { data },
        } = await axiosInstance.get(`/Broker`);

        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...data,
        ]);
      }
      if (isData === "Bank") {
        const {
          data: { data: BankData },
        } = await axiosInstance.get("/Bank/getBankByUs");
        const Bank = BankData.map(({ Name: Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...Bank,
        ]);
      }
      if (isData === "LedgerGroup") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...LedgerGroup,
        ]);
      }
      setPos({
        _id: "",
        Name: "-- Select --",
      });
    } catch (error) {}
  };
  const getLedger = async () => {
    try {
      if (!Pos?._id) {
        return ToastWarn("Please Select Ledger");
      }
      setLoading(true);

      const {
        data: { data, OpeningBalance },
      } = await axiosInstance.get(
        `/Accountancy/getAccountancyCheckAllModal?Ledger=${Pos?._id}&StartDate=${StartDate}&EndDate=${EndDate}`
      );

      setOpeningBalance(OpeningBalance ?? 0);
      let DR = 0;
      let CR = 0;
      data.reduce((sum, a) => {
        if (a.Amount > 0) {
          DR += Math.abs(a.Amount);
        } else {
          CR += Math.abs(a.Amount);
        }

        return sum + a.Amount;
      }, OpeningBalance ?? 0);

      setTotalDr(DR);
      setTotalCr(CR);
      setData(data);

      setLoading(false);
      // getOpeningBalance()
    } catch (error) {
      setLoading(false);
      return ToastError(error?.response?.data?.message);
    }
  };

  // const getOpeningBalance = async () => {
  //   try {
  //     const {
  //       data: {  OpeningBalance },
  //     } = await axiosInstance.get(
  //       `/Accountancy/getOpeningBalance?Ledger=${Pos?._id}&StartDate=${StartDate}&EndDate=${EndDate}`
  //     );

  //     console.log(OpeningBalance,"OpeningBalance 111");
  //   } catch {}
  // };
  const EraserHandler = async () => {
    setData([]);
    setPos({
      _id: "",
      Name: "-- Select --",
    });
    setOpeningBalance({});
    setStartDate(firstDay);
    setTotalSum(0);
    setEndDate(lastDay);
  };

  const getBalance = (data, index) => {
    const myArr = structuredClone(data);
    const newArr = myArr.slice(0, index + 1);
    let value = newArr.reduce(
      (acc, it) => Number(it.Amount) + acc,
      OpeningBalance ?? 0
    );

    return (
      <td style={{ textAlign: "right" }}>{`${coverAmountIntoCurrent(value)} ${
        value > 0 ? "Dr." : "Cr."
      }`}</td>
      // <td style={{ textAlign: "right" }}>{value}</td>
    );
  };
  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };
  const downloadPdf = async () => {
    try {
      let Name = `Bill-${Pos.Name}-${StartDate}-TO-${EndDate}.pdf`;
      axios({
        url: `${REACT_APP_FileURL}/api/v1/Accountancy/generatePdf?Ledger=${
          Pos?._id
        }&StartDate=${StartDate}&EndDate=${EndDate}&Pos=${JSON.stringify(
          Pos
        )}&OpeningBalance=${OpeningBalance}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = Name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error, "generatePdf");
          console.error(error);
        });
    } catch (error) {}
  };

  const CreateUrlLink = (data) => {
    let URL = "";

    switch (data?.VoucherType) {
      case "Paid By Bank":
        URL = `/admin/Policy/${data?.CreatedWhich}`;
        break;
      case "DebitNotes":
        URL = `/admin/view/DebitNotes/${data?.CreatedWhich}`;
        break;
      case "Payment":
        URL = `/admin/view/Payment/${data?.CreatedWhich}`;
        break;
      case "Opening Balance":
        URL = "";
        break;
      case "Received In":
        URL = `/admin/Policy/${data?.CreatedWhich}`;
        break;
      case "Contra":
        URL = `/admin/view/Contra/${data?.CreatedWhich}`;

        break;
      case "CreditNotes":
        URL = `/admin/view/CreditNotes/${data?.CreatedWhich}`;
        break;
      case "Receipt":
        URL = `/admin/view/Receipt/${data?.CreatedWhich}`;
        break;
      case "Purchase":
        URL = `/admin/view/Purchase/${data?.CreatedWhich}`;
        break;
      case "Sale":
        URL = `/admin/viewSale/${data?.CreatedWhich}`;
        break;
      case "Journal Voucher":
        URL = `/admin/view/Journal/${data?.CreatedWhich}`;
        break;
      case "Purchase Return":
        URL = `/admin/view/Purchase/Return/${data?.CreatedWhich}`;
        break;
      default:
        URL = "";
    }

    return URL ? (
      <Link to={URL} target="__blank" rel="noopener noreferrer">
        {data?.oppositeLedger?.Salutation
          ? ` ${data?.oppositeLedger?.Salutation} ${data?.oppositeLedger?.Name} ${data?.oppositeLedger?.MiddleName} ${data?.oppositeLedger?.LastName}`
          : data?.oppositeLedger.Name}
      </Link>
    ) : data?.oppositeLedger?.Salutation ? (
      ` ${data?.oppositeLedger?.Salutation} ${data?.oppositeLedger?.Name} ${data?.oppositeLedger?.MiddleName} ${data?.oppositeLedger?.LastName}`
    ) : (
      data?.oppositeLedger.Name
    );
  };

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by REG No, Name, Policy No... "
      />

      <div className="container-fluid" style={{ marginTop: "60px" }}>
        <div className="row CreateCard p-4">
          <div
            className=" p-2 container filters"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "unset",
              flexDirection: "row",
              gap: "10px",
              zoom: "80%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <div className="d-flex gap-3">
                <div>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="PolicyType"
                    style={{
                      minWidth: "200px",
                    }}
                    onChange={(e) => {
                      getPos(e.target.value);
                    }}
                    required
                  >
                    <option value=""> Select Ledger</option>

                    {MASTER_DB.map((ele) => {
                      return <option value={ele}>{ele}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <Select
                    style={{ backgroundColor: "#F5F5F6" }}
                    className="basic-single"
                    classNamePrefix="select"
                    options={PartyAccountOpt}
                    // defaultValue={{
                    //   _id:"-- Please Select Variant --"
                    // }}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["Name"]}
                    onChange={setPos}
                    value={Pos}
                  />
                </div>
                <div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <input
                      className="form-control "
                      type="date"
                      name="startDate"
                      style={{
                        maxWidth: "150px",
                      }}
                      value={StartDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      // placeholder="Enter  PolicyNumber"
                      required //done
                    />

                    <p>-</p>
                    <input
                      className="form-control "
                      type="date"
                      name="endDate"
                      value={EndDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      // placeholder="Enter  PolicyNumber"
                      style={{
                        maxWidth: "150px",
                      }}
                      required //done
                    />
                  </div>
                </div>
                <DateRange setDateHandler={setDateHandler} />
                <div className="">
                  <BiSearchAlt
                    size={32}
                    onClick={() => getLedger()}
                    style={{
                      backgroundColor: "#174379",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "5px",
                      marginTop: "2px",
                    }}
                  />{" "}
                  &nbsp;
                  <Link>
                    <RxReset
                      onClick={() => EraserHandler()}
                      size={32}
                      style={{
                        backgroundColor: "#E8960F",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "5px",
                        marginTop: "2px",
                      }}
                    />
                  </Link>
                  &nbsp;
                  {Data && Data?.length !== 0 && (
                    <Link>
                      <AiFillFilePdf
                        onClick={() => downloadPdf()}
                        size={32}
                        style={{
                          backgroundColor: "#1b9a9a",
                          color: "#fff",
                          padding: "5px",
                          borderRadius: "5px",
                          marginTop: "2px",
                        }}
                      />
                    </Link>
                  )}
                  &nbsp;
                  {Data && Data?.length !== 0 && (
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn mybtn"
                      table="table-to-xls"
                      filename={`Ledger-oF-${Pos?.Name}-Between-${StartDate}-${EndDate}`}
                      sheet="tablexls"
                      buttonText="Download as XLS"
                    />
                  )}
                </div>
              </div>
            </div>

            {/* <div className="row gap-2">
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => getLedger()}
                >
                  <MdSend />
                </div>
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => EraserHandler()}
                >
                  <BsEraser />
                </div>
              </div> */}
          </div>

          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <div
              className=" p-2 container"
              style={{
                zoom: "80%",
              }}
            >
              {Data && (
                <table
                  class="table table-striped   table-bordered  overflow-x mt-3"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th scope="col" style={{ textAlign: "center" }}>
                        #
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Date
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Particular
                      </th>
                      {/* <th scope="col">Name</th> */}
                      <th scope="col" style={{ textAlign: "center" }}>
                        Voucher Type
                      </th>
                      {/* <th scope="col" style={{ textAlign: "center" }}>
                        Bill Number
                      </th> */}
                      <th scope="col" style={{ textAlign: "center" }}>
                        Narration
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Remark
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Dr.
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Cr.
                      </th>

                      <th scope="col" style={{ textAlign: "center" }}>
                        Balance
                      </th>
                      {/* <th scope="col">Approve</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td> {getPreviousDay(StartDate)}</td>
                      <td></td>
                      <td></td>
                      {/* <td>{data?.oppositeLedger.Name}</td> */}
                      <td>Opening Balance</td>
                      {/* <td></td> */}
                      <td
                        style={{
                          textAlign: "right",
                          color: "red",
                          fontWeight: "700",
                        }}
                      ></td>
                      <td
                        style={{
                          textAlign: "right",
                          color: "#38dc38",
                          fontWeight: "700",
                        }}
                      ></td>
                      <td></td>

                      <td
                        style={{ textAlign: "right" }}
                      >{` ${coverAmountIntoCurrent(OpeningBalance ?? 0)} ${
                        OpeningBalance <= 0 ? "Cr." : "Dr."
                      }`}</td>
                      {/* {OpeningBalance ?? 0} */}
                    </tr>
                    {Data &&
                      Data?.length !== 0 &&
                      Data.map((data, index) => (
                        <tr>
                          <td scope="row">{index + 2}</td>
                          <td>
                            {data?.EntryDate?.split("-").reverse().join("-")}
                          </td>
                          <td>{CreateUrlLink(data)}</td>
                          {/* <td>{data?.oppositeLedger.Name}</td> */}
                          <td>{data?.VoucherType}</td>
                          {/* <td>{data?.RefNumber}</td> */}
                          <td>&nbsp;{data?.RefNumber}</td>
                          <td>{data?.Remark}</td>
                          <td
                            style={{
                              textAlign: "right",
                              color: "red",
                              fontWeight: "700",
                            }}
                          >
                            {/* {data?.DR === 0 ? "" : `₹ ${data?.DR}`} */}

                            {/* Amount IS +(VE) +DR */}
                            {data?.Amount >= 0
                              ? ` ${coverAmountIntoCurrent(data?.Amount)}`
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color: "#38dc38",
                              fontWeight: "700",
                            }}
                          >
                            {/* Amount IS -VE +CR */}
                            {data?.Amount < 0
                              ? `${coverAmountIntoCurrent(data?.Amount)}`
                              : ""}
                            {/* {data?.Amount < 0 ? `₹ ${data?.Amount}` : ""} */}
                            {/* {data?.CR === 0 ? "" : `₹ ${data?.CR}`} */}
                          </td>

                          {getBalance(Data, index)}
                        </tr>
                      ))}

                    {Data && Data?.length !== 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "right",
                            // color: "#38dc38",
                            fontWeight: "700",
                          }}
                        >
                          {coverAmountIntoCurrent(TotalDr)}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            // color: "#38dc38",
                            fontWeight: "700",
                          }}
                        >
                          {coverAmountIntoCurrent(TotalCr)}
                        </td>

                        <td></td>
                        {/* <th>Total :-</th>
                      <th style={{ textAlign: "right" }}>{`₹ ${
                        TotalSum ? TotalSum : 0
                      } `}</th> */}
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              {/* {
                  <div
                    style={{
                      backgroundColor: "#1a2e45d7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      marginTop: "5px",
                    }}
                  >
                    <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                      count={totalDocs ?? 0}
                      page={pagination}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                } */}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Ledger;
