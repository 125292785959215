import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import useHttpErrorHandler from "../Hooks/useHttpErrorHandler";

const initialState = {
  VariantName: "",
  FuelType: "",
  FuelTypeName: "",
  FuelTypeData: "",
  Engine: "",
  Seater: "",
  GVW: "",
  Status: true,
  inStatus: 1,
};

const CreateMakeModal = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { view } = state || {};
  const [Name, setName] = useState("");
  const [ModalName, setModalName] = useState("");
  const navigate = useNavigate();
  const httpErrorHandler = useHttpErrorHandler();
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState({
    data: [],
    value: "",
  });
  const [fuelType, setFuelType] = useState({ data: [] });
  const [variantFormData, setVariantFormData] = useState(initialState);
  const [variant, setVariant] = useState([]);

  const handleCreate = async () => {
    if (!Name) {
      return ToastWarn("Name is required ");
    }
    try {
      // const insuranceType = SelectedInsuranceType.filter(
      //   (ele) => ele.value !== "" && ele.value !== " "
      // ).map((ele) => ele.value);
      // const length = insuranceType.length;

      if (!SelectedInsuranceType?.value.trim()) {
        return ToastError("Insurance type Is Required");
      }
      const {
        data: { success, message },
      } = await axiosInstance.post("/MakeModal/create", {
        InsuranceType: SelectedInsuranceType?.value,
        Name,
        ModalName,
        Variant: variant,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/MakeModal/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const handleEdit = async () => {
    try {
      // const insuranceType = SelectedInsuranceType.filter(
      //   (ele) => ele.value !== "" && ele.value !== " "
      // ).map((ele) => ele.value);
      // const length = insuranceType.length;
      if (!SelectedInsuranceType?.value?.trim()) {
        return ToastError("Insurance type Is Required");
      }
      const {
        data: { success, message },
      } = await axiosInstance.put(`/MakeModal/edit/${id}`, {
        InsuranceType: SelectedInsuranceType?.value,
        Name,
        ModalName,
        Variant: variant,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/MakeModal/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType({ data, value: "" });
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  useEffect(() => {
    const getFuelType = async () => {
      try {
        const {
          data: { data },
        } = await axiosInstance.get("/FuelType");
        setFuelType({ data });
      } catch (error) {
        httpErrorHandler(error);
      }
    };

    getFuelType();
  }, [httpErrorHandler]);

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] ?? "" });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };

  const getDataById = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/MakeModal/getDataById/${id}`);
      const variantData = data.Variant;

      if (variantData.length) {
        const updatedData = variantData.map((item) => ({
          ...item,
          FuelType: item?.FuelType?._id,
          FuelTypeName: item?.FuelType?.FuelType,
          FuelTypeData: `${item?.FuelType?.FuelType}_${item?.FuelType?._id}`,
          Status: item?.Status,
          inStatus: item?.Status ? 1 : 0,
        }));
        setVariant(updatedData);
      }


      setName(data.Name);
      setModalName(data.ModalName);
      setSelectedInsuranceType((prev) => ({
        ...prev,
        value: data?.InsuranceType?._id,
      }));

      // callInsuranceData(
      //   [
      //     ...data?.InsuranceType?.InsuranceUnderFlow,
      //     data?.InsuranceType?._id,
      //   ] ?? []
      // );
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getDataById();
    } else {
      // callInsuranceData([]);
    }
    getDataInsuranceType();
  }, []);

  const handleDeleteClick = (index) => {
    const myVariant = [...variant];
    myVariant.splice(index, 1);

    setVariant(myVariant);
  };

  const handleEditClick = (index) => {
    const myVariant = [...variant];

    const editedData = myVariant[index];
    setVariantFormData(editedData);
    myVariant.splice(index, 1);

    setVariant(myVariant);
  };

  const handleAddClick = () => {
    const { VariantName, FuelType, Engine, Seater } = variantFormData;
    if (!VariantName || !FuelType || !Engine || !Seater) return;
    setVariant((prev) => [...prev, variantFormData]);
    setVariantFormData(initialState);
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="">
              <h4 className="card-title">
                {view
                  ? "View Make Modal Type"
                  : id
                  ? "Edit  Make Modal  "
                  : "Create  Make Modal  "}
              </h4>
              <hr />
              <br />

              <div className="row">
                <div className="col-md-4 align-items-center">
                  <label>Make Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter Make Name"
                    required //done
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Modal Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Name"
                    value={ModalName}
                    onChange={(e) => setModalName(e.target.value)}
                    placeholder="Enter Modal Name"
                    required //done
                  />
                </div>

                <div className=" col-md-3 align-items-center">
                  <label>Product</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="InsuranceType"
                    value={SelectedInsuranceType?.value}
                    onChange={(e) =>
                      setSelectedInsuranceType((prev) => ({
                        ...prev,
                        value: e.target.value,
                      }))
                    }
                    required
                  >
                    <option value={""}>Select Product</option>
                    {SelectedInsuranceType.data &&
                      SelectedInsuranceType.data.map((data) => (
                        <option key={data?._id} value={data?._id}>
                          {data?.InsuranceType}
                        </option>
                      ))}
                  </select>
                </div>

                {/* {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsurance
                          key={index}
                          Options={data}
                          setSelectedInsuranceType={setSelectedInsuranceType}
                          // getMakeModal={getMakeModal}
                          view={view}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                        />
                      )
                  )} */}
              </div>
            </div>
            <hr
              style={{
                marginTop: "10px",
                height: "2px",
                backgroundColor: "black",
                border: "none",
              }}
            />
            <div className="">
              <h4 className="card-title">
                {view
                  ? "View Make Modal Variant"
                  : id
                  ? "Edit  Make Modal Variant"
                  : "Create  Make Modal Variant"}
              </h4>
              <hr />
              <br />

              <div className="row">
                <div className="col-md-3 align-items-center">
                  <label>Variant Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="VariantName"
                    value={variantFormData?.VariantName}
                    onChange={(e) =>
                      setVariantFormData((prev) => ({
                        ...prev,
                        VariantName: e.target.value,
                      }))
                    }
                    placeholder="Enter Variant Name"
                    required //done
                  />
                </div>
                <div className="col-md-3 align-items-center">
                  <label>Cubic Capacity (CC)</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Engine"
                    value={variantFormData?.Engine}
                    onChange={(e) =>
                      setVariantFormData((prev) => ({
                        ...prev,
                        Engine: e.target.value,
                      }))
                    }
                    placeholder="Enter CC"
                    required //done
                  />
                </div>
                <div className=" col-md-3 align-items-center">
                  <label>Select Fuel Type</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="FuelType"
                    value={variantFormData?.FuelTypeData}
                    onChange={(e) => {
                      const value = e.target.value;
                      const [FuelType, _id] = value ? value.split("_") : "";
                      setVariantFormData((prev) => ({
                        ...prev,
                        FuelTypeData: value,
                        FuelType: _id,
                        FuelTypeName: FuelType,
                      }));
                    }}
                    required
                  >
                    <option value={""}>Select Fuel Type</option>
                    {fuelType.data &&
                      fuelType.data.map((data) => (
                        <option
                          key={data?._id}
                          value={`${data?.FuelType}_${data?._id}`}
                        >
                          {data?.FuelType}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-3 align-items-center">
                  <label>Seater</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Seater"
                    value={variantFormData?.Seater}
                    onChange={(e) =>
                      setVariantFormData((prev) => ({
                        ...prev,
                        Seater: e.target.value,
                      }))
                    }
                    placeholder="Enter Seater"
                    required //done
                  />
                </div>
                <div className="col-md-3 align-items-center">
                  <label>GVW</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="GVW"
                    value={variantFormData?.GVW}
                    onChange={(e) =>
                      setVariantFormData((prev) => ({
                        ...prev,
                        GVW: e.target.value,
                      }))
                    }
                    placeholder="Enter GVW"
                    required //done
                  />
                </div>
                <div className=" col-md-3 align-items-center">
                  <label>Status</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="Status"
                    value={variantFormData?.inStatus}
                    onChange={(e) => {
                      const value = e.target.value;
                      setVariantFormData((prev) => ({
                        ...prev,
                        inStatus: value,
                        Status: value === "1" ? true : false,
                      }));
                    }}
                    required
                  >
                    <option value={1}>Enable</option>
                    <option value={0}>Disable</option>
                  </select>
                </div>
                <div className=" col-md-3  align-items-center">
                  <button
                    type="button"
                    className="btn mybtn mt-4"
                    onClick={handleAddClick}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>

          {variant && (
            <div className=" p-2 container">
              <table class="table table-striped   table-bordered  overflow-x mt-3">
                <thead>
                  <tr
                    style={{
                      fontSize: "12px",
                      backgroundColor: "#03254d",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    <th scope="col">variant Name</th>
                    <th scope="col">Fuel Type</th>
                    <th scope="col">CC</th>
                    <th scope="col">Seater</th>
                    <th scope="col">GVW</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {variant &&
                    variant?.length !== 0 &&
                    variant.map((data, index) => (
                      <tr>
                        <td>{data?.VariantName}</td>
                        <td>{data?.FuelTypeName}</td>
                        <td>{data?.Engine}</td>
                        <td>{data?.Seater}</td>
                        <td>{data?.GVW}</td>
                        <td>
                          {data?.Status === true || data?.Status === 1
                            ? "Enable"
                            : "Disable"}
                        </td>

                        <th scope="row">
                          {" "}
                          <div className="col-md-2">
                            <div className="d-flex gap-3">
                              <div className="">
                                <BiEdit
                                  onClick={handleEditClick.bind(this, index)}
                                  size={25}
                                  color="blue"
                                />{" "}
                              </div>
                              <div className="">
                                <AiFillDelete
                                  color="red"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  size={25}
                                  onClick={() => {
                                    const isTrue = window.confirm(
                                      "Do you want to delete!"
                                    );
                                    if (isTrue) {
                                      handleDeleteClick(index);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/MakeModal/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  id ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateMakeModal;
