import React, { useEffect, useState } from "react";
// import Footer from '../Footer/Footer';
// import Header from '../Header/Header';
// import Select from "react-select";
import CreatePolicy from "./CreatePolicyType";
import CreateNonMotorPolicy from "./CreateNonMotorPolicy";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Loader from "../utils/Loader/Loader";
const PolicyForEdit = () => {
  const { PolicyId } = useParams();

  const getSingle = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/getSingleForPolicy/${PolicyId}`);

      const { IsNonMotorPolicy } = data;

      if (IsNonMotorPolicy) {
        setIsNonMotorPolicy({
          _id: true,
          Name: "NON-MOTOR",
        });
      } else {
        setIsNonMotorPolicy({
          _id: false,
          Name: "MOTOR",
        });
      }
    } catch (error) {}
  };
  const [IsNonMotorPolicy, setIsNonMotorPolicy] = useState([
    {
      _id: "",
      Name: "-- Select Policy Type --",
    },
  ]);

  useEffect(() => {
    getSingle();
  }, []);

  if (IsNonMotorPolicy.Name === "NON-MOTOR") {
    return <CreatePolicy />;
    // return <CreateNonMotorPolicy />;
  }
  if (IsNonMotorPolicy.Name === "MOTOR") {
    return <CreatePolicy />;
  }

  return <Loader />;
};

export default PolicyForEdit;
