import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import "./ViewFileModal.css";
import { Fade } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
const ViewFileModal = ({ handleOpen, handleClose, open, ModalData }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    // height: "90vh",
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 24,
    p: 2,
    borderRadius:"5px"
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {ModalData?.Name}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <div style={{}}>
                {ModalData?.MyLink?.match(/\.(jpeg|JPEG|jpg|JPG|png|PNG|webp|WEBP)$/) ? (
                  <img
                    className="img-fluid imgDisplay"
                    style={{
                      maxHeight: "500px",
                      minHeight: "300px",
                    }}
                    src={ModalData.MyLink}
                    alt={ModalData.MyLink}
                  />
                ) : (
                  <iframe
                    src={ModalData.MyLink}
                    title="pdf data"
                    width="800px"
                    height="460px"
                  ></iframe>
                )}
              </div>
            </Typography>
            <AiFillCloseCircle className="closedIcon" onClick={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ViewFileModal;
//  name {ModalData?.Name}
