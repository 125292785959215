import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertDialogSlide = ({
  handleClose,
  open,
  Title,
  Message,
  deleteFunction,
  SelectId,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{Title}</DialogTitle>
        {Message && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {Message}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button />
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              deleteFunction(SelectId);
              handleClose();
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialogSlide;
