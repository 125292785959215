import React, { useState } from "react";   import { ToastError, ToastSusses ,ToastInfo,ToastWarn } from "../../Utils/Tost";

import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const CreateBroker = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [OpeningBalance, setOpeningBalance] = useState(value?.OpeningBalance || "");


  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Broker", {
        Name,
        OpeningBalance
        
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/Broker/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } =  await axiosInstance.put(`/Broker/${value?._id}`, {
        Name,
        OpeningBalance
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/Broker/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="col-lg-12">
              <div className="">
                <div className="">
                  <h4 className="card-title">
                    {view ? "View Broker" : Mode ? "Edit Broker" : "Create New Broker"}
                  </h4>
                  <hr />

                  <div data-repeater-item="" className="outer">
                    <div
                      className="col-4"
                      style={{ float: "left", padding: 15 }}
                    >
                      <label className="form-label" htmlFor="formname">
                        Broker Name
                      </label>
                      <input
                        className="form-control "
                        type="text"
                        name="Name"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter Broker Name"
                        required //done
                        disabled={view}
                      />
                    </div>

                  </div>
                  <div data-repeater-item="" className="outer">
                    <div
                      className="col-4"
                      style={{ float: "left", padding: 15 }}
                    >
                      <label className="form-label" htmlFor="formname">
                      Opening Balance
                      </label>
                      <input
                        className="form-control "
                        type="text"
                        name="Name"
                        value={OpeningBalance}
                        onChange={(e) => setOpeningBalance(e.target.value)}
                        placeholder="Enter Opening Balance"
                        required //done
                        disabled={view}
                      />
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">

            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/Broker/data")}
            >
              Go Back
            </button>
            {!view && <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => {
                Mode ? handleEdit() : handleCreate();
              }}
            >
              Submit
            </button>}


          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateBroker;
