import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import { Box, Fade } from "@mui/material";
import "./UploadBankDocModal.css";
import {  useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import ImageUploaderForBank from "./ImageUploaderForBank";
import { axiosInstance } from "../../config";
import { ToastError, ToastSusses } from "../../Utils/Tost";
const UploadBankDocModal = ({
  handleClose,
  open,
  ModalData,
  BankDetails,
  setBankDetails,
}) => {
  const DELETE_URL = "/PBD/delete/Image";
  const [panCard, setpanCard] = useState();
  const [Cheque, setCheque] = useState();
  const [adharcardFrontImage, setadharcardFrontImage] = useState();
  const [adharcardBackImage, setadharcardBackImage] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 40,
    p: 4,
    borderRadius: "5px",
    width: "80vw",
    height: "90vh",
  };

  const EmpData = new FormData();

  EmpData.append("adharcardFrontImage", adharcardFrontImage);
  EmpData.append("adharcardBackImage", adharcardBackImage);
  EmpData.append("Cheque", Cheque);
  EmpData.append("panCard", panCard);

  
  const UploadImages = async () => {
    try {
      const {
        data: { success, message, data },
      } = await axiosInstance.put(`/PBD/${ModalData?._id}`, EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const myBankDetailsData = BankDetails.filter(
        (ele) => ele._id !== data._id
      );

  
      setBankDetails([...myBankDetailsData, data]);
      if (success) {
        ToastSusses(message);
        // handleClose()
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      return ToastError(error?.response?.data?.message);
    }
  };


  

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Upload Bank Documents
            </Typography>
            <hr />
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <div className="BankDocUploadModal">
                <div>
                  <h5 className="text-center">
                    {" "}
                    Account Holder- {ModalData?.AccountHolderName}
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "10px",
                    height: "55vh",
                    alignItems: "center",
                  }}
                >
                  <ImageUploaderForBank
                    deleteUrl={DELETE_URL}
                    id={ModalData?._id}
                    index={"8"}
                    Mode={ModalData?.adharcardFrontImage ? true : false}
                    Url={ModalData?.adharcardFrontImage ?? ""}
                    setBankDetails={setBankDetails}
                    BankDetails={BankDetails}

                    // view={view}
                    setChangeHandler={setadharcardFrontImage}
                    Name="Aadharcard Front"
                    filedName="adharcardFrontImage"
                  />
                  <ImageUploaderForBank
                     setBankDetails={setBankDetails}
                     BankDetails={BankDetails}
                    deleteUrl={DELETE_URL}
                    id={ModalData?._id}
                    index={"9"}
                    Mode={ModalData?.adharcardBackImage ? true : false}
                    Url={ModalData?.adharcardBackImage ?? ""}
                    // setModalData={setModalData}

                    // view={view}
                    setChangeHandler={setadharcardBackImage}
                    Name="Adharcard Back"
                    filedName="adharcardBackImage"
                  />
                  <ImageUploaderForBank
                     setBankDetails={setBankDetails}
                     BankDetails={BankDetails}
                    deleteUrl={DELETE_URL}
                    id={ModalData?._id}
                    index={"10"}
                    Mode={ModalData?.panCard ? true : false}
                    Url={ModalData?.panCard ?? ""}
                    // setModalData={setModalData}

                    // view={view}
                    setChangeHandler={setpanCard}
                    Name="Pan Card Image"
                    filedName="panCard"
                  />

                  <ImageUploaderForBank
                     setBankDetails={setBankDetails}
                     BankDetails={BankDetails}
                    deleteUrl={DELETE_URL}
                    id={ModalData?._id}
                    index={"11"}
                    Mode={ModalData?.Cheque ? true : false}
                    Url={ModalData?.Cheque ?? ""}
                    // setModalData={setModalData}

                    // view={view}
                    setChangeHandler={setCheque}
                    Name="Cancel Cheque"
                    filedName="Cheque"
                  />
                </div>
                <div
                  style={{
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <button className="btn mybtn m-2">View Documents</button>
                  <button
                    className="btn mybtn m-2"
                    onClick={() => UploadImages()}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </Typography>
            <AiFillCloseCircle className="closedIcon" onClick={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UploadBankDocModal;
