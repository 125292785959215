import React, { useEffect, useState } from "react";
import { ToastError, ToastInfo, ToastSusses } from "../../Utils/Tost";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import Select from "react-select";
import "./Purchase.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { coverAmountIntoCurrent, reversDate } from "../../Utils/usefullFunction";
const EditPurchaseReturn = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const [EnterDate, setEnterDate] = useState(lastDay);
  const [inputData, setInput] = useState();
  const [TDSAccount, setTDSAccount] = useState({
    _id: "",
    Name: "-- Select TDS Account --",
  });
  const [TDSOpt, setTDSOpt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Remark, setRemark] = useState();
  const [TDSPercent, setTDSPercent] = useState(0);
  const [Data, setData] = useState([]);

  const [CreditAccount, setCreditAccount] = useState({
    _id: "",
    Name: "-- Select Credit Account",
  });
  const [CreditAccountOpt, setCreditAccountOpt] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [POS, setPOS] = useState({});
  const [NetAmount, setNetAmount] = useState(0);
  const getLedgerMasterForTds = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/LedgerEntry?Group=648ac8ca7df926cfdd2ddccb`
      ); // 	Duties and Tax
      setTDSOpt(data);
    } catch (error) {}
  };
  const getSinglePurchase = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/PurchaseReturn/single/${id}`);
      setEnterDate(data?.EnterDate.split("T")[0]);
      setRemark(data?.Remark);
      setTDSPercent(data?.TDSPercent);
      setTotalAmount(data?.TotalAmount);
      setNetAmount(data?.NetAmount);
      setPOS(data?.DebitAccount);
      setCreditAccount(data?.CreditAccount);
      setTDSAccount(data?.TDSAccount);
      setData(data?.Policy);
    } catch (error) {}
  };

  const handleEdit = async () => {
    const AmountToGive = TotalAmount;
    const isGSTNumber =
      POS?.GSTINNumber && POS?.GSTINNumber.length === 16 ? true : false;

    let GstAmount = 0;
    let TDSAmount = Math.round((AmountToGive * TDSPercent) / 100);

    if (isGSTNumber) {
      GstAmount = Math.round(AmountToGive * (18 / 100));
    }
    const NetAmount = Math.round(AmountToGive + GstAmount - TDSAmount);

    if (!CreditAccount?._id) {
      return ToastInfo("Select Credit Account ");
    }
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/PurchaseReturn/${id}`, {
        CreditAccount: CreditAccount._id,
        EnterDate,
        Remark,
        // TDSPercent,
        // TDSAccount: TDSAccount._id,
        TotalAmount: AmountToGive,
        NetAmount,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Purchase/Return");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getSinglePurchase();
    getLedgerMasterForTds();
  }, []);

  let FilteredPolicy;

  if (inputData) {
    FilteredPolicy = Data?.filter(
      (e) =>
        e?.VehicleNumber?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.InsureeName?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.PolicyNumber?.toLowerCase()?.includes(inputData?.toLowerCase())
    );
  } else {
    FilteredPolicy = Data;
  }

  const getLedgerMaster = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/LedgerEntry`);
      setCreditAccountOpt(data);
    } catch (error) {}
  };

  useEffect(() => {
    getLedgerMaster();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        {
          <div className="row">
            <div
              className="card-body CreateCard"
              style={{
                height: "38vh",
                zoom: "90%",
              }}
            >
              <h4 className="card-title">Edit Purchase Return</h4>
              <hr />
              <br />
              <div className="row mx-2 ">
                <div className="col-md-4 align-items-center">
                  <label>Debit Account</label>
                  <input
                    disabled={true}
                    className="form-control "
                    type="input"
                    value={`${POS?.Salutation ?? ""} ${
                      POS["MiddleName"] ?? ""
                    } ${POS["Name"] ?? ""} ${POS["LastName"] ?? ""}`}
                    placeholder="Enter Receipt"
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>EnterDate</label>
                  <input
                    className="form-control "
                    type="date"
                    name="EnterDate"
                    value={EnterDate}
                    onChange={(e) => setEnterDate(e.target.value)}
                    placeholder="Enter Receipt "
                    required //done
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Credit Account</label>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={[
                      {
                        _id: "",
                        Name: "-- Select Credit Account",
                      },
                      ...CreditAccountOpt,
                    ]}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => {
                      const Name = `${option?.Name}`;
                      return Name;
                    }}
                    onChange={setCreditAccount}
                    value={CreditAccount}
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label>Remark</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Remark"
                    value={Remark}
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Remark"
                    required //done
                  />
                </div>
                {/* <div
                  className="col-md-4 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label>TDS Account</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={[
                      {
                        _id: "",
                        Name: "-- Select TDS Account --",
                      },
                      ...TDSOpt,
                    ]}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => {
                      const Name = `${option?.Name}`;
                      return Name;
                    }}
                    onChange={setTDSAccount}
                    value={TDSAccount}
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label>TDS Percent</label>
                  <input
                    className="form-control "
                    type="number"
                    name="TDSPercent"
                    value={TDSPercent}
                    onChange={(e) => setTDSPercent(e.target.value)}
                    placeholder="Enter TDS Percent "
                    required //done
                  />
                </div> */}
              </div>
            </div>
            <div className="card-body CreateCard">
              <div
                className="p-2 filters"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "unset",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                    zoom: "80%",
                  }}
                >
                  <div className="d-flex gap-3">
                    <SearchInputFilter
                      setInput={setInput}
                      inputData={inputData}
                      PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                    />
                  </div>
                </div>
              </div>

              <div className="PolicyCounts">
                <div className="PolicyCounts-Item">
                  <b>Total Net Amount : </b> {NetAmount}
                </div>

                <div className="PolicyCounts-Item">
                  {" "}
                  <b>Total Amount : </b> {TotalAmount}
                </div>
              </div>

              {loading ? (
                <div
                  className=" p-2 "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <TableSkeleton />
                </div>
              ) : (
                <div
                  className=" p-2 "
                  style={{
                    zoom: "70%",
                  }}
                >
                  {Data && (
                    <table
                      class="table table-striped table-bordered overflow-x mt-3"
                      id="table-to-xls"
                    >
                      <thead>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            S/N
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Date
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Policy Details
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}></th>
                          <th scope="col" style={{ textAlign: "center" }}></th>
                          <th scope="col" style={{ textAlign: "center" }}></th>

                          <th scope="col" style={{ textAlign: "center" }}>
                            Vehicle No.
                          </th>

                          <th scope="col" style={{ textAlign: "center" }}>
                            Amount To Pay
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {FilteredPolicy &&
                          FilteredPolicy?.length !== 0 &&
                          FilteredPolicy.map((data, index) => (
                            <tr key={data._id}>
                              <th>{index + 1}</th>
                              <th>{reversDate(data?.IssueDate)}</th>
                              <th>
                                <tr> {data?.InsuranceCompany?.Name}</tr>
                                <tr> {data?.InsuranceType?.InsuranceType}</tr>
                                <tr> RSD:-{reversDate(data?.RSD)}</tr>
                                <tr> {data?.InsureeName}</tr>
                              </th>

                              <th>
                                <tr>
                                  <Link
                                    to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    {data?.PolicyNumber}
                                  </Link>{" "}
                                </tr>
                                <tr> {data?.MakeModal?.Name}</tr>
                                <tr> {data?.Variant}</tr>
                                <tr> YOM- {data?.YearOfManufacture}</tr>
                              </th>

                              <th>
                                <tr>Gross </tr>
                                <tr>Net </tr>
                                <tr>TP</tr>
                                <tr>OD</tr>
                              </th>
                              <th>
                              <tr> {coverAmountIntoCurrent(data?.GrossPremium)}</tr>
                                <tr> {coverAmountIntoCurrent(data?.NETPremium)}</tr>
                                <tr> {coverAmountIntoCurrent(data?.TPPremium)}</tr>
                                <tr> {coverAmountIntoCurrent(data?.ODPremium)}</tr>
                              </th>
                              <th>
                                <tr> {data?.PaymentMode?.TypeOfPaymentMode}</tr>
                                <tr> {data?.PolicyType?.Name}</tr>
                                <tr> {data?.VehicleNumber}</tr>
                                {/* <tr> {data?.ODPremium}</tr> */}
                              </th>
                              <th>
                                <tr> {coverAmountIntoCurrent(data?.Commission?.TotalAmountToPay)}</tr>
                              </th>
                            </tr>
                          ))}
                        <tr>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>

                          <th scope="row"> {coverAmountIntoCurrent(TotalAmount ?? 0)}</th>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {
                    <div
                      style={{
                        backgroundColor: "#1a2e45d7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        marginTop: "5px",
                      }}
                    ></div>
                  }
                </div>
              )}
            </div>
            <div className="col-lg-5" style={{ float: "left" }}></div>
            <div className="col-lg-2" style={{ float: "left" }}>
              <div className="d-flex gap-3 ">
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={() => {
                    navigate("/admin/vouchers/Purchase/Return");
                  }}
                >
                  Go Back
                </button>

                {
                  <button
                    type="submit"
                    className="btn mybtn"
                    // style={{ marginTop: 28 }}
                    onClick={() => {
                      handleEdit();
                    }}
                  >
                    Submit
                  </button>
                }
              </div>
            </div>
            <div className="col-lg-5" style={{ float: "left" }}></div>
          </div>
        }
      </div>
      <Footer />
    </>
  );
};

export default EditPurchaseReturn;
