import React from "react";
import exportFromJSON from "export-from-json";
import { AiOutlineFileExcel } from "react-icons/ai";

function maskMobileNumber(mobileNo) {
  return mobileNo.replace(/^(\d{2})\d{6}(\d{2})$/, "$1******$2");
}

const ImportPOSExcel = ({ fileName, getDataExport }) => {
  const exportType = exportFromJSON.types.csv;
  const KeyArray = [
    "Code",
    "Name",
    "Alias Name",
    "OpeningBalance",
    "Email",
    "Mobile",
    "Branch",
    "Club",
    "Examined",
    "PayoutCycle",
    "DOB",
    "DOA",
    "GSTINNumber",
    "PanNumber",
    "AadhaarNumber",
    "RM Name (MOTOR)",
    "RM Name (Non-MOTOR)",
    "createdAt",
  ];
  const downloadFile = async () => {
    const newArr = [];

    const excelData = await getDataExport();

    excelData.forEach((value) => {
      const myObj = {};

      for (const key of KeyArray) {
        if (key === "createdAt" || key === "updatedAt") {
          myObj[key] = value[key].split("T")[0];
          continue;
        }
        if (key === "Name") {
          const {
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
          } = value;
          myObj["Name"] = `${Salutation ?? ""} ${Name ?? ""} ${
            MiddleName ?? ""
          } ${LastName ?? ""}`;
          continue;
        }
        if (key === "Mobile") {
          myObj[key] = value?.["Mobile"]
            ? maskMobileNumber(value?.["Mobile"]?.toString())
            : "-";
          continue;
        }
        if (key === "PanNumber") {
          myObj[key] = value["PanNumber"].toUpperCase();
          continue;
        }
        if (key === "Alias Name") {
          myObj[key] = value["AliasName"];
          continue;
        }
        if (key === "Club") {
          myObj[key] = value?.Club?.Name;
          continue;
        }
        if (key === "Branch") {
          myObj[key] = value?.Branch?.BranchName;
          continue;
        }
        if (key === "RM Name (MOTOR)") {
          myObj[key] = `${value?.ReportingToMotor?.Name || ""} ${
            value?.ReportingToMotor?.MiddleName
          } ${value?.ReportingToMotor?.LastName || ""}`;
          continue;
        }
        if (key === "RM Name (Non-MOTOR)") {
          myObj[key] = `${value?.ReportingToNonMotor?.Name || ""} ${
            value?.ReportingToNonMotor?.MiddleName || ""
          } ${value?.ReportingToMotor?.LastName || ""}`;
          continue;
        }
        myObj[key] = value[key] ?? "";

        newArr.push(myObj);
      }
    });

    const jsonObject = newArr.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    exportFromJSON({
      data: uniqueArray,
      fileName: fileName,
      exportType: exportType,
    });
  };

  return (
    <>
      <AiOutlineFileExcel
        onClick={downloadFile}
        size={35}
        style={{
          backgroundColor: "green",
          color: "#fff",
          padding: "7px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      />
    </>
  );
};

export default ImportPOSExcel;
