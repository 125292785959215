import React, { useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const CreateClub = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};

  const [Name, setName] = useState(value?.Name || "");
  const [sNo, setSNo] = useState(value?.sNo || "");

  const [HOMargin, setHOMargin] = useState(value?.HOMargin || "");

  const [CPMargin, setCPMargin] = useState(value?.CPMargin || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Club", {
        Name,
        HOMargin: HOMargin || 0,
        CPMargin: CPMargin || 0,
        sNo: sNo || 0,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Club/data");
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Club/${value?._id}`, {
        Name,
        HOMargin: HOMargin || 0,
        CPMargin: CPMargin || 0,
        sNo: sNo || 0,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Club/data");
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              zoom: "90%",
            }}
          >
            <h5>{view ? "View Club" : Mode ? "Edit Club" : "Create Club"}</h5>
            <hr />
            <br />
            <div className="row gap-3">
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label required>SNo.</label>
                <input
                  className="form-control "
                  disabled={view}
                  type="number"
                  name="sNo"
                  value={sNo}
                  onChange={(e) => setSNo(e.target.value)}
                  placeholder="Enter SNo"
                  required //done
                />
              </div>
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label required>Name</label>
                <input
                  className="form-control "
                  disabled={view}
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                  required //done
                />
              </div>
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label required>HOM</label>
                <input
                  className="form-control "
                  disabled={view}
                  type="number"
                  name="HOMargin"
                  value={HOMargin}
                  onChange={(e) => setHOMargin(e.target.value)}
                  placeholder="HOMargin"
                  required //done
                />
              </div>
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label required>CPM</label>
                <input
                  className="form-control "
                  disabled={view}
                  type="number"
                  name="CPMargin"
                  value={CPMargin}
                  onChange={(e) => setCPMargin(e.target.value)}
                  placeholder="CPMargin"
                  required //done
                />
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn mybtn"
              onClick={() => navigate("/admin/masters/Club/data")}
            >
              Back
            </button>
            &nbsp;
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateClub;
