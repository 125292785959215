import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import AsyncSelect from "react-select/async";
import { returnInsuranceTypeValue } from "../../Utils/usefullFunction";
const CreateVehicleModal = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const { value, Mode, view } = state || {};
  const { id } = useParams();
  const [DefaultMakeModalOptions, setDefaultMakeModalOption] = useState([]);

  const getDefaultMakeModalOption = async (
    insuranceType = "",
    reset = false
  ) => {
    if (!insuranceType) {
      setDefaultMakeModalOption((pre) => {
        return [{ _id: "", Name: "-- Select Make Modal --" }];
      });
    }
    const {
      data: { data = [] },
    } = await axiosInstance.get(
      `/MakeModal/getMakeModelSelectBox?InsuranceType=${insuranceType}`
    );

    if (!reset) {
      setMakeModal({ _id: "", Name: "-- Select Make Modal --" });
    }
    setDefaultMakeModalOption((pre) => {
      return [{ _id: "", Name: "-- Select Make Modal --" }, ...data];
    });
  };
  const MakeModalPromiseOptions = async (inputValue) => {
    try {
      const id = returnInsuranceTypeValue(SelectedInsuranceType);
      if (!id) {
        return;
      }
      const {
        data: { data },
      } = await axiosInstance.get(
        `/MakeModal/getMakeModelSelectBox?inputData=${inputValue}&InsuranceType=${id}`
      );
      return data;
    } catch (error) {}
  };

  const convertName = (NAME) => {
    const pattern = /^([\d\sA-Za-z]+)/;
    const match = NAME.match(pattern);

    let MyName;
    if (match) {
      MyName = match[1];
    } else {
      MyName = NAME;
    }

    return MyName || "";
  };
  const [VariantName, setVariantName] = useState(value?.VariantName || "");
  const [FuelType, setFuelType] = useState(value?.FuelType || "");
  const [Seater, setSeater] = useState(value?.Seater || "");
  const [Engine, setEngine] = useState(value?.Engine || "");
  const [FuelTypeData, setFuelTypeData] = useState([]);
  const [MakeModal, setMakeModal] = useState({
    _id: "",
    Name: "-- Select Make Modal --",
  });
  const handleCreate = async () => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    const insuranceType = SelectedInsuranceTypeData.length - 1;

    if (!VariantName) {
      return ToastWarn("VariantName is Required");
    }

    if (!SelectedInsuranceTypeData[insuranceType]) {
      return ToastWarn("InsuranceType is Required");
    }
    if (!MakeModal["_id"]) {
      return ToastWarn("MakeModal is Required");
    }

    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/VehicleModal/create`, {
        Variant: {
          VariantName,
          FuelType,
          Seater,
          Engine,
        },
        MakeModal: MakeModal["_id"],
        InsuranceType: SelectedInsuranceTypeData[insuranceType],
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/VehicleModal/data");
      } else {
        ToastSusses(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const getFuelType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/MakeModal/getAllFuelType");

      setFuelTypeData(
        data[0]["FuelType"].filter((ele) => {
          return ele !== null;
        }) ?? []
      );
      // setInsuranceTypeData(data);
    } catch (error) {}
  };
  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    }
  };

  useEffect(() => {
    callInsuranceData([]);
    getFuelType();
  }, []);
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="card-body">
              <h4 className="card-title">
                <h5>Create VehicleModal</h5>
              </h4>
              <hr />
              <br />

              <div className="row">
                <div className=" col-md-4 align-items-center">
                  <label required>Variant Name</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Name"
                    value={VariantName}
                    onChange={(e) => setVariantName(e.target.value)}
                    placeholder="Enter VehicleModal Name"
                    required //done
                    disabled={view}
                  />
                </div>
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsurance
                          key={index}
                          Options={data}
                          setSelectedInsuranceType={setSelectedInsuranceType}
                          getMakeModal={getDefaultMakeModalOption}
                          view={view}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                          // col=" col-md-4 align-items-center"
                          // InsuranceCompany={Policy?.InsuranceCompany}
                        />
                      )
                  )}
                {
                  <div className="col-md-4 align-items-center">
                    <label required>Make/Modal</label>
                    {/* <select
                      disabled={view}
                      class="form-select"
                      aria-label="Default select example"
                      name="MakeModal"
                      value={MakeModal}
                      onChange={(e) => setMakeModal(e.target.value)}
                      required
                    >
                      <option value="">
                        -------------Select Make/Modal-------------
                      </option>
                      {MakeModalOpt &&
                        MakeModalOpt.map((data) => (
                          <option value={data._id}>{data.Name}</option>
                        ))}
                     
                    </select> */}
                    <AsyncSelect
                      cacheOptions
                      defaultOptions={DefaultMakeModalOptions}
                      loadOptions={MakeModalPromiseOptions}
                      onChange={setMakeModal}
                      value={MakeModal}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["Name"]}
                    />
                  </div>
                }

                <div className=" col-md-4 align-items-center">
                  <label>FuelType</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setFuelType(e.target.value)}
                    value={FuelType}
                    required
                  >
                    <option value={""}>Select Fuel Type</option>
                    {FuelTypeData &&
                      FuelTypeData.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                    {/* <option value="self">self</option> */}
                  </select>
                </div>

                <div className=" col-md-4 align-items-center">
                  <label>Seater</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Name"
                    value={Seater}
                    onChange={(e) => setSeater(e.target.value)}
                    placeholder="Enter Seater Data"
                    required //done
                    disabled={view}
                  />
                </div>

                <div className=" col-md-4 align-items-center">
                  <label>Engine</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Engine"
                    value={Engine}
                    onChange={(e) => setEngine(e.target.value)}
                    placeholder="Enter Engine "
                    required //done
                    disabled={view}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/VehicleModal/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => handleCreate()}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateVehicleModal;
