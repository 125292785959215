import React from "react";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import "./FileUploadModal.css";
import IconImage from "../utils/UiImages/UploadImage.png";
import pdfImg from "../utils/UiImages/pdfImg.png";
import { useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
const ImageUploaderForBank = ({
  Mode,
  Url,
  handleOpen = () => {},
  setChangeHandler,
  Name,
  index,
  deleteUrl = "/Pos/deleteImage",
  filedName,
  id,
  setBankDetails,
  BankDetails,
}) => {
  const [IsEdit, setIsEdit] = useState(!Mode);
  const [ImageUrl, setImageUrl] = useState(
    Url ? `${REACT_APP_FileURL}${Url}` : ""
  );

  const [IsPdf, setIsPdf] = useState(Url?.includes(".pdf") ? true : false);
  const [dragging, setDragging] = useState(false);

  const handleFileHandle = (file) => {
    if (file.type.includes("pdf")) {
      let fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        setImageUrl(fileLoadedEvent.target.result);
      };
      fileReader.readAsDataURL(file);
      setIsPdf(true);
    } else {
      const fileUrl = URL.createObjectURL(file);
      setIsPdf(false);
      setImageUrl(fileUrl);
    }
    setIsEdit(false);
  };

  const deleteImageHandler = async () => {
    try {
      if (!id) {
        setIsEdit(true);
        return;
      }
      const isDelete = window.confirm("Image delete into a Database");
      if (!isDelete) {
        return;
      }

      const {
        data: { success, message, data },
      } = await axiosInstance.put(deleteUrl, {
        fieldName: filedName,
        id,
      });
      const myBankDetailsData = BankDetails.filter(
        (ele) => ele._id !== data._id
      );

      setBankDetails([...myBankDetailsData, data]);
      if (success) {
        ToastSusses(message);
        setIsEdit(true);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setChangeHandler(droppedFile);
    handleFileHandle(droppedFile);
  };

  return (
    <div className="Document_Upload">
      <div
        className=""
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <label
          style={{
            marginBottom: "10px",
          }}
        >
          {" "}
          {Name}
        </label>
        {!IsEdit && ImageUrl ? (
          <div
            style={{
              border: "1px solid #ddd",
              height: "150px",
              width: "150px",
              position: "relative",
            }}
          >
            <label className="PlusBox">
              <span className="material-symbols-outlined icon " id="icon">
                <img
                  src={
                    IsPdf
                      ? pdfImg
                      : ImageUrl
                      ? ImageUrl
                      : `${REACT_APP_FileURL}${Url}`
                  }
                  style={{
                    height: "150px",
                    width: "150px",
                  }}
                  alt="IconImage"
                  className="UrlData"
                />
              </span>
            </label>
            <div
              className="Edit-image"
              onClick={() => {
                setIsEdit(true);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: "-13px",
                right: "76px",
              }}
            >
              <BiEdit
                style={{
                  fontSize: 15,
                }}
              />
            </div>
            <div
              className="Delete-image"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: "-13px",
                right: "45px",
              }}
            >
              <AiFillDelete
                style={{
                  fontSize: 15,
                }}
                onClick={() => deleteImageHandler()}
              />
            </div>
          </div>
        ) : (
          <div
            className=""
            style={{
              border: "1px solid #ddd",
              height: "150px",
              width: "150px",
            }}
          >
            <label htmlFor={`FileDisplay${index}`} className="PlusBox">
              <span className="material-symbols-outlined icon " id="icon">
                <img
                  src={IconImage}
                  style={{ width: "100%" }}
                  alt="IconImage"
                />
              </span>
            </label>
            <input
              // disabled={view}
              className="form-control"
              type="file"
              name={Name}
              id={`FileDisplay${index}`}
              onChange={(e) => {
                setChangeHandler(e.target.files[0]);
                handleFileHandle(e.target.files[0]);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploaderForBank;
