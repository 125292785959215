import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import { MdPendingActions } from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import { AiOutlineStop } from "react-icons/ai";
import { BsShieldCheck } from "react-icons/bs";
import { Tab, TablePagination, Tabs } from "@mui/material";
import AutocompleteComponent from "../../Utils/AutoCompleteComponent";
import Header from "../Header/Header";
import { FiRefreshCcw } from "react-icons/fi";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import {
  convertDateStringToDate,
  coverAmountIntoCurrent,
} from "../../Utils/usefullFunction";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { FaEye } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import Test from "../../Utils/Test";
import Tooltip from "../utils/Tooltip/Tooltip";
import DateRange from "../DateRangeModule/DateRange";

import useGettingData from "../Hooks/useGettingData";

import { useDispatch, useSelector } from "react-redux";
import MultiInsuranceTypeFilter from "../../Utils/MultiInsuranceTypeFilter";
import { ToastError, ToastSusses } from "../../Utils/Tost";

const dateFilterCombo = [
  { label: "Issue Date", value: "IssueDate" },
  { label: "Start Date (RSD)", value: "RSD" },
  { label: "Created At", value: "createdAt" },
];

const CPPolicy = () => {
  const dispatch = useDispatch();
  const {
    Policy,
    TabsValue,
    PayoutCycle,
    InsuranceCompany,
    Broker,
    POS,
    Branch,
    pagination,
    rowsPerPage,
    SavedData,
    SavedTotalDocs,
    Status,
    Premium,
    SelectedInsuranceType,
  } = useSelector((store) => store.CPPolicyFilters);
  const { user } = useSelector((store) => store.user);

  const today = new Date();
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [data, setData] = useState([]);
  const [CPMargin, setCPMargin] = useState({});
  const {
    getData: getInsuranceCompany,
    Loading: CompanyLoading,
    Options: InsuranceCompanyOpt,
  } = useGettingData("/InsuranceCompany", [
    {
      _id: "",
      AliasName: "-- Select Company --",
    },
  ]);

  let {
    getData: getBranch,
    Loading: BranchLoading,
    Options: BranchOpt = [
      {
        _id: "",
        BranchName: "-- Select Branch --",
      },
      user?.Branch,
    ],
  } = useGettingData("/Branch", [
    {
      _id: "",
      BranchName: "-- Select Branch --",
    },
  ]);

  const [LOB, setLOB] = useState("Motor");
  const [inputData, setInput] = useState("");
  const [POSOpt, setPOSOpt] = useState([]);
  const [POSLoading, setPOSLoading] = useState("");
  const [PremiumLoading, setPremiumLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [IsFirstRendering, setIsFirstRendering] = useState(true);
  const [totalDocs, setTotalDocs] = useState(0);

  const handleChangePage = (event, newPage) => {
    onSetPolicyFilter("pagination", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    onSetPolicyFilter("rowsPerPage", parseInt(event.target.value));
    onSetPolicyFilter("pagination", 0);
  };

  const filterDataHandler = async ({
    EraserHandler = false,
    StopLoading = false,
    notCallOther = true,
  }) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    try {
      !StopLoading && setLoading(true);

      if (!user) return;

      let que = {};

      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS && POS["_id"]) {
        que["POS"] = POS["_id"];
      }
      if (PayoutCycle) {
        que["PayoutCycle"] = PayoutCycle;
      }

      // if (Branch["_id"]) {
      //   que["Branch"] = Branch["_id"];
      // }

      if (Branch["_id"] || (user && user?.Branch?.BranchCode !== "0")) {
        que["Branch"] =
          user && user?.Branch?.BranchCode === "0"
            ? Branch["_id"]
            : user?.Branch?._id;
      }

      if (!EraserHandler) {
        que = {
          ...que,
          ...Policy,
          InsuranceType: SelectedInsuranceTypeData,
        };
      } else {
        que = {};
        que["startDate"] = firstDay;
        que["endDate"] = lastDay;
        que["PayoutCycle"] = "";
        que["InsuranceType"] = [];
      }

      const {
        data: { data, totalDocs },
      } = await axiosInstance.post("/Policy/CPPolicy", {
        page: pagination + 1,
        limit: rowsPerPage,
        TabsValue: TabsValue,
        inputData,
        ...que,
      });
      setTotalDocs(totalDocs);
      setData(data);
      onSetPolicyFilter("SavedData", data);
      onSetPolicyFilter("SavedTotalDocs", totalDocs);
      getStatus({ que });

      !StopLoading && setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getStatus = async ({ que = {} }) => {
    try {
      const {
        data: { StatusCount },
      } = await axiosInstance.post("/Policy/getCPPolicyStatus", {
        page: pagination + 1,
        limit: rowsPerPage,
        TabsValue: TabsValue,
        inputData,
        ...que,
      });
      onSetPolicyFilter("Status", StatusCount);
    } catch (error) { }
  };

  const getPolicyPos = async () => {
    setPOSLoading(true);
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        branch: Branch?._id,
        showFiled: {
          Code: 1,
          AliasName: 1,
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });

      const POS = data.map(
        ({
          Code,
          AliasName,
          Name = "",
          Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: `${Code}-${Salutation} ${AliasName} (${Name} ${MiddleName} ${LastName})`,
            _id,
          };
        }
      );

      setPOSOpt(POS);
      setPOSLoading(false);
    } catch (error) {
      setPOSLoading(false);
    }
  };

  useEffect(() => {
    if (Branch?._id) {
      getPolicyPos();
    }
  }, [Branch]);

  const EraserHandler = async () => {
    dispatch({
      type: "setCPPolicyFilterDefaultValue",
    });
    setInput("");
    filterDataHandler({ EraserHandler: true });
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };

    onSetPolicyFilter("SelectedInsuranceType", [reSetData]);
  };

  useEffect(() => {
    if (IsFirstRendering) {
      // filterDataHandler({});
      setIsFirstRendering(false);
      if (SavedData.length > 0) {
        setData(SavedData);
        setTotalDocs(SavedTotalDocs);
      } else {
        filterDataHandler({});
      }
    } else {
      filterDataHandler({
        notCallOther: false,
      });
    }
  }, [pagination, rowsPerPage, TabsValue]);

  useEffect(() => {
    if (SelectedInsuranceType.length === 0 || LOB) {
      callInsuranceData();
    }
  }, [LOB]);

  const onChangeSetValue = (e) => {
    onSetPolicyFilter("Policy", {
      ...Policy,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (IsFirstRendering) {
      return;
    }
    const timer = setTimeout(
      () => filterDataHandler({ EraserHandler: false, StopLoading: true }),
      1000
    );
    return () => clearTimeout(timer);
  }, [inputData]);

  const setDateHandler = (ranges) => {
    onSetPolicyFilter("Policy", {
      ...Policy,
      startDate: convertDateStringToDate(ranges.startDate),
      endDate: convertDateStringToDate(ranges.endDate),
    });
  };

  const onSetPolicyFilter = (key, value) => {
    dispatch({
      type: "updateCPPolicyFilters",
      key: key,
      value: value,
    });
  };

  useEffect(() => {
    if (user && user?.Branch && user?.Branch?.BranchCode !== "0") {
      onSetPolicyFilter("Branch", user?.Branch);
    }
  }, [user]);

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
        );
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) { }
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]
            }&InsuranceCategory=${LOB}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) { }
      }

      return onSetPolicyFilter("SelectedInsuranceType", [
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
      );
      onSetPolicyFilter("SelectedInsuranceType", [{ data, value: "" }]);
    }
  };

  const saveNumber = (num, id) => {
    const obj = { ...CPMargin };
    obj[id] = num;
    setCPMargin(obj);
  };

  useEffect(() => {
    if (TabsValue === "FEEDED" && data && data?.length > 0) {
      const CPMarginData = data.reduce((acc, curr) => {
        acc[curr?._id] = curr?.POSRate;
        return acc;
      }, {});

      setCPMargin(CPMarginData);
    }
  }, [TabsValue, data]);

  const saveCPMargin = async (id) => {
    try {
      const response = await axiosInstance.put(`/Policy/putPOSRate/${id}`, {
        POSRate: CPMargin[id],
      });

      if (response.data.success) {
        ToastSusses("CPMargin successfully updated");
        filterDataHandler({});
      }
    } catch (error) {
      ToastError(error.response.data.message);
    }
  };
  
  return (
    <div>
      <Header />
      <div
        className="container-fluid"
        style={{ marginTop: "10px", outline: "none" }}
      >
        <div className="row CreateCard p-4">
          <div className=" p-2 container filters" style={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "baseline",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <SearchInputFilter
                setInput={setInput}
                inputData={inputData}
                PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
              />

              <div
                onClick={() => {
                  getInsuranceCompany();
                }}
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={InsuranceCompanyOpt}
                  noOptionsMessage={() => "No Company Found"}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["AliasName"]}
                  onChange={(ele) => {
                    onSetPolicyFilter("InsuranceCompany", ele);
                    // setInsuranceCompany(ele);
                  }}
                  value={InsuranceCompany}
                  isLoading={CompanyLoading}
                  styles={{ backgroundColor: "#F5F5F6", height: "42px" }}
                />
              </div>
              <div
                className=""
                onClick={() => {
                  if (user?.Branch?.BranchCode === "0") getBranch();
                }}
              >
                {/* <div className=""> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={user?.Branch?.BranchCode !== "0"}
                  options={BranchOpt.length === 0 ? [user?.Branch] : BranchOpt}
                  isLoading={BranchLoading}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["BranchName"]}
                  value={Branch}
                  onChange={(ele) => {
                    onSetPolicyFilter("Branch", ele);
                    // setBranch(ele);
                  }}
                  styles={{ height: "42px" }}
                />
              </div>
              <div style={{ position: "relative", top: "-9px" }}>
                <AutocompleteComponent
                  placeholder="---- Select POS ----"
                  styles={{ width: "450px" }}
                  value={POS || null}
                  options={POSOpt || []}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  onChange={(event, newValue) =>
                    onSetPolicyFilter("POS", newValue)
                  }
                />
                {/* <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={POSOpt}
                  noOptionsMessage={() => "No POS Found"}
                  isLoading={POSLoading}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={(ele) => {
                    onSetPolicyFilter("POS", ele);
                    // setPOS(ele);
                  }}
                  value={POS}
                  styles={{ height: "42px" }}
                /> */}
              </div>
            </div>
            <div className="d-flex justify-content-between pt-2 align-items-center">
              <div className="d-flex gap-2 ">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div className="">
                    <select
                      class="form-select SelectBoxWith"
                      aria-label="Default select example"
                      name="filterOn"
                      value={Policy?.filterOn}
                      onChange={(e) => onChangeSetValue(e)}
                      required
                      styles={{ backgroundColor: "#F5F5F6", height: "42px" }}
                    >
                      {dateFilterCombo &&
                        dateFilterCombo.map((data) => (
                          <option value={data.value}>{data.label}</option>
                        ))}
                      {/* <option value="self">self</option> */}
                    </select>
                  </div>
                  <div>
                    <input
                      className="form-control "
                      type="date"
                      name="startDate"
                      style={{ backgroundColor: "#F5F5F6", height: "42px" }}
                      value={Policy?.startDate}
                      onChange={(e) => onChangeSetValue(e)}
                      // placeholder="Enter  PolicyNumber"
                      required //done
                    />
                  </div>
                  <p>-</p>
                  <div>
                    <input
                      className="form-control "
                      type="date"
                      name="endDate"
                      style={{ backgroundColor: "#F5F5F6", height: "42px" }}
                      value={Policy?.endDate}
                      onChange={(e) => onChangeSetValue(e)}
                      // placeholder="Enter  PolicyNumber"
                      required //done
                    />
                  </div>
                </div>
                <div>
                  <DateRange setDateHandler={setDateHandler} />
                </div>
                <div className="">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="LOB"
                    value={LOB}
                    // style={{ backgroundColor: "#f1e5ac" }}
                    onChange={(e) => {
                      onSetPolicyFilter("SelectedInsuranceType", [
                        { data: [], value: "" },
                      ]);
                      setLOB(e.target.value);
                    }}
                    required
                  >
                    <option value="Motor">Motor</option>
                    <option value="Non-Motor">Non-Motor</option>
                  </select>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  {SelectedInsuranceType &&
                    SelectedInsuranceType?.length > 0 &&
                    SelectedInsuranceType.map(
                      (data, index) =>
                        data &&
                        data?.data?.length > 0 && (
                          <MultiInsuranceTypeFilter
                            key={data.value}
                            Options={data}
                            setSelectedInsuranceType={onSetPolicyFilter}
                            SelectedInsuranceType={SelectedInsuranceType}
                            index={index}
                            OptionsValue={data.value}
                          />
                        )
                    )}
                </div>
              </div>
              <div>
                <Tooltip content="Refresh">
                  <FiRefreshCcw
                    size={32}
                    onClick={() => filterDataHandler({})}
                    style={{
                      backgroundColor: "#174379",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "5px",
                      marginTop: "-5px",
                    }}
                  />{" "}
                </Tooltip>
                &nbsp;
                <Link>
                  <Tooltip content="Reset">
                    <MdOutlineRefresh
                      onClick={() => EraserHandler()}
                      size={32}
                      style={{
                        backgroundColor: "#f99d36",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "5px",
                        marginTop: "-5px",
                      }}
                    />
                  </Tooltip>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            style={{ borderTop: "1px solid #ddd" }}
          >
            <div className="row">
              <div
                className=" p-2 container d-flex justify-content-center align-items-center"
                style={{
                  height: "1px",
                }}
              >
                <div className="col-lg-9" style={{ float: "left" }}>
                  <Tabs
                    value={TabsValue}
                    onChange={(ele, value) => {
                      onSetPolicyFilter("TabsValue", value);
                    }}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary icon position tabs example"
                  >
                    <Tab
                      icon={<BiTimer style={{ color: "blue" }} />}
                      value="PENDING"
                      iconPosition="start"
                      label={`PENDING ${Status["PENDING"] ?? ""}`}
                    />
                    <Tab
                      icon={<BiTimer style={{ color: "blue" }} />}
                      value="FEEDED"
                      iconPosition="start"
                      label={`FEEDED ${Status["FEEDED"] ?? ""}`}
                    />
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <div
              className=" p-2 container"
              style={{
                zoom: "80%",
              }}
            >
              {data && (
                <table class="table table-striped table-bordered table-responsive overflow-x mt-0">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "right" }}>SNo.</th>

                      <th style={{ textAlign: "left" }}>Policy</th>
                      <th style={{ textAlign: "left", width: "240px" }}>
                        Insured Name
                      </th>
                      <th style={{ textAlign: "left", width: "210px" }}>POS</th>
                      <th style={{ textAlign: "left", width: "150px" }}>
                        Vehicle No.
                      </th>
                      <th style={{ textAlign: "right", width: "160px" }}>
                        NET Premium
                      </th>
                      <th style={{ textAlign: "right", width: "160px" }}>
                        Gross Premium
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        Branch
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        Inflow
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        Club
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        Old Ho Margin
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        New Ho Margin
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        Club Margin
                      </th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data?.length !== 0 &&
                      data.map((data, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }} scope="row">
                            {index + 1}
                          </td>
                          <td>
                            {" "}
                            <tr
                              className="d-flex"
                              style={{
                                width: "200px",
                              }}
                            >
                              {" "}
                              <img
                                src={`${REACT_APP_FileURL}${data?.InsuranceCompany?.Logo}`}
                                style={{ width: "3vw", borderRadius: "10px" }}
                                loading="lazy"
                                alt=""
                              />
                              &nbsp;&nbsp;{" "}
                              <div>
                                <div> {data?.InsuranceCompany?.AliasName}</div>
                                <Link
                                  to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  state={{
                                    color: "#2424d1",
                                  }}
                                >
                                  {" "}
                                  {data?.PolicyNumber}
                                </Link>
                              </div>
                            </tr>
                            {/* <br/> */}
                            {/* <tr> </tr> */}
                          </td>
                          <td>{data?.InsureeName}</td>
                          <td>
                            {`${data?.POS?.Salutation ?? ""} ${data?.POS?.Name ?? ""
                              } ${data?.POS?.MiddleName ?? ""} ${data?.POS?.LastName ?? ""
                              }`}{" "}
                          </td>

                          <td>{data?.VehicleNumber}</td>

                          {/* <td>{data?.Broker?.Name}</td> */}
                          {/* <td>{data?.Variant}</td> */}
                          {/* <td>
                            {data?.MakeModal?.Make} {data?.Model?.Model}
                            {"-"}
                            {data?.Variant?.VariantName}
                          </td> */}
                          <td className="text-end">
                            {" "}
                            {`${coverAmountIntoCurrent(data?.NETPremium)}`}
                          </td>
                          <td className="text-end">
                            {" "}
                            {`${coverAmountIntoCurrent(data?.GrossPremium)}`}
                          </td>
                          <td className="text-start">
                            {" "}
                            {data?.Branch?.BranchName}
                          </td>
                          <td className="text-start">
                            {" "}
                            {data?.Inflow}
                          </td>
                          <td className="text-start">
                            {" "}
                            {data?.POS?.Club?.Name}
                          </td>
                          <td className="text-start">
                            {" "}
                            {data?.POS?.Club?.HOMargin}
                          </td>
                          <td className="text-start">
                            {
                              data?.POS?.ClubException?.find(item =>
                                data?.InsuranceUnderFlow?.includes(item?.InsuranceType)
                              )?.HOMargin || "Margin Not Found"
                            }
                          </td>

                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Club Margin"
                              value={CPMargin[data?._id] || ""}
                              onChange={(e) =>
                                saveNumber(e.target.value, data?._id)
                              }
                            />
                          </td> */}

                          {/* new club margin with regex */}

                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Club Margin"
                              value={CPMargin[data?._id] !== undefined && CPMargin[data?._id] !== null ? CPMargin[data?._id] : ""}
                              onChange={(e) => {
                                const maxLimit = (data?.Inflow || 0) - (data?.POS?.ClubException[0]?.HOMargin || 0);
                                const inputValue = e.target.value;

                                if (inputValue === "") {
                                  saveNumber("", data?._id);
                                  return;
                                }

                                const newValue = parseFloat(inputValue);
                                if (newValue <= maxLimit) {
                                  saveNumber(newValue, data?._id);
                                } else {
                                  alert(`Value cannot be more than ${maxLimit}`);
                                }
                              }}
                            />
                          </td>




                          <th scope="row">
                            {" "}
                            <div className="col-md-4">
                              <div className="">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-center",
                                  }}
                                >
                                  <button
                                    disabled={CPMargin[data?._id] !== undefined && CPMargin[data?._id] !== null ? false : true}
                                    onClick={saveCPMargin.bind(null, data?._id)}
                                    className="btn mybtn"
                                  >
                                    Save
                                  </button>

                                  &nbsp;&nbsp;
                                  <Link
                                    className=""
                                    to={`/admin/view/Policy/${data._id}`}
                                  >
                                    <FaEye
                                      size={35}
                                      style={{
                                        backgroundColor: "#f99d36",
                                        color: "#fff",
                                        padding: "7px",
                                        borderRadius: "5px",
                                      }}
                                    />{" "}
                                  </Link>{" "}
                                </div>
                                {/* <div className="">
                                  {TabsValue !== "APPROVED" && (
                                    <Link
                                      className=""
                                      state={{
                                        value: {
                                          ...data,
                                          Broker: data?.Broker?._id,
                                          InsuranceCompany:
                                            data?.InsuranceCompany._id,
                                          InsuranceType:
                                            data?.InsuranceType?._id,
                                          MakeModal: data?.MakeModal?._id,
                                          POS: data?.POS?._id,
                                          PolicyType: data?.PolicyType?._id,
                                        },
                                        Mode: true,
                                      }}
                                      to={`/admin/Policy/${data._id}`}
                                    >
                                      <BiEdit
                                        size={35}
                                        style={{
                                          backgroundColor: "green",
                                          color: "#fff",
                                          padding: "7px",
                                          borderRadius: "5px",
                                        }}
                                      />{" "}
                                    </Link>
                                  )}
                                </div>
                                {TabsValue === "PENDING" && (
                                  <div className="">
                                    <AiFillDelete
                                      size={35}
                                      style={{
                                        backgroundColor: "#A50406",
                                        color: "#fff",
                                        padding: "7px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const isTrue = window.confirm(
                                          "Do you want to delete!"
                                        );
                                        if (isTrue) {
                                          deletePolicy(data?._id);
                                        }
                                      }}
                                    />
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {
                <div
                  style={{
                    backgroundColor: "#1a2e45d7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <TablePagination
                    component="div"
                    className="text-white"
                    rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                    count={totalDocs ?? 0}
                    page={pagination}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CPPolicy;

//    AllKey={AllKey}
// data={filteredData}
// editUrl={"/admin/Policy"}
// Mode={true}
// deleteFunction={deletePolicy}
