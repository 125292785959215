import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";

const SearchInputFilter = ({
  setInput,
  inputData,
  PlaceHolder = "Search ...",
  style = {},
}) => {
  return (
    <div className="searchFilter-box" style={{ zoom: "88%" }}>
      <input
        type="text"
        className="form-control searchFilter"
        style={{ ...style }}
        placeholder={PlaceHolder}
        onChange={(e) => {
          const vla = e.target.value;

          setInput(vla);
        }}
        value={inputData}
      />
      <BiSearchAlt2 className="span-Icon" />
    </div>
  );
};

export default SearchInputFilter;
