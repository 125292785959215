import React, { useEffect, useState } from "react";
import { axiosInstance } from "../config";

const MultiInsuranceTypeFilter = ({
  Options,
  SelectedInsuranceType,
  setSelectedInsuranceType,
  index,
  OptionsValue,
  view,
}) => {
  const [InsuranceType, setInsuranceType] = useState(OptionsValue ?? "");
  const [IsFirstCall, setIsFirstCall] = useState(true);

  const getDataInsuranceType = async () => {
    const selectedObj = JSON.parse(JSON.stringify(SelectedInsuranceType));
    if (!InsuranceType) {
      selectedObj[index]["value"] = "";
      selectedObj.length = index + 1;
      return setSelectedInsuranceType("SelectedInsuranceType", [
        ...selectedObj,
      ]);
    }

    try {
      const {
        data: { data = [] },
      } = await axiosInstance.get(
        `/InsuranceType?InsuranceUnder=${InsuranceType}&InsuranceCompany=${""}`
      );
      selectedObj[index]["value"] = InsuranceType;

      selectedObj.length = index + 1;
      if (data && data?.length !== 0) {
        return setSelectedInsuranceType("SelectedInsuranceType", [
          ...selectedObj,
          { data, value: "" },
        ]);
      } else {
        return setSelectedInsuranceType("SelectedInsuranceType", [
          ...selectedObj,
        ]);
      }
    } catch (error) {
      console.log(error, "check this error");
    }
  };
  useEffect(() => {
    if (IsFirstCall) {
      return setIsFirstCall(false);
    }
    getDataInsuranceType();
  }, [InsuranceType]);

  return (
    <>
      {
        <div>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) => {
              setInsuranceType(e.target.value);
            }}
            key={OptionsValue}
            value={InsuranceType}
            style={{
              width: "10vw",
              // backgroundColor: "#F5F5F6",
              height: "38px",
            }}
            required={index === 0 ? true : false}
            disabled={view}
          >
            <option value="">--Product--</option>
            {Options &&
              Options?.data &&
              Options?.data.length > 0 &&
              Options?.data.map((data) => (
                <option value={data._id}>{`${data.InsuranceType} ${
                  data?.InsuranceCompany?.Name ? "-" : ""
                } ${data?.InsuranceCompany?.Name ?? ""}`}</option>
              ))}
          </select>
        </div>
      }
    </>
  );
};

export default MultiInsuranceTypeFilter;
