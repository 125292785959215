import React, { useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";

import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const CreateBranch = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [BranchName, setBranchName] = useState(value?.BranchName || "");

  const [BranchCode, setBranchCode] = useState(value?.BranchCode || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Branch", {
        BranchName,
        BranchCode,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Branch/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Branch/${value?._id}`, {
        BranchName,
        BranchCode,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Branch/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="col-lg-12">
              <div className="">
                <div className="">
                  <h4 className="card-title">
                    {view
                      ? "View Branch"
                      : Mode
                      ? "Edit Branch"
                      : "Create New Branch"}
                  </h4>
                  <hr />

                  <div data-repeater-item="" className="outer">
                    <div
                      className="col-4"
                      style={{ float: "left", padding: 15 }}
                    >
                      <label className="form-label" htmlFor="formname">
                        Branch Name
                      </label>
                      <input
                        className="form-control "
                        type="text"
                        name="BranchName"
                        value={BranchName}
                        onChange={(e) => setBranchName(e.target.value)}
                        placeholder="Enter Branch Name"
                        required //done
                        disabled={view}
                      />
                    </div>
                    <div
                      className="col-4"
                      style={{ float: "left", padding: 15 }}
                    >
                      <label className="form-label" htmlFor="formemail">
                        Branch Code
                      </label>
                      <input
                        className="form-control "
                        type="text"
                        name="BranchCode"
                        value={BranchCode}
                        onChange={(e) => setBranchCode(e.target.value)}
                        placeholder="BranchCode"
                        required //done
                        disabled={view || BranchCode === "0"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/Branch/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateBranch;
