import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { FaEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";

const MakeModal = () => {
  const [totalDocs, setTotalDocs] = useState(0);
  const [inputData, setInputData] = useState("");
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };
  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}}}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }
      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    }
  };
  const getData = async () => {
    try {
      setLoading(true);
      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");

      const insuranceType = SelectedInsuranceTypeData.length - 1;
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/MakeModal/getMakeModel?page=${
          pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}&InsuranceType=${
          SelectedInsuranceTypeData[insuranceType] ?? ""
        }`
      );

      setTotalDocs(totalDocs);

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteMakeModal = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/MakeModal/deleteMakeModal/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
      } else {
        ToastError(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, pagination]);

  useEffect(() => {
    const timer = setTimeout(getData, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [inputData]);

  useEffect(() => {
    callInsuranceData([]);
  }, []);
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="row mt-4">
              <div className="col-10" style={{ float: "left" }}>
                <div className="ListDesigncol">
                  <h4 className="card-title">Make Modal</h4>
                  <p className="card-title-desc">
                    Make Modal is used to create a new make modal.
                  </p>
                </div>
              </div>
              <div className="col-2" style={{ float: "left" }}>
                <Link
                  className="btn mybtn"
                  to="/admin/MakeModal"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Add Modals
                </Link>
              </div>
            </div>

            <div
              className=" p-2 container filters"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                flexDirection: "row",
                gap: "10px",
                zoom: "80%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <SearchInputFilter
                  setInput={setInputData}
                  inputData={inputData}
                  PlaceHolder="Make modal Name"
                />
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsurance
                          key={data.value}
                          Options={data}
                          setSelectedInsuranceType={setSelectedInsuranceType}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                          IsFilter={true}
                          // InsuranceCompany={PayoutGrid?.InsuranceCompany ?? ""}
                        />
                      )
                  )}
              </div>

              <div className="d-flex gap-2">
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => getData()}
                >
                  <MdSend />
                </div>
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  // onClick={() => EraserHandler()}
                >
                  <BsEraser />
                </div>
              </div>
            </div>

            {loading ? (
              <div
                className="container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TableSkeleton />
              </div>
            ) : (
              <div
                className=" p-2 container"
                style={{
                  zoom: "65%",
                }}
              >
                {data && (
                  <table class="table table-striped   table-bordered  overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Make Name</th>
                        <th scope="col">Modal Name</th>
                        <th scope="col">Insurance Type</th>
                        <th scope="col">EXTRA_OPTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.length !== 0 &&
                        data.map((data, index) => (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{data?.Name}</td>
                            <td>{data?.ModalName}</td>

                            <td>{data?.InsuranceType?.InsuranceType}</td>

                            <th scope="row">
                              {" "}
                              <div className="col-md-2">
                                <div className="d-flex gap-3">
                                  <div>
                                    <Link
                                      className=""
                                      state={{
                                        view: true,
                                      }}
                                      to={`/admin/MakeModal//${data._id}`}
                                    >
                                      <FaEye size={25} color="" />{" "}
                                    </Link>
                                  </div>
                                  <div className="">
                                    <Link
                                      className=""
                                      state={{
                                        Mode: true,
                                      }}
                                      to={`/admin/MakeModal/${data._id}`}
                                    >
                                      <BiEdit size={25} color="blue" />{" "}
                                    </Link>
                                  </div>
                                  <div className="">
                                    <AiFillDelete
                                      color="red"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      size={25}
                                      onClick={() => {
                                        const isTrue = window.confirm(
                                          "Do you want to delete!"
                                        );
                                        if (isTrue) {
                                          deleteMakeModal(data?._id);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {
                  <div
                    style={{
                      backgroundColor: "#1a2e45d7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      marginTop: "5px",
                    }}
                  >
                    <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                      count={totalDocs ?? 0}
                      page={pagination}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MakeModal;
