import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

import { AiOutlineFileExcel } from "react-icons/ai";
import { reversDate } from "./usefullFunction";
const PaymentExvelNew = ({ _id, value, Name }) => {
  const createDownloadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        `${Name}-${value?.EntryDate}`
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wboutput = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wboutput)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    const KeyArray = [
      "PYMT_PROD_TYPE_CODE",
      "PYMT_MODE",
      "DEBIT_ACC_NO",
      "BNF_NAME",
      "BENE_ACC_NO",
      "BENE_IFSC",
      "AMOUNT",
      "DEBIT_NARR",
      "CREDIT_NARR",
      "MOBILE_NUM",
      "EMAIL_ID",
      "REMARK",
      "PYMT_DATE",
      "REF_NO",
      "ADDL_INFO1",
      "ADDL_INFO2",
      "ADDL_INFO3",
      "ADDL_INFO4",
      "ADDL_INFO5",
      // "Date",
      // "Name",
      // "Bank Name",
      // "Account Number",
      // "IFSC CODE",
      // "Section",
      // "Amount",
      // "Remark",
      // "REF_NO",
    ];
    let table = [
      {
        A: "PYMT_PROD_TYPE_CODE",
        B: "PYMT_MODE",
        C: "DEBIT_ACC_NO",
        D: "BNF_NAME",
        E: "BENE_ACC_NO",
        F: "BENE_IFSC",
        G: "AMOUNT",
        H: "DEBIT_NARR",
        I: "CREDIT_NARR",
        J: "MOBILE_NUM",
        K: "EMAIL_ID",
        L: "REMARK",
        M: "PYMT_DATE",
        N: "REF_NO",
        O: "ADDL_INFO1",
        P: "ADDL_INFO2",
        Q: "ADDL_INFO3",
        R: "ADDL_INFO4",
        S: "ADDL_INFO5",
      },
    ];

    value?.PaymentList.forEach((data, index) => {
      const myObj = {};

      for (const key of KeyArray) {
        if (key === "PYMT_DATE") {
          myObj["M"] = reversDate(value?.EntryDate);
          continue;
        }
        if (key === "PYMT_PROD_TYPE_CODE") {
          myObj["A"] = "PAB_VENDOR";
          continue;
        }

        if (key === "DEBIT_ACC_NO") {
          myObj["C"] = value?.CashBankAccount?.AccountNumber
            ? value?.CashBankAccount?.AccountNumber + "\t"
            : "";
          continue;
        }
        if (key === "PYMT_MODE") {
          if (data?.BankDetail?.IFSC.toLowerCase().startsWith("icic")) {
            myObj["B"] = "FT";
            continue;
          }
          if (data?.Account > 200000) {
            myObj["B"] = "RTGS";
            continue;
          } else {
            myObj["B"] = "NEFT";
            continue;
          }
        }

        if (key === "BNF_NAME") {
          // Name Email Salutation LastName MiddleName
          // "LedgerGroup", "POS", "Broker", "Employee"
          switch (data.From) {
            case "POS":
              myObj["D"] = data?.BankDetail?.AccountHolderName
              break;
            case "Broker":
              myObj["D"] = data?.Broker?.Name ?? "";
              break;
            case "Employee":
              {
                let {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = data?.Employee;

                myObj["D"] = `${Salutation ?? ""} ${Name ?? ""} ${MiddleName ?? ""
                  } ${LastName ?? ""}`;
              }
              break;
            case "LedgerGroup":
              myObj["D"] = data?.LedgerEntry?.Name ?? "";
              break;
            default:
          }

          continue;
        }
        if (key === "BENE_ACC_NO") {
          myObj["E"] = data?.BankDetail?.BankAccountNo
            ? data?.BankDetail?.BankAccountNo + "\t"
            : "";
          continue;
        }
        if (key === "BENE_IFSC") {
          myObj["F"] = data?.BankDetail?.IFSC ?? "";
          continue;
        }

        if (key === "AMOUNT") {
          myObj["G"] = data?.Account - data?.TDSAmount;
          continue;
        }
        if (key === "MOBILE_NUM") {
          switch (data.From) {
            case "POS":
              myObj["J"] = data?.PartyAccount?.Mobile
                ? data?.PartyAccount?.Mobile + "\t"
                : "";
              break;
            case "Broker":
              myObj["J"] = "";
              break;
            case "Employee":
              myObj["J"] = data?.Employee?.MobileNumber
                ? data?.Employee?.MobileNumber + "\t"
                : "";
              break;
            case "LedgerGroup":
              myObj["J"] = "";
              break;
            default:
          }
          continue;
        }
        if (key === "EMAIL_ID") {
          switch (data.From) {
            case "POS":
              myObj["K"] = data?.PartyAccount?.Email ?? "";
              break;
            case "Broker":
              myObj["K"] = "";
              break;
            case "Employee":
              myObj["K"] = data?.Employee?.Email ?? "";
              break;
            case "LedgerGroup":
              myObj["K"] = "";
              break;
            default:
          }
          continue;
        }

        if (key === "REF_NO") {
          myObj["L"] = data?.RefNumber ?? "";
          continue;
        }
        if (key === "REMARK") {
          myObj["L"] = value?.Remark ?? "";
          continue;
        }
      }
      table.push(myObj);
    });

    // table = [{ A: "Student Details" }].concat(table);
    const finalData = [...table];

    // 
    // 

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Payment_List");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sl No." ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      //   titleRange: "A1:AG2",
      tbodyRange: `A1:S${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:S${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(10);
        sheet.column("B").width(15);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(20);
        sheet.column("G").width(20);
        sheet.column("H").width(20);
        sheet.column("I").width(25);
        sheet.column("J").width(15);
        sheet.column("K").width(15);
        sheet.column("L").width(15);
        sheet.column("M").width(15);
        sheet.column("N").width(15);
        sheet.column("O").width(20);
        sheet.column("P").width(15);
        sheet.column("Q").width(15);
        sheet.column("R").width(25);
        sheet.column("S").width(20);

        // sheet.range(dataInfo.titleRange).merged(true).style({
        //   bold: true,
        //   horizontalAlignment: "center",
        //   verticalAlignment: "center",
        // });

        // if (dataInfo.tbodyRange) {
        //   sheet.range(dataInfo.tbodyRange).style({
        //     horizontalAlignment: "center",
        //   });
        // }

        // sheet.range(dataInfo.theadRange).style({
        //   bold: true,
        //   horizontalAlignment: "center",
        // });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      <AiOutlineFileExcel
        onClick={createDownloadData}
        size={35}
        style={{
          backgroundColor: "green",
          color: "#fff",
          padding: "7px",
          borderRadius: "5px",
        }}
      />
    </div>
  );
};

export default PaymentExvelNew;
