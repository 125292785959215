import React, { useState } from "react";   import { ToastError, ToastSusses ,ToastInfo,ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
const CreateManufacturer = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [ManufacturerName, setManufacturerName] = useState(
    value?.ManufacturerName || ""
  );
  const navigate = useNavigate();
  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Manufacturer", {
        ManufacturerName,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/Manufacturer/data");
      }
     
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Manufacturer/${value?._id}`, {
        ManufacturerName,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/Manufacturer/data");
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">


            <div className="">
              <h4 className="card-title">
                {view ? "View Manufacturer" : Mode ? "Edit Manufacturer" : "Create New Manufacturer"}
              </h4>
              <hr />
              {/* <br /> */}
              <div className="row">

                <div
                  className="col-4"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Manufacturer Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="ManufacturerName"
                    value={ManufacturerName}
                    onChange={(e) =>
                      setManufacturerName(e.target.value)
                    }
                    placeholder="Enter Manufacturer Name"
                    required //done
                  />
                </div>



              </div>
            </div>


            <div className="d-flex gap-3">
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => navigate("/admin/Manufacturer/data")}
              >
                Go Back
              </button>
              {!view && <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>}


            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateManufacturer;
