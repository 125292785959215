import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";

const CommissionablePremium = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const getDataByPageNation = async () => {
    try {
      setLoading(true);

      const {
        data: { data },
      } = await axiosInstance.get("/CommissionablePremium");
      if (data) {
        setData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCommissionablePremium = async (_id) => {
    try {
      const isTrue = window.confirm("Do you want to delete!");

      if (!isTrue) {
        return;
      }

      const {
        data: { success, message },
      } = await axiosInstance.delete(`/CommissionablePremium/${_id}`);

      if (success) {
        getDataByPageNation();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getDataByPageNation();
  }, []);

  return (
    <>
      <Header placeHolder="Search by, ClubName..." />
      <div
        className="container-fluid"
        style={{ marginTop: "70px", zoom: "85%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div className="row">
                  <div className="col-10" style={{ float: "left" }}>
                    <h4 className="card-title">Commissionable Premium</h4>
                    <p>
                      List of commissionable premium calculations for different
                      insurance companies, policy types, and products.
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <Link
                      className="btn mybtn"
                      to="/admin/masters/Commissionablepremium"
                      state={{
                        Mode: false,
                        value: "",
                      }}
                    >
                      Add More
                    </Link>
                  </div>

                  <div
                    className="d-flex align-items-center mt-2 mb-2"
                    style={{
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: "0 1 auto",
                        width: "250px",
                        minWidth: "150px",
                      }}
                    ></div>

                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <div
                        className=" d-flex align-items-center justify-content-center"
                        onClick={getDataByPageNation}
                      ></div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    <div
                      class="table-responsive"
                      style={{
                        zoom: "80%",
                      }}
                    >
                      <table class="table">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "rgb(23, 67, 121)",
                              color: "rgb(255, 255, 255)",
                            }}
                          >
                            <th scope="col">SNo.</th>
                            <th scope="col" style={{ width: "38%" }}>
                              Insurance Company
                            </th>
                            <th scope="col" style={{ width: "30%" }}>
                              Product
                            </th>
                            <th scope="col">Policy Type</th>
                            <th scope="col">Premium</th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.length !== 0 &&
                            data.map((ele, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>

                                  <th scope="row">
                                    {ele?.InsuranceCompany?.map(
                                      (company, idx) => (
                                        <span
                                          class="badge m-2 p-2"
                                          style={{ backgroundColor: "#174379" }}
                                          key={idx}
                                        >
                                          {company?.label}
                                        </span>
                                      )
                                    )}
                                  </th>

                                  <th scope="row">
                                    {ele?.InsuranceType?.map((type, idx) => (
                                      <span
                                        class="badge m-2 p-2"
                                        style={{ backgroundColor: "#f98800" }}
                                        key={idx}
                                      >
                                        {type?.label}
                                      </span>
                                    ))}
                                  </th>

                                  <th scope="row">
                                    {ele?.PolicyType?.PolicyTypeName}
                                  </th>

                                  <th scope="row">
                                    {ele?.Premium?.join(" + ")}
                                  </th>

                                  <th scope="row">
                                    <div className="col-md-2">
                                      <div className="d-flex gap-3">
                                        <div>
                                          <Link
                                            className=""
                                            state={{
                                              value: ele,
                                              view: true,
                                            }}
                                            to="/admin/masters/CommissionablePremium"
                                          >
                                            <FaEye size={25} color="" />
                                          </Link>
                                        </div>
                                        <div>
                                          <Link
                                            className=""
                                            state={{
                                              value: ele,
                                              Mode: true,
                                            }}
                                            to="/admin/masters/CommissionablePremium"
                                          >
                                            <BiEdit size={25} color="blue" />
                                          </Link>
                                        </div>
                                        <div>
                                          <AiFillDelete
                                            color="red"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            size={25}
                                            onClick={deleteCommissionablePremium.bind(
                                              null,
                                              ele?._id
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CommissionablePremium;
