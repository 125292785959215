import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";

import ExcelExport from "../utils/ExcelExport/ExcelExport";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
const InsuranceType = () => {
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  const getDataByFilter = async (EraserHandler = false) => {
    setLoading(true);
    const que = {};
    if (EraserHandler) {
      que["inputData"] = "";
      que["InsuranceUnder"] = "";
    } else {
      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");
      const insuranceType = SelectedInsuranceTypeData.length - 1;
      que["inputData"] = inputData;
      que["InsuranceUnder"] = SelectedInsuranceTypeData[insuranceType] ?? "";
    }

    try {
      const {
        data: { data, totalDocs = 0 },
      } = await axiosInstance.post(`/InsuranceType/getDataByFilter`, {
        page: pagination + 1,
        limit: rowsPerPage,
        ...que,
      });

      setTotalDocs(totalDocs);
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const EraserHandler = async () => {
    setInput("");
    getDataByFilter(true);
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
    setSelectedInsuranceType((pre) => [reSetData]);
  };

  const deleteInsuranceType = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/InsuranceType/${_id}`);

      if (success) {
        getDataByFilter();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    }
  };

 
  useEffect(() => {
    callInsuranceData([]);
    // getInsuranceCompany();
  }, []);

  useEffect(() => {
    getDataByFilter();
  }, [pagination, rowsPerPage, inputData, SelectedInsuranceType]);

  //new code


  // const getExcelData = async () => {
  //   try {
  //     let rows = [];

  //     // Fetch data
  //     const {
  //       data: { success, data },
  //     } = await axiosInstance.get(
  //       `/InsuranceType/getAllInsuranceTypes?&inputData=${inputData}`
  //     );

  //     if (success && data.length > 0) {
  //       const responseData = data;
  //       responseData.forEach((item) => {
  //         const parentInsuranceCategory = item.InsuranceCategory;
  //         const parentInsuranceType = item.InsuranceType;
  //         if (item.RelatedInsurance && item.RelatedInsurance.length > 0) {
  //           item.RelatedInsurance.forEach((related) => {
  //             if (related.subCategory && related.subCategory.length > 0) {
  //               related.subCategory.forEach((sub) => {
  //                 rows.push({
  //                   GST: related.GST,
  //                   GVW: item.GVW,
  //                   InsuranceCategory: parentInsuranceCategory,
  //                   InsuranceType: parentInsuranceType,
  //                   InsuranceUnder: related._doc.InsuranceType,
  //                   cubicCapacity: item.cubicCapacity || "N/A",
  //                   seatingCapacity: item.seatingCapacity || "N/A",
  //                   vehicleClassName: sub._doc.InsuranceType || " ",
  //                 });
  //               });
  //             } else {
  //               rows.push({
  //                 GST: related.GST,
  //                 GVW: item.GVW,
  //                 InsuranceCategory: parentInsuranceCategory,
  //                 InsuranceType: parentInsuranceType,
  //                 InsuranceUnder: related._doc.InsuranceType,
  //                 cubicCapacity: item.cubicCapacity || "N/A",
  //                 seatingCapacity: item.seatingCapacity || "N/A",
  //                 vehicleClassName: " ",
  //               });
  //             }
  //           });
  //         }

  //         rows.push({
  //           GST: item.GST,
  //           GVW: item.GVW,
  //           InsuranceCategory: parentInsuranceCategory,
  //           InsuranceType: parentInsuranceType,
  //           InsuranceUnder: "",
  //           cubicCapacity: item.cubicCapacity || "N/A",
  //           seatingCapacity: item.seatingCapacity || "N/A",
  //           vehicleClassName: "",
  //         });
  //       });
  //     }

  //     const columns = [
  //       { header: "GST", key: "GST", width: 10 },
  //       { header: "GVW", key: "GVW", width: 10 },
  //       { header: "Insurance Category", key: "InsuranceCategory", width: 20 },
  //       { header: "Insurance Type", key: "InsuranceType", width: 20 },
  //       { header: "Insurance Under", key: "InsuranceUnder", width: 20 },
  //       { header: "Cubic Capacity", key: "cubicCapacity", width: 15 },
  //       { header: "Seating Capacity", key: "seatingCapacity", width: 15 },
  //       { header: "Vehicle Class Name", key: "vehicleClassName", width: 30 },
  //     ];

  //     return { columns, rows, fileName: "InsuranceType List" };
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  
  const getExcelData = async () => {
    try {
      let rows = [];
  
      const { data } = await axiosInstance.get(
        `/InsuranceType/getAllInsuranceTypes?&inputData=${inputData}`
      );
  
      if (data && data.success && data.data.length > 0) {
        const responseData = data.data;

  responseData.forEach((item) => {
  const parentInsuranceCategory = item.InsuranceCategory || "";
  const parentInsuranceType = item.InsuranceType || "";

  if (item.RelatedInsurance && item.RelatedInsurance.length > 0) {
    item.RelatedInsurance.forEach((related) => {
      const insuranceUnder = related.InsuranceType || ""; 

      if (related.subCategory && related.subCategory.length > 0) {
        related.subCategory.forEach((sub) => {
          rows.push({
            GST: related.GST || "",
            GVW: item.GVW || "",
            InsuranceCategory: parentInsuranceCategory,
            InsuranceType: parentInsuranceType,
            InsuranceUnder: insuranceUnder, 
            cubicCapacity: item.cubicCapacity || "",
            seatingCapacity: item.seatingCapacity || "",
            vehicleClassName: sub._doc?.InsuranceType || "",
          });
        });
      } else {

        rows.push({
          GST: related.GST || "",
          GVW: item.GVW || "",
          InsuranceCategory: parentInsuranceCategory,
          InsuranceType: parentInsuranceType,
          InsuranceUnder: insuranceUnder, 
          cubicCapacity: item.cubicCapacity || "",
          seatingCapacity: item.seatingCapacity || "",
          vehicleClassName: "",
        });
      }
    });
  }

  rows.push({
    GST: item.GST || "",
    GVW: item.GVW || "",
    InsuranceCategory: parentInsuranceCategory,
    InsuranceType: parentInsuranceType,
    InsuranceUnder: "", 
    cubicCapacity: item.cubicCapacity || "",
    seatingCapacity: item.seatingCapacity || "",
    vehicleClassName: "", 
  });
});

  
      } else {
        console.warn("No data found or success flag is false");
      }
  
      // Ensure rows is not empty and always return something
      if (rows.length === 0) {
        rows.push({
          GST: "", // Empty fields to ensure Excel is generated
          GVW: "",
          InsuranceCategory: "",
          InsuranceType: "",
          InsuranceUnder: "",
          cubicCapacity: "",
          seatingCapacity: "",
          vehicleClassName: "",
        });
      }
  
      // Define columns structure
      const columns = [
        { header: "Insurance Category (LOB)", key: "InsuranceCategory", width: 30 },
        { header: "Product", key: "InsuranceType", width: 20 },
        { header: "Sub Product", key: "InsuranceUnder", width: 20 },
        { header: "Vehicle Class", key: "vehicleClassName", width: 30 },
        { header: "GST", key: "GST", width: 10 },
        { header: "GVW", key: "GVW", width: 10 },
        { header: "Cubic Capacity", key: "cubicCapacity", width: 15 },
        { header: "Seating Capacity", key: "seatingCapacity", width: 15 },
      ];
  
      // Return data to be used for Excel generation
      return { columns, rows, fileName: "InsuranceType List" };
  
    } catch (error) {
      console.error("Error:", error);
  
      // Return a default structure to prevent breaking the download flow
      return { columns: [], rows: [], fileName: "" };
    }
  };
  

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, Insurance Type"
      />
      <div className=" MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10" style={{ float: "left" }}>
                      <div className="ListDesigncol">
                        <h4 className="card-title">Product</h4>
                        <p className="card-title-desc">
                        Product is the type of insurance that you add.
                        </p>
                      </div>
                    </div>
                    <div className="col-2" style={{ float: "left" }}>
                      <Link
                        className="btn mybtn"
                        to="/admin/masters/InsuranceType"
                        state={{
                          Mode: false,
                          value: "",
                        }}
                      >
                        Add Product
                      </Link>
                      {/* <a
                      href="RTOCreate.html"
                      className="btn btn-primary inner mt-2 mt-sm-0"
                    >
                      Create
                    </a> */}
                    </div>
                  </div>

                  {/* <div
                  
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "unset",
                      flexDirection: "row",
                      gap: "10px",
                      zoom: "75%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <div className="">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="InsuranceCompany"
                          value={InsuranceCompany}
                          onChange={(e) => setInsuranceCompany(e.target.value)}
                          required
                        >
                          <option value="">Insurance Company</option>
                          {InsuranceCompanyOpt &&
                            InsuranceCompanyOpt.map((data) => (
                              <option value={data._id}>{data.Name}</option>
                            ))}
                         
                        </select>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div
                        className="searchBox"
                        style={{
                          width: "77px",
                          cursor: "pointer",
                        }}
                        onClick={() => getDataByFilter()}
                      >
                        <MdSend />
                      </div>
                      <div
                        className="searchBox"
                        style={{
                          width: "77px",
                          cursor: "pointer",
                        }}
                        onClick={() => EraserHandler()}
                      >
                        <BsEraser />
                      </div>
                    </div>
                  </div> */}
                  <div
                    // className=" p-2 container filters"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "unset",
                      flexDirection: "row",
                      gap: "10px",
                      zoom: "75%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {SelectedInsuranceType &&
                        SelectedInsuranceType?.length > 0 &&
                        SelectedInsuranceType.map(
                          (data, index) =>
                            data &&
                            data?.data?.length > 0 && (
                              <MultiInsurance
                                key={data.value}
                                Options={data}
                                setSelectedInsuranceType={
                                  setSelectedInsuranceType
                                }
                                SelectedInsuranceType={SelectedInsuranceType}
                                index={index}
                                OptionsValue={data.value}
                                IsFilter={true}
                              />
                            )
                        )}
                      <div className="col">
                        <ExcelExport getData={getExcelData} />
                        {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn mybtn"
                    table="table-to-xls"
                    filename="RTOGroup"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  /> */}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div
                      className=" p-2 container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                      }}
                    >
                      <TableSkeleton />
                    </div>
                  ) : (
                    <>
                      {/* <TableTransition
                        AllKey={AllKey}
                        data={data}
                        editUrl={"/admin/InsuranceType"}
                        Mode={true}
                        deleteFunction={deleteInsuranceType}
                        isInsuranceType={true}
                        EditId={true}
                      /> */}
                      {data && (
                        <div
                          // className=" p-2 container"
                          style={{
                            zoom: "65%",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr>
                                <th scope="col">#</th>

                                <th scope="col">Under</th>
                                <th scope="col">Insurance Type Name</th>
                                <th scope="col">Insurance Type</th>
                                <th scope="col">Insurance Company</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data &&
                                data?.length !== 0 &&
                                data.map((data, index) => (
                                  <tr key={data?._id}>
                                    <th scope="row">{index + 1}</th>

                                    <td>
                                      {data?.Root
                                        ? "Root"
                                        : `Root, ${data?.InsuranceUnderFlow?.map(
                                            (ele) => `${ele.InsuranceType}`
                                          )}`}
                                    </td>

                                    <td
                                      className=""
                                      // onClick={() => {callInsuranceData(data.InsuranceUnderFlow.map(ele =>ele._id)}}
                                    >
                                      {data?.InsuranceType}
                                    </td>
                                    <td>{data?.InsuranceCategory}</td>
                                    <td>{data?.InsuranceCompany?.Name}</td>
                                    <th scope="row">
                                      {" "}
                                      <div className="col-md-2">
                                        <div className="d-flex gap-3">
                                          <div>
                                            <Link
                                              className=""
                                              state={{
                                                value: data,
                                                view: true,
                                              }}
                                              to={`/admin/masters/InsuranceType/${data._id}`}
                                            >
                                              <FaEye
                                                size={35}
                                                style={{
                                                  backgroundColor: "#E8960F",
                                                  color: "#fff",
                                                  padding: "7px",
                                                  borderRadius: "5px",
                                                }}
                                              />{" "}
                                            </Link>
                                          </div>
                                          <div className="">
                                            <Link
                                              className=""
                                              state={{
                                                value: data,
                                                Mode: true,
                                              }}
                                              to={`/admin/masters/InsuranceType/${data._id}`}
                                            >
                                              <BiEdit
                                                size={35}
                                                style={{
                                                  backgroundColor: "green",
                                                  color: "#fff",
                                                  padding: "7px",
                                                  borderRadius: "5px",
                                                }}
                                              />{" "}
                                            </Link>
                                          </div>
                                          {!data?.Root && (
                                            <div className="">
                                              <AiFillDelete
                                                size={35}
                                                style={{
                                                  backgroundColor: "#A50406",
                                                  color: "#fff",
                                                  padding: "7px",
                                                  borderRadius: "5px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  const isTrue = window.confirm(
                                                    "Do you want to delete!"
                                                  );
                                                  if (isTrue) {
                                                    deleteInsuranceType(
                                                      data?._id
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {
                        <div
                          style={{
                            backgroundColor: "#1a2e45d7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            marginTop: "5px",
                          }}
                        >
                          <TablePagination
                            component="div"
                            className="text-white"
                            rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                            count={totalDocs ?? 0}
                            page={pagination}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>

              {/* end col */}
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default InsuranceType;
