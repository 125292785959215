import { axiosInstance } from "../../config";

export const logoutAction = () => async (dispatch) => {
  try {
    dispatch({
      type: "logoutRequest",
    });
    await axiosInstance.get("/api/v1/logout");

    dispatch({
      type: "logoutSuccess",
    });
  } catch (error) {
    dispatch({
      type: "logoutFailure",
      payload: error.message,
    });
  }
};
export const getMe = () => async (dispatch) => {
  try {
    dispatch({
      type: "LodeUserRequest",
    });
    const {
      data: { user, success },
    } = await axiosInstance.get("/Employee/singleEmployee?Permission&Email");

    if (success) {
      dispatch({
        type: "LodeUserSuccess",
        payload: user,
      });
    }
  } catch (error) {
    dispatch({
      type: "LodeUserFailure",
      payload: error.message,
    });
  }
};
