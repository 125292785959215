import React, { useEffect, useState } from "react";
// import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link, useNavigate, useParams } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { reversDate } from "../../Utils/usefullFunction";

// const convertInsuranceToId = (arr) => {
//   if (arr.length === 0) return [];
//   const data = arr.map((ele) => ele.value).filter((ele) => ele !== "");
//   return JSON.stringify(data);
// };

const ViewPolicy = () => {
  const { PolicyId } = useParams();

  const [Policy, setPolicy] = useState({});
  const [ViewDocuments, setViewDocuments] = useState(false);
  const [IsNonMotorPolicy, setIsNonMotorPolicy] = useState(false);
  const [PolicyMetaData, setPolicyMetaData] = useState([
    {
      viewName: "Policy Copy",
      url: "",
      Name: "NewPolicyCopy",
    },
    {
      viewName: "First RC",
      url: "",
      Name: "RC1",
    },
    {
      viewName: "Second RC",
      url: "",
      Name: "RC2",
    },
    {
      viewName: "First Previous Policy",
      url: "",
      Name: "PreviousPolicy1",
    },
    {
      viewName: "Second Previous Policy",
      url: "",
      Name: "PreviousPolicy2",
    },
  ]);
  const navigate = useNavigate();

  const getSingle = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/viewPolicy/${PolicyId}`);

      setPolicy({
        ...data,
      });
      setIsNonMotorPolicy(data?.IsNonMotorPolicy ?? false);
      setPolicyMetaData([
        {
          viewName: "Policy Copy",
          url: data?.NewPolicyCopy ?? "",
          Name: "NewPolicyCopy",
        },
        {
          viewName: "First RC",
          url: data?.RC1 ?? "",
          Name: "RC1",
        },
        {
          viewName: "Second RC",
          url: data?.RC2 ?? "",
          Name: "RC2",
        },
        {
          viewName: "First Previous Policy",
          url: data?.PreviousPolicy1 ?? "",
          Name: "PreviousPolicy1",
        },
        {
          viewName: "Second Previous Policy",
          url: data?.PreviousPolicy2 ?? "",
          Name: "PreviousPolicy2",
        },
      ]);
    } catch (error) {}
  };
  useEffect(() => {
    getSingle();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row">
          {ViewDocuments ? (
            <ViewDocumentsFile PolicyMetaData={PolicyMetaData} />
          ) : (
            <PolicyDetails
              Policy={Policy}
              IsNonMotorPolicy={IsNonMotorPolicy}
            />
          )}
          <div
            className="d-flex justify-content-center gap-3 mb-2"
            style={{ marginTop: "20px" }}
          >
            <button
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/Policy/data")}
            >
              Back
            </button>
            <Link
              className="btn mybtn"
              state={{
                value: {
                  ...Policy,
                  Broker: Policy?.Broker?._id,
                  InsuranceCompany: Policy?.InsuranceCompany?._id,
                  InsuranceType: Policy?.InsuranceType?._id,
                  MakeModal: Policy?.MakeModal?._id,
                  POS: Policy?.POS?._id,
                  PolicyType: Policy?.PolicyType?._id,
                },
                Mode: true,
              }}
              to={`/admin/Policy/${Policy?._id}`}
            >
              Edit
            </Link>

            <button
              type="submit"
              className="btn mybtn"
              onClick={() => {
                setViewDocuments(!ViewDocuments);
              }}
            >
              {ViewDocuments ? "View Details" : "View Documents"}
            </button>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};
const ViewDocumentsFile = ({ PolicyMetaData }) => {
  const [count, setCount] = useState(0);
  return (
    <div
      className="col-md-12 align-items-center mb-3 "
      style={{
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 0rem",
          width: "100%",
        }}
      >
        <button
          className="btn"
          style={{
            backgroundColor: "#E8960F",
            borderRadius: "7px",
            color: "white",
          }}
          onClick={() => {
            setCount((p) => {
              if (p === 0) return 4;
              return p - 1;
            });
          }}
        >
          Previous
        </button>

        <h5>{PolicyMetaData[count]?.viewName ?? ""}</h5>

        <button
          className="btn"
          style={{
            backgroundColor: "green",
            borderRadius: "7px",
            color: "white",
          }}
          onClick={() => {
            setCount((p) => {
              if (p === 4) return 0;
              return p + 1;
            });
          }}
        >
          Next
        </button>
      </div>
      {PolicyMetaData[count]?.url ? (
        <embed
          src={`${REACT_APP_FileURL}${PolicyMetaData[count].url}`}
          type="application/pdf"
          width="100%"
          height="90%"
        />
      ) : (
        <>
          <h5> Not Uploaded</h5>
        </>
      )}
    </div>
  );
};
const PolicyDetails = ({ Policy, IsNonMotorPolicy }) => {
  return (
    <section
      className="push"
      style={{
        position: "relative",
        transition: " margin-left .5s",
        zoom: "90%",
        marginTop: "20px",
      }}
    >
      <div className="col-lg-8  p-4" style={{ float: "left" }}>
        <div className="">
          <div className="row mx-2 ">
            <h5>View Policy</h5>
            <hr className="my-2" />
            <div className="row">
              {/* {
                <div className="col-md-6 align-items-center">
                  <label style={{ color: "#B8B8B8" }}>Policy Type</label>
                  <h6>{Policy?.PolicyType?.PolicyTypeName}</h6>
                </div>
              } */}
              {
                <div className="col-md-6 align-items-center">
                  <label style={{ color: "#B8B8B8" }}>Insurance Company</label>
                  <h6>{Policy?.InsuranceCompany?.Name}</h6>
                </div>
              }
              {Policy?.InsuranceUnderFlow &&
                Policy?.InsuranceUnderFlow?.length > 0 &&
                Policy?.InsuranceUnderFlow.map((ele, index) => (
                  <div
                    className="col-md-6 align-items-center"
                    style={{ marginTop: "15px" }}
                    key={ele?._id}
                  >
                    <label style={{ color: "#B8B8B8" }}>
                      {index === 0 ? "" : "Sub"} Product
                    </label>
                    <h6>{ele?.InsuranceType}</h6>
                  </div>
                ))}

              {/* {
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Business Type</label>
                  <h6>{Policy?.BusinessType}</h6>
                </div>
              } */}
              {/* <div
                className="col-md-6 align-items-center"
                style={{ marginTop: "15px" }}
              >
                <label style={{ color: "#B8B8B8" }}>PolicyNumber</label>
                <h6>{Policy?.PolicyNumber}</h6>
              </div> */}
              <div
                className="col-md-6 align-items-center"
                style={{ marginTop: "15px" }}
              >
                <label style={{ color: "#B8B8B8" }}>Insured Name</label>
                <h6>{Policy?.InsureeName}</h6>
              </div>
              <div
                className="col-md-6 align-items-center"
                style={{ marginTop: "15px" }}
              >
                <label style={{ color: "#B8B8B8" }}>RSD</label>
                <h6>{reversDate(Policy?.RSD)}</h6>
              </div>
              <div
                className="col-md-6 align-items-center"
                style={{ marginTop: "15px" }}
              >
                <label style={{ color: "#B8B8B8" }}>RED</label>
                <h6>{reversDate(Policy?.RED)}</h6>
              </div>

              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Vehicle Number</label>
                  <h6>{Policy?.VehicleNumber}</h6>
                </div>
              )}
              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Fuel Type</label>
                  <h6>{Policy?.FuelType?.FuelType}</h6>
                </div>
              )}
              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>CC/Watt</label>
                  <h6>{Policy?.Engine}</h6>
                </div>
              )}
              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Seating Capacity</label>
                  <h6>{Policy?.Seater}</h6>
                </div>
              )}
              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>GVW</label>
                  <h6>{Policy?.GVW}</h6>
                </div>
              )}

              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Make</label>
                  <h6>{Policy?.MakeModal?.Make}</h6>
                </div>
              )}

              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Model</label>
                  <h6>{Policy?.Model?.Model}</h6>
                </div>
              )}

              {
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>POS</label>
                  <h6>{`${Policy?.POS?.Salutation ?? ""} ${
                    Policy?.POS?.MiddleName ?? ""
                  } ${Policy?.POS?.Name ?? ""} ${
                    Policy?.POS?.LastName ?? ""
                  }`}</h6>
                </div>
              }

              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Variant</label>
                  <h6>{Policy?.Variant?.VariantName}</h6>
                </div>
              )}
              {/* {
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Broker</label>
                  <h6>{Policy?.Broker?.Name}</h6>
                </div>
              } */}
              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>IMT Type</label>
                  <h6>{Policy?.IMTType}</h6>
                </div>
              )}

              {
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>NCB</label>
                  <h6>
                    {Policy?.NCB === 0
                      ? "No"
                      : Policy?.NCB < 0
                      ? "Not Applicable"
                      : "Yes"}
                  </h6>
                </div>
              }

              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>
                    Year of Manufacture
                  </label>
                  <h6>{Policy?.YearOfManufacture}</h6>
                </div>
              )}
              {!IsNonMotorPolicy && (
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Registration Date</label>
                  <h6>{reversDate(Policy?.RegistrationDate)}</h6>
                </div>
              )}

              <div
                className="col-md-6 align-items-center"
                style={{ marginTop: "15px" }}
              >
                <label style={{ color: "#B8B8B8" }}>Issue Date</label>
                <h6>{reversDate(Policy?.IssueDate)}</h6>
              </div>

              <div
                className="col-md-6 align-items-center"
                style={{ marginTop: "15px" }}
              >
                <label style={{ color: "#B8B8B8" }}>Remark</label>
                <h6>{Policy?.Remark}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-lg-4 CreateCard p-4"
        style={{ float: "left", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
      >
        <div className="">
          <div className="row mx-2 ">
            <h5>Policy Summary</h5>
            <hr className="my-2" />
            <div className="row">
              {
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <img
                      src={`${REACT_APP_FileURL}${Policy?.InsuranceCompany?.Logo}`}
                      style={{
                        height: "50px",
                        // width: "100px",
                        objectFit: "cover",
                      }}
                      // className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              }
              {
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label style={{ color: "#B8B8B8", float: "left" }}>
                      Business Type
                    </label>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ textAlign: "right", float: "left" }}
                  >
                    <h6 style={{ float: "left" }}>{Policy?.BusinessType}</h6>
                  </div>
                </div>
              }
              {
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label style={{ color: "#B8B8B8", float: "left" }}>
                      Policy Type
                    </label>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ textAlign: "right", float: "left" }}
                  >
                    <h6 style={{ float: "left" }}>
                      {" "}
                      {Policy?.PolicyType?.PolicyTypeName}
                    </h6>
                  </div>
                </div>
              }

              {
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label style={{ color: "#B8B8B8", float: "left" }}>
                      Policy Number
                    </label>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ textAlign: "right", float: "left" }}
                  >
                    <h6 style={{ float: "left" }}>{Policy?.PolicyNumber}</h6>
                  </div>
                </div>
              }
              {
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label style={{ color: "#B8B8B8", float: "left" }}>
                      Add Ons
                    </label>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ textAlign: "right", float: "left" }}
                  >
                    <h6 style={{ float: "left" }}> N/A</h6>
                  </div>
                </div>
              }
              {
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label style={{ color: "#B8B8B8", float: "left" }}>
                      Request Id
                    </label>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ textAlign: "right", float: "left" }}
                  >
                    <h6 style={{ float: "left" }}>9590654</h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 p-4" style={{ float: "left" }}>
        <div className="row mx-4 ">
          <h5>Premium Details</h5>
          {/* Pos Information */}
          <hr className="mt-1 " />
          <div>
            <div className="row">
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-md-6 align-items-center">
                  <label style={{ color: "#B8B8B8" }}>OD Premium</label>
                  <h6>{Policy?.ODPremium}</h6>
                </div>
                <div className="col-md-6 align-items-center">
                  <label style={{ color: "#B8B8B8" }}>GST OD Premium </label>
                  <h6>{Policy?.ODPremiumGST}</h6>
                </div>
                {IsNonMotorPolicy && (
                  <>
                    <div className="col-md-6 align-items-center">
                      <label style={{ color: "#B8B8B8" }}>
                        {" "}
                        Terrorism Premium
                      </label>
                      <h6>{Policy?.TerrorismPremium}</h6>
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label style={{ color: "#B8B8B8" }}>
                        Terrorism GST Premium{" "}
                      </label>
                      <h6>{Policy?.TerrorismPremiumGST}</h6>
                    </div>
                  </>
                )}
                {!IsNonMotorPolicy && (
                  <>
                    <div className="col-md-6 align-items-center">
                      <label style={{ color: "#B8B8B8" }}>TP Premium</label>
                      <h6>{Policy?.TPPremium}</h6>
                    </div>
                    <div className="col-md-6 align-items-center">
                      <label style={{ color: "#B8B8B8" }}>GST TP Premium</label>
                      <h6>{Policy?.TPPremiumGST}</h6>
                    </div>
                    <div
                      className="col-md-6 align-items-center"
                      style={{ marginTop: "15px" }}
                    >
                      <label style={{ color: "#B8B8B8" }}>PA Premium</label>
                      <h6>{Policy?.PAPremium}</h6>
                    </div>
                    <div
                      className="col-md-6 align-items-center"
                      style={{ marginTop: "15px" }}
                    >
                      <label style={{ color: "#B8B8B8" }}>GST PA Premium</label>
                      <h6>{Policy?.PAPremiumGST}</h6>
                    </div>
                    <div
                      className="col-md-6 align-items-center"
                      style={{ marginTop: "15px" }}
                    >
                      <label style={{ color: "#B8B8B8" }}>LL Premium</label>
                      <h6>{Policy?.LLPremium}</h6>
                    </div>
                    <div
                      className="col-md-6 align-items-center"
                      style={{ marginTop: "15px" }}
                    >
                      <label style={{ color: "#B8B8B8" }}>GST LL Premium</label>
                      <h6>{Policy?.LLPremiumGST}</h6>
                    </div>
                  </>
                )}
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Total GST Amount</label>
                  <h6>
                    {+Policy?.ODPremiumGST +
                      +Policy?.TPPremiumGST +
                      +Policy?.TerrorismPremiumGST +
                      +Policy?.PAPremiumGST +
                      +Policy?.LLPremiumGST}
                  </h6>
                </div>
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>NET Premium</label>
                  <h6>{Policy?.NETPremium}</h6>
                </div>
                <div
                  className="col-md-6 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label style={{ color: "#B8B8B8" }}>Gross Premium</label>
                  <h6>{Policy?.GrossPremium}</h6>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewPolicy;
