import React, { useEffect, useState } from "react";

import { axiosInstance } from "../../config";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { RxReset } from "react-icons/rx";
import { BiSearchAlt } from "react-icons/bi";
const CommissionTableForPos = () => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [inputData, setInput] = useState();
  const [loading, setLoading] = useState(false);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [Pos, setPos] = useState();
  const [Data, setData] = useState([]);
  const [POSOpt, setPOSOpt] = useState([]);
  const [TotalAmount, setTotalAmount] = useState({});

  const EraserHandler = async () => {
    setPos("");
    setStartDate(firstDay);
    setEndDate(lastDay);
    getApprovedPolicy(true);
  };
  const getPos = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        showFiled: {
          Name: 1,
          _id: 1,
        },
      });
      setPOSOpt(data);
    } catch (error) {}
  };

  const getApprovedPolicy = async (EraserHandler) => {
    let que = {
      startDate: StartDate,
      endDate: EndDate,
    };

    if (Pos) {
      que["POS"] = Pos;
    }
    if (inputData) {
      que["inputData"] = inputData;
    }
    if (EraserHandler) {
      que = {
        startDate: firstDay,
        endDate: lastDay,
      };
    }
    try {
      const {
        data: { data = [], TotalAmount = {} },
      } = await axiosInstance.post("/Policy/getApprovedPolicy", {
        ...que,
      });

      setTotalAmount(TotalAmount);
      setData(data);
    } catch (error) {}
  };
  useEffect(() => {
    getApprovedPolicy();
    getPos();
  }, []);

  useEffect(() => {
    let time = setTimeout(getApprovedPolicy, 1000);

    return () => {
      clearTimeout(time);
    };
  }, [inputData]);

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "60px" }}>
        <div className="row CreateCard p-4">
          <div
            className=" p-2 container filters"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "unset",
              flexDirection: "row",
              gap: "10px", zoom: "80%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <div className="d-flex gap-3">
                <SearchInputFilter
                  setInput={setInput}
                  inputData={inputData}
                  PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                />
                <div className="col-lg-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="PolicyType"
                    value={Pos}
                    onChange={(e) => {
                      setPos(e.target.value);
                    }}
                    required
                  >
                    <option value="">POS</option>
                    {POSOpt &&
                      POSOpt.map((data) => (
                        <option value={data._id}>{data?.Name}</option>
                      ))}
                    {/* <option value="self">self</option> */}
                  </select>
                </div>
                <div
                  className="col-lg-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <input
                    className="form-control "
                    type="date"
                    name="startDate"
                    value={StartDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    // placeholder="Enter  PolicyNumber"
                    required //done
                  />

                  <p>-</p>
                  <input
                    className="form-control "
                    type="date"
                    name="endDate"
                    value={EndDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    // placeholder="Enter  PolicyNumber"
                    required //done
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <BiSearchAlt
                  size={32}
                  onClick={() => getApprovedPolicy()}
                  style={{
                    backgroundColor: "#174379",
                    color: "#fff",
                    padding: "5px",
                    borderRadius: "5px", marginTop:'-15px',
                  }}
                />{" "}
                &nbsp;
                <Link>
                  <RxReset
                    onClick={() => EraserHandler()}
                    size={32}
                    style={{
                      backgroundColor: "#E8960F",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "5px", marginTop:'-15px',
                    }}
                  />
                </Link>
                &nbsp;
                &nbsp;
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn mybtn"
                  table="table-to-xls"
                  filename={`Billing-oF${StartDate}-${EndDate}`}
                  sheet="tablexls"
                  buttonText="Download as XLS"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <div
              className=" p-2 container"
              style={{
                zoom: "80%",
              }}
            >
              {Data && (
                <table
                  class="table table-striped   table-bordered  overflow-x mt-3"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        #
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Insuree Name
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Policy No.
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Vehicle No.
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        POS
                      </th>
                      {/* <th scope="col">Total Profit</th> */}
                      <th style={{ textAlign: "center" }} scope="col">
                        Amount To Pay
                      </th>
                      {/* <th scope="col">Profit After Commission</th> */}

                      {/* <th scope="col">Approve</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {Data &&
                      Data?.length !== 0 &&
                      Data.map((data, index) => (
                        <tr>
                          <td scope="row">{index + 1}</td>
                          <td scope="row">{data?.InsureeName}</td>
                          <td scope="row">{data?.PolicyNumber}</td>
                          <td scope="row">{data?.VehicleNumber}</td>
                          <td scope="row">{data?.POS?.Name}</td>
                          {/* <th scope="row">
                              ₹{" "}
                              {Math.round(data?.Commission?.TotalPolicyProfit)}
                            </th> */}
                          <td scope="row" style={{ textAlign: "right" }}>
                            ₹ {Math.round(data?.Commission?.TotalAmountToPay)}
                          </td>
                          {/* <th scope="row">
                              ₹ {Math.round(data?.Commission?.PolicyProfit)}
                            </th> */}
                        </tr>
                      ))}
                    <tr>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      {/* <th scope="row">₹ {Math.round(TotalAmount?.TotalProfit) ?? 0}</th> */}
                      <th scope="row" style={{ textAlign: "right" }}>
                        ₹{" "}
                        {isNaN(Math.round(TotalAmount?.AmountToPay))
                          ? 0
                          : Math.round(TotalAmount?.AmountToPay)}
                      </th>
                      {/* <th scope="row">₹ {Math.round(TotalAmount?.Profit) ?? 0}</th> */}
                    </tr>
                  </tbody>
                </table>
              )}
              {
                <div
                  style={{
                    backgroundColor: "#1a2e45d7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    marginTop: "5px",
                  }}
                >
                  {/* <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                      count={totalDocs ?? 0}
                      page={pagination}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CommissionTableForPos;
