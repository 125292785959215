import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { BiSearchAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { convertDateStringToDate, coverAmountIntoCurrent } from "../../Utils/usefullFunction";
import DateRange from "../DateRangeModule/DateRange";

const ERPReportReportingManager = ({ ReportOf = "ReportingManger" }) => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [loading, setLoading] = useState(false);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [data, setData] = useState();
  const [PolicyCount, setPolicyCount] = useState();
  const setDateHandler = (ranges) => {

    setEndDate(convertDateStringToDate(ranges.endDate))
    setStartDate(convertDateStringToDate(ranges.startDate))

  }
  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { data, PolicyCount },
      } = await axiosInstance.get(`/Policy/CompanyReportOfReportingManager?startDate=${StartDate}&endDate=${EndDate}`);

      setData(data);
      setPolicyCount(PolicyCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [ReportOf]);

  return (
    <>
      {/* <Header /> */}

      <div className="row">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div
              className="col-lg-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <input
                className="form-control "
                type="date"
                name="startDate"
                value={StartDate}
                onChange={(e) => setStartDate(e.target.value)}
                // placeholder="Enter  PolicyNumber"
                required //done
              />

              <p>-</p>
              <input
                className="form-control "
                type="date"
                name="endDate"
                value={EndDate}
                onChange={(e) => setEndDate(e.target.value)}
                // placeholder="Enter  PolicyNumber"
                required //done
              />
              <DateRange setDateHandler={setDateHandler} />
            </div>
            <div className="">
              <BiSearchAlt
                size={32}
                onClick={() => getData()}
                style={{
                  backgroundColor: "#174379",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  marginTop: "2px",
                }}
              />
              &nbsp;
              <Link>
                <RxReset
                  onClick={() => {
                    setStartDate(firstDay);
                    setEndDate(lastDay);
                  }}
                  size={32}
                  style={{
                    backgroundColor: "#E8960F",
                    color: "#fff",
                    padding: "5px",
                    borderRadius: "5px",
                    marginTop: "2px",
                  }}
                />
              </Link>
            </div>
          </div>
          
          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <>
              <div
                class="table-responsive"
                style={{
                  zoom: "85%",
                }}
              >
                <table class="table table-striped table-bordered mt-3">
                  <thead>
                    <tr>
                      <th scope="col" style={{ textAlign: "center" }}>
                        S/No.
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        Name
                      </th>
                      <th scope="row">Count</th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        GrossPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        NETPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        ODPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        TPPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        Commisionable Premium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        TotalPolicyProfit
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        TotalAmountToPay
                      </th>

                      <th scope="row" style={{ textAlign: "center" }}>
                        Profit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((ele, index) => {
                        const {
                          Name = "",
                          Count = 0,
                          GrossPremium,
                          NETPremium,
                          ODPremium,
                          TPPremium,
                          PolicyProfit,
                          TotalAmountToPay,
                          TotalPolicyProfit,
                          CommisionablePremium,
                        } = ele;
                        return (
                          <tr key={ele._id}>
                            <td> {index + 1}</td>
                            <td>{Name}</td>
                            <td>{Count}</td>
                            <td>{coverAmountIntoCurrent(GrossPremium)}</td>
                            <td>{coverAmountIntoCurrent(NETPremium)}</td>
                            <td>{coverAmountIntoCurrent(ODPremium)}</td>
                            <td>{coverAmountIntoCurrent(TPPremium)}</td>
                            <td>{coverAmountIntoCurrent(CommisionablePremium)}</td>
                            <td>{coverAmountIntoCurrent(TotalPolicyProfit)}</td>
                            <td>{coverAmountIntoCurrent(TotalAmountToPay)}</td>
                            <td> <b>{coverAmountIntoCurrent(PolicyProfit)}</b></td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td> </td>
                      <th>Total:</th>
                      <th>{PolicyCount?.Count}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.GrossPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.NETPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.ODPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.TPPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.CommisionablePremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.TotalPolicyProfit)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.TotalAmountToPay)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.PolicyProfit)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        {/* end col */}
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default ERPReportReportingManager;
