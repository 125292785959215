import { createReducer } from "@reduxjs/toolkit";

const initialClubEceptionState = {

    SelectedInsuranceType: [],
   
  };
  
  const My_ClubException_Details = {
    SavedData: [],
   
  };
  export const setSelectedInsuranceType = (payload) => ({
    type: "SET_SELECTED_INSURANCE_TYPE",
    payload,
  });
  
  export const ClubExceptionFilters = createReducer(
    { ...initialClubEceptionState, ...My_ClubException_Details },
    {
      updateClubExceptionFilters: (state, action = {}) => {
        const { key, value } = action;
        state[key] = value;
      },
      setClubExceptionFilterDefaultValue: (state, action = {}) => {
        const data = Object.entries(initialClubEceptionState);
        data.forEach(([key, value]) => {
          state[key] = value;
        });
      },
    }
  );