import React, { useEffect, useState } from "react";

import { axiosInstance } from "../../config";

import TableSkeleton from "../../Skeleton/TableSkeleton";
import { useLocation } from "react-router-dom";
import { coverAmountIntoCurrent } from "../../Utils/usefullFunction";
import { FaFastBackward } from "react-icons/fa";
const CommissionTable = () => {

  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const StartDate = queryParams.get("StartDate");
  const EndDate = queryParams.get("EndDate");
  const For = queryParams.get("For");
  const [Data, setData] = useState([]);

  const [TotalAmount, setTotalAmount] = useState({});



  const getApprovedPolicy = async () => {
    let que = {
      startDate: StartDate,
      endDate: EndDate,
    };
    if (!_id) {
      return;
    }
    if (!For) {
      return;
    }

    setLoading(true)
    if (For === "InsuranceCompany") {
      que["InsuranceCompany"] = _id;
    }
    if (For === "POS") {
      que["POS"] = _id;
    }
    if (For === "Broker") {
      que["Broker"] = _id;
    }


    try {
      const {
        data: { data = [], TotalAmount = {} },
      } = await axiosInstance.post("/Policy/getApprovedPolicy", {
        ...que,
      });


      setTotalAmount(TotalAmount);
      setData(data);
      setLoading(false)
    } catch (error) {

      setLoading(false)
      
    }
  };
  useEffect(() => {
    getApprovedPolicy();
  }, []);
  const goBack = () => {
    window.history.back();
  };

  return (
    <>

      <div className="row">
        <div className="card-body">
          <div style={{
            color: "#4b6e8e",
            fontSize: "larger",
            padding: "5px",
            cursor: "pointer",
            width:"fit-content"
          }}

            onClick={() => goBack()}
          >
            <div>
              <FaFastBackward /> Go Back
            </div>

          </div>

          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <div
              style={{
                zoom: "85%",
              }}
            >
              {Data && (
                <table
                  class="table table-striped   table-bordered  overflow-x mt-3"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th scope="col" style={{ textAlign: "center" }}>
                        #
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Insuree Name
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Policy No.
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Vehicle No.
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Total Profit
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Amount To Pay
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Profit After Commission
                      </th>

                      {/* <th scope="col">Approve</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {Data &&
                      Data?.length !== 0 &&
                      Data.map((data, index) => (
                        <tr>
                          <td scope="row">{index + 1}</td>
                          <td scope="row">{data?.InsureeName}</td>
                          <td scope="row">{data?.PolicyNumber}</td>
                          <td scope="row">{data?.VehicleNumber}</td>
                          <td scope="row" style={{ textAlign: "right" }}>
                            {coverAmountIntoCurrent(data?.Commission?.TotalPolicyProfit)}
                          </td>
                          <td scope="row" style={{ textAlign: "right" }}>
                            {coverAmountIntoCurrent(data?.Commission?.TotalAmountToPay)}
                          </td>
                          <td scope="row" style={{ textAlign: "right" }}>
                            {coverAmountIntoCurrent(data?.Commission?.PolicyProfit)}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row"></th>

                      <th scope="row"></th>
                      <th scope="row" style={{ textAlign: "right" }}>

                        {coverAmountIntoCurrent(TotalAmount?.TotalProfit)}
                      </th>
                      <th scope="row" style={{ textAlign: "right" }}>


                        {coverAmountIntoCurrent(TotalAmount?.AmountToPay)}

                      </th>
                      <th scope="row" style={{ textAlign: "right" }}>

                        {coverAmountIntoCurrent(TotalAmount?.Profit)}

                      </th>
                    </tr>
                  </tbody>
                </table>
              )}
              {
                <div
                  style={{
                    backgroundColor: "#1a2e45d7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    marginTop: "5px",
                  }}
                >
                  {/* <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                      count={totalDocs ?? 0}
                      page={pagination}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </div>
              }
            </div>
          )}
        </div>
      </div>

    </>
  );
};

export default CommissionTable;
