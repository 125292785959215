export const DESIGNATION = new Map();
DESIGNATION.set("Sales", [
  "BDM Manager",
  "Territory Manager",
  "Regional Manager",
  "National HEAD",
]);
DESIGNATION.set("Accounts", [
  "JR Accountant",
  "Asst. Accountant",
  "Head Accountant",
]);
DESIGNATION.set("Hr", ["Junior HR", "Senior HR"]);
DESIGNATION.set("Operation", [
  "Ops Executive",
  "Asst. Ops Manager",
  "Ops Manager",
]);
DESIGNATION.set("TeamLeader", []);
DESIGNATION.set("QC", ["QC Executive", "QC TL", "QC Manager"]);
