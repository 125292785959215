import React, { useState } from "react";
import { ToastError, ToastSusses, ToastInfo, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

const CreateInsuranceSubCategory = () => {
  const { state } = useLocation();
  const { value = "", Mode } = state || {};

  const [InsuranceSubCategoryName, setInsuranceSubCategoryName] = useState(
    value?.InsuranceSubCategory || ""
  );
  const [CategoryName, setCategoryName] = useState(value?.CategoryName || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    if (!CategoryName || !InsuranceSubCategoryName) {
      return ToastWarn("All Field is required");
    }
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/InsuranceSubCategory", {
        InsuranceSubCategory: InsuranceSubCategoryName,
        CategoryName,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/InsuranceSubCategory/data");
      } else {
        ToastSusses(message);
      }

    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const { data: { success, message } } = await axiosInstance.put(
        `/InsuranceSubCategory/${value?._id}`,
        {
          InsuranceSubCategory: InsuranceSubCategoryName,
          CategoryName,
        }
      );

      if (success) {
        ToastSusses(message);
        navigate("/admin/InsuranceSubCategory/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <h4 className="card-title">
                    {Mode
                      ? "Edit Insurance SubCategory"
                      : "Create Insurance SubCategory "}
                  </h4>
                  <hr />
                  <br />
                  <div className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item="" className="outer">
                        <div
                          className="col-md-6 align-items-center"
                          style={{ float: "left", padding: 15 }}
                        >
                          <label>Insurance SubCategory Name</label>
                          <input
                            className="form-control "
                            type="text"
                            name="InsuranceSubCategoryName"
                            value={InsuranceSubCategoryName}
                            onChange={(e) =>
                              setInsuranceSubCategoryName(e.target.value)
                            }
                            placeholder="Enter Insurance SubCategory Name"
                            required //done
                          />
                        </div>
                        <div
                          className="col-md-6 align-items-center"
                          style={{ float: "left", padding: 15 }}
                        >
                          <label>SubCategory</label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setCategoryName(e.target.value)}
                            value={CategoryName}
                            required
                          >
                            <option selected value="">
                              ---------------------Select---------------------
                            </option>
                            <option value="SubCategory1">SubCategory1</option>
                            <option value="SubCategory2">SubCategory2</option>
                            <option value="SubCategory3">SubCategory3</option>
                          </select>
                        </div>
                        <div
                          className="col-4"
                          style={{ float: "left", padding: 15 }}
                        >
                          <button
                            type="submit"
                            className="btn mybtn"
                            style={{ marginTop: 28 }}
                            onClick={() => {
                              Mode ? handleEdit() : handleCreate();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateInsuranceSubCategory;
