import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Select from "react-select";
import { axiosInstance } from "../../config";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const NewDebitNotes = () => {
  const MASTER_DB = ["LedgerGroup", "POS", "Broker"];
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const Mode = Boolean(queryParams.get("Mode"));

  const [EntryDate, setEntryDate] = useState(lastDay);

  const [PartyAccountLedger, setPartyAccountLedger] = useState();
  const [Remark, setRemark] = useState("");
  const [PartyAccount, setPartyAccount] = useState("");
  const [PartyAccountOpt, setPartyAccountOpt] = useState();
  const [PartyAccountOptFor, setPartyAccountOptFor] = useState([]);
  const [Account, setAccount] = useState("");
  const [RefNumber, setRefNumber] = useState("");
  const [FromModal, setFromModal] = useState();
  const [From, setFrom] = useState("");

  const [DebitNotes, setDebitNotes] = useState([]);
  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      if (!PartyAccountLedger._id) {
        return ToastWarn("PartyAccount Ledger Bank Account is Required");
      }
      const que = {};

      if (FromModal === "POS") {
        que["PartyAccount"] = PartyAccountLedger._id;
      } else if (FromModal === "LedgerGroup") {
        que["LedgerEntry"] = PartyAccountLedger._id;
      } else if (FromModal === "Broker") {
        que["Broker"] = PartyAccountLedger._id;
      }
      const {
        data: { success, message },
      } = await axiosInstance.post("/DebitNotes", {
        ...que,
        EntryDate,
        Remark,
        DebitNotes,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/DebitNotes/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      if (!PartyAccountLedger?._id) {
        return ToastWarn("Cash Bank Account is Required");
      }
      const que = {};

      if (FromModal === "POS") {
        que["PartyAccount"] = PartyAccountLedger._id;
      } else if (FromModal === "LedgerGroup") {
        que["LedgerEntry"] = PartyAccountLedger._id;
      } else if (FromModal === "Broker") {
        que["Broker"] = PartyAccountLedger._id;
      }
      const {
        data: { success, message },
      } = await axiosInstance.put(`/DebitNotes/${_id}`, {
        ...que,
        EntryDate,
        Remark,
        DebitNotes,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/DebitNotes/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const removeHandler = (index) => {
    const DebitNotesArr = DebitNotes;
    DebitNotesArr.splice(index, 1);
    setDebitNotes([...DebitNotesArr]);
  };

  const editHandler = (data, index) => {
    if (Account || RefNumber || Account) {
      return ToastWarn("Please Add To List First");
    }
    getPos(data.From);
    if (data.From === "POS") {
      if (data?.PartyAccount) {
        setPartyAccount({ _id: data?.PartyAccount, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    if (data.From === "Broker") {
      if (data?.Broker) {
        setPartyAccount({ _id: data?.Broker, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
    }
    if (data.From === "LedgerGroup") {
      if (data?.LedgerEntry) {
        setPartyAccount({ _id: data?.LedgerEntry, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }

    setAccount(data.Account);
    setRefNumber(data.RefNumber);
    setFrom(data.From);
    const DebitNotesArr = DebitNotes;
    DebitNotesArr.splice(index, 1);
    setDebitNotes([...DebitNotesArr]);
  };
  const addHandler = () => {
    if (!PartyAccountLedger) {
      return ToastWarn("Please Add PartyAccountLedger first");
    }
    if (!PartyAccount || !Account) {
      return ToastWarn("Please enter a valid field");
    }
    const que = {};
    const PartyAccountData = PartyAccount;

    if (From === "POS") {
      que["PartyAccount"] = PartyAccountData._id;
    } else if (From === "LedgerGroup") {
      que["LedgerEntry"] = PartyAccountData._id;
    } else if (From === "Broker") {
      que["Broker"] = PartyAccountData._id;
    }

    setDebitNotes([
      ...DebitNotes,
      {
        ...que,
        Account: Number(Account),
        RefNumber,
        Name: PartyAccountData.Name,
        key: PartyAccountData.key,
        From: From,
      },
    ]);
    setPartyAccount({
      _id: "",
      Name: "-- Select --",
    });
    setAccount("");
    setFrom("");
    setRefNumber("");
  };

  const getPos = async (isData) => {
    try {
      if (isData === "Broker") {
        const {
          data: { data: Broker },
        } = await axiosInstance.get("/Broker");

        const LedgerGroup = Broker.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
      if (isData === "POS") {
        const {
          data: { data },
        } = await axiosInstance.post("/Pos/getPosByField", {
          showFiled: { Name: 1, Salutation: 1, LastName: 1, MiddleName: 1 },
        });
        // data.forEach((element) => {
        //   element["key"] = "pos";
        //   // element["Name"] =
        // });
        setPartyAccountOpt(data);
      }

      if (isData === "LedgerGroup") {
        const {
          data: { data: LedgerGroup },
        } = await axiosInstance.get("/LedgerEntry");
        // LedgerGroup.forEach((element) => {
        //   element["key"] = "ledgerentries";
        // });

        setPartyAccountOpt(LedgerGroup);
      }
    } catch (error) {}
  };
  const getPosPartyAccount = async (isData) => {
    try {
      if (isData === "POS") {
        const {
          data: { data },
        } = await axiosInstance.post("/Pos/getPosByField", {
          showFiled: { Name: 1, Salutation: 1, LastName: 1, MiddleName: 1 },
        });

        setPartyAccountOptFor(data);
      }
      if (isData === "Broker") {
        const {
          data: { data: Broker },
        } = await axiosInstance.get("/Broker");

        setPartyAccountOptFor(Broker);
      }

      if (isData === "LedgerGroup") {
        const {
          data: { data: LedgerGroup },
        } = await axiosInstance.get("/LedgerEntry");

        setPartyAccountOptFor(LedgerGroup);
      }
    } catch (error) {}
  };
  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/DebitNotes/single/${_id}`);
      
      getPosPartyAccount(value?.FromModal);
      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      setPaymentListHandler(value);
      setEntryDate(value?.EntryDate);
      setCashBankOptHandler(value.DebitNotes);
    } catch (error) {
      
    }
  };

  const setPaymentListHandler = (value) => {
    const {
      LedgerEntry: Entry,
      PartyAccount: Account,
      Broker: BrokerDetails,
    } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (BrokerDetails) {
      const { _id, Name } = BrokerDetails;
      obj = {
        _id,
        Name,
      };
    }
    if (Account) {
      const { _id, Name } = Account;
      obj = {
        _id,
        Name,
      };
    }
    setPartyAccountLedger(obj);
  };
  const setCashBankOptHandler = (DebitNotes) => {
    const myArr = DebitNotes?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        _id,
        ...rest
      } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }
      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      return obj;
    });
    setDebitNotes(myArr);
  };
  useEffect(() => {
    if (_id) {
      getDataById();
    }
  }, [_id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>{Mode ? "Edit Debit Notes" : "Create Debit Notes"}</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter DebitNotes "
                  required //done
                />
              </div>

              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  onChange={(e) => {
                    setFromModal(e.target.value);
                    getPosPartyAccount(e.target.value);
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-4 align-items-center">
                <label>Party Account Ledger</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOptFor}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setPartyAccountLedger}
                  value={PartyAccountLedger}
                />
                {/* <select
                  
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccountLedger"
                  value={PartyAccountLedger}
                  onChange={(e) => {
                    setPartyAccountLedger(e.target.value);
                  }}
                  required
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOptFor &&
                    PartyAccountOptFor.map((data) => (
                      <option value={data._id}>{data.Name}</option>
                    ))}
                 
                </select> */}
              </div>
              <div className="col-md-4 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Enter Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={From}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    getPos(e.target.value);
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 align-items-center">
                {/* <label>PartyAccount</label> */}

                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOpt}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setPartyAccount}
                  value={PartyAccount}
                />
                {/* <select
                  
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccount"
                  value={PartyAccount}
                  onChange={(e) => {
                    setPartyAccount(e.target.value);
                  }}
                  required
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOpt &&
                    PartyAccountOpt.filter(
                      (data) => data._id !== PartyAccountLedger
                    ).map((data) => (
                      <option
                        value={JSON.stringify({
                          _id: data._id,
                          Name: data.Name,
                          key: data.key,
                        })}
                      >
                        {data.Name}
                      </option>
                    ))}
              
                </select> */}
              </div>
              <div className="col-md-2 align-items-center">
                {/* <label>Account</label> */}
                <input
                  className="form-control "
                  type="number"
                  name="Account"
                  value={Account}
                  onChange={(e) => setAccount(e.target.value)}
                  placeholder="Amount"
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                {/* <label>Ref Number</label> */}
                <input
                  className="form-control "
                  type="text"
                  name="Ref Data"
                  value={RefNumber}
                  onChange={(e) => setRefNumber(e.target.value)}
                  placeholder="Enter Narration"
                  required //done
                />
              </div>
              <div
                className="col-md-2 align-items-center "
                style={
                  {
                    // paddingTop: "1.4rem",
                  }
                }
              >
                <button
                  className="btn mybtn"
                  onClick={() => {
                    addHandler();
                  }}
                >
                  Add to list
                </button>
              </div>
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">PartyAccount</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Narration</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DebitNotes.map((ele, index) => (
                        <tr>
                          <td scope="row">{index + 1}</td>
                          <td>{ele?.Name}</td>
                          <td>{ele?.Account}</td>
                          <td>{ele?.RefNumber}</td>
                          <td>
                            <BiEdit
                              size={32}
                              style={{
                                backgroundColor: "green",
                                color: "#fff",
                                padding: "5px",
                                borderRadius: "5px",
                                marginTop: "-5px",
                              }}
                              onClick={() => editHandler(ele, index)}
                            />
                            &nbsp;
                            <AiFillDelete
                              size={32}
                              style={{
                                backgroundColor: "#A50406",
                                color: "#fff",
                                padding: "5px",
                                borderRadius: "5px",
                                marginTop: "-5px",
                              }}
                              onClick={() => {
                                const isTrue = window.confirm(
                                  "Do you want to delete!"
                                );
                                if (isTrue) {
                                  removeHandler(index);
                                }
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th scope="row"></th>
                        <th>Total</th>
                        <th>
                          {DebitNotes.reduce((sum, a) => sum + a.Account, 0)}
                        </th>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                className="btn mybtn "
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewDebitNotes;
