import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Rto.css";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
const CreateRto = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [RTOName, setRTOName] = useState(value?.RTOName || "");

  const [RTOCode, setRTOCode] = useState(value?.RTOCode || "");
  const [State, setState] = useState(
    value?.State || {
      _id: "",
      StateName: "-- Select State --",
    }
  );
  const [StateOpt, setStateOpt] = useState([]);

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {

      if(!State?._id){
        return  ToastError("State is required");
      }
      const {
        data: { success, message },
      } = await axiosInstance.post("/RTO", {
        RTOName,
        RTOCode,
        State:State?._id
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/RTO/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      if(!State?._id){
        return  ToastError("State is required");
      }
      const {
        data: { success, message },
      } = await axiosInstance.put(`/RTO/${value?._id}`, {
        RTOName,
        RTOCode,
        State:State?._id
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/RTO/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getState = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/State`);

      setStateOpt(data);
    } catch (error) {}
  };
  useEffect(() => {
    getState();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              zoom: "90%",
            }}
          >
            <h5>{view ? "View RTO" : Mode ? "Edit RTO" : "Create New RTO"}</h5>
            <hr />
            <br />
            <div className="row">
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label className="form-label" htmlFor="formname">
                  RTO Name
                </label>
                <input
                  className="form-control "
                  type="text"
                  name="RTOName"
                  value={RTOName}
                  onChange={(e) => setRTOName(e.target.value)}
                  placeholder="Enter RTO Name"
                  required //done
                  disabled={view}
                />
              </div>
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label className="form-label" htmlFor="formemail">
                  RTO Code
                </label>
                <input
                  className="form-control "
                  type="text"
                  name="RTOCode"
                  value={RTOCode}
                  onChange={(e) => setRTOCode(e.target.value)}
                  placeholder="RTOCode"
                  required //done
                  disabled={view}
                />
              </div>
              <div className="col-4" style={{ float: "left", padding: 15 }}>
                <label className="form-label" htmlFor="formemail">
                  State
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={[
                    {
                      _id: "",
                      StateName: "-- Select State --",
                    },
                    ...StateOpt,
                  ]}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["StateName"]}
                  onChange={(ele) => {
                    setState(ele);
                  }}
                  value={State}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => navigate("/admin/masters/RTO/data")}
              >
                Go Back
              </button>
              &nbsp;
              {!view && (
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={() => {
                    Mode ? handleEdit() : handleCreate();
                  }}
                >
                  Submit
                </button>
              )}
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateRto;
