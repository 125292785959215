export const removeEmptyValues = (object) => {
  const keys = Object.keys(object);
  for (var i = 0; i < keys.length; ++i) {
    const key = keys[i];
    const value = object[key];
    if (value === null || value === undefined || value === "") {
      delete object[key];
    }
  }
};

export function getPreviousDay(date = "2023-10-01") {
  // Get the current date

  if (date <= "2023-10-01") {
    return "01-10-2023";
  }
  let currentDate = new Date(date);

  // Subtract one day in milliseconds (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  let previousDay = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);

  // Extract the year, month, and day components
  let year = previousDay.getFullYear();
  let month = previousDay.getMonth() + 1; // Months are zero-based, so we add 1
  let day = previousDay.getDate();

  // Format the date as YYYY-MM-DD (e.g., "2023-11-27")
  let formattedDate =
    (day < 10 ? "0" : "") +
    day +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    year;

  return formattedDate;
}

export const convertTimeToIndianTimeZone = (Time) => {
  // Input time in UTC

  const myDate = String(Time).split("T");

  // Convert input time to JavaScript Date object
  var dateObj = new Date("1970-01-01T" + myDate[1]);

  // Convert to Indian Standard Time (IST)
  dateObj.setUTCHours(dateObj.getUTCHours()); // Add 5 hours for IST
  dateObj.setUTCMinutes(dateObj.getUTCMinutes()); // Add 30 minutes for IST

  // Format the time in 12-hour AM/PM format
  var options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  var formattedTime = dateObj.toLocaleString("en-IN", options);
  return ` ${myDate[0]}  ${formattedTime}`;
};

export const returnCurrentTime = () => {
  const timezone = "Asia/Kolkata";
  const currentDateTime = new Date().toLocaleString("en-US", {
    timeZone: timezone,
  });
  return currentDateTime;
};

export const ValidateAccountDetails = (
  bankHolderName = "",
  PersonName = ""
) => {
  return bankHolderName.replace(/\s/g, "").toLocaleLowerCase() ===
    PersonName.replace(/\s/g, "").toLocaleLowerCase()
    ? false
    : true;
};

export const removeObjectEmptyValues = (obj) => {
  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === null || obj[key] === undefined || obj[key] === "")
    ) {
      delete obj[key];
    }
  }
  return obj;
};

export const MyDebounceFunction = (input, cb) => {
  let timer;

  return function () {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => cb(input), 500);
  };
};

export const convertValueIntoRound = (obj = {}) => {
  for (const key in obj) {
    obj[key] = Math.round(obj[key]);
  }
  return obj;
};
export const returnUnique = (arrOFRto = []) => {
  let jsonObject = arrOFRto.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

  return uniqueArray;
};
export const returnInsuranceTypeValue = (insuranceType = []) => {
  const SelectedInsuranceTypeData = insuranceType
    ?.map((ele) => ele.value)
    ?.filter((ele) => ele !== "");

  const index = SelectedInsuranceTypeData.length - 1;
  return SelectedInsuranceTypeData[index];
};
export const ReturnFullName = (value = {}) => {
  const { Salutation = "", Name = "", MiddleName = "", LastName = "" } = value;

  return `${Salutation} ${Name} ${MiddleName} ${LastName}`;
};

export const coverAmountIntoCurrent = (amount) => {
  let isAmount = isNaN(amount) ? 0 : amount;
  const MyAmount = Math.abs(isAmount);
  const myAmount = new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 0,
  }).format(MyAmount);

  return myAmount;
};


export const returnAgeDifference = (
  dateString1,
  dateString2,
  showDate = false
) => {
  // Convert string dates to Date objects
  let date1 = new Date(dateString1);
  let date2 = new Date(dateString2);

  // Calculate the difference in years, months, and days
  let diffYear = date2.getFullYear() - date1.getFullYear();
  let diffMonth = date2.getMonth() - date1.getMonth();
  let diffDate = date2.getDate() - date1.getDate();

  // Adjust for negative months and days
  if (diffDate < 0) {
    diffMonth--;
    const lastMonthDays = new Date(
      date2.getFullYear(),
      date2.getMonth(),
      0
    ).getDate();
    diffDate += lastMonthDays;
  }

  // Adjust for negative months
  if (diffMonth < 0) {
    diffYear--;
    diffMonth += 12;
  }

  if (showDate) {
    return `${diffYear} Year${diffYear !== 1 ? "s" : ""} ${diffMonth} Month${
      diffMonth !== 1 ? "s" : ""
    } ${diffDate} Day${diffDate !== 1 ? "s" : ""}`;
  }

  // If you only want the total number of years (ignoring months and days)
  return (diffYear * 12 + diffMonth) / 12;
};

export function reversDate(date = "") {
  if (date) {
    return date.split("-").reverse().join("-");
  }

  return "";
}
export function afterOneYearDate(date = "") {
  // Assuming your input date is in the format 'YYYY-MM-DD'
  var inputDate = new Date(date);

  // Add 1 year to the input date
  inputDate.setFullYear(inputDate.getFullYear() + 1);

  // Subtract 1 day to get the exact "one year ago" date
  inputDate.setDate(inputDate.getDate() - 1);

  // Format the new date as 'YYYY-MM-DD'
  var formattedDate = inputDate.toISOString().slice(0, 10);

  return formattedDate;
}
export function convertDateStringToDate(dateString = "") {
  // Create a Date object
  var date = new Date(dateString);

  // Get the components of the date
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  var day = date.getDate().toString().padStart(2, "0");
  var formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
