import { toast } from "react-toastify";

export const ToastSusses = (Message) => {
  toast.success(Message ?? "Something Went Wrong", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
};
export const ToastInfo = (Message) => {
  toast.info(Message ?? "Something Went Wrong", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
};
export const ToastError = (Message) => {
  toast.error(Message ?? "Something Went Wrong", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
};
export const ToastWarn = (Message) => {
  toast.warn(Message ?? "Something Went Wrong", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
};
