import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../config";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { coverAmountIntoCurrent } from "../../Utils/usefullFunction";
import { BiSearchAlt } from "react-icons/bi";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const TrialBalance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  let DrAmount = 0;
  let CrAmount = 0;
  const getData = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Accountancy/reportOfTdsSheet?selectedIndex=${selectedIndex}`
      );

      setData(data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />
      <div
        className="container-fluid"
        style={{ marginTop: "80px", zoom: "80%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div className="d-flex gap-3">
                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="PolicyType"
                        style={{
                          minWidth: "200px",
                        }}
                        value={selectedIndex}
                        onChange={(e) => {
                          setSelectedIndex(e.target.value);
                        }}
                        required
                      >
                        <option value={0}>Banks</option>
                        <option value={1}>POS</option>
                        <option value={2}>Ledger</option>
                        <option value={3}>brokers</option>
                      </select>
                    </div>

                    <div className="">
                      <BiSearchAlt
                        size={32}
                        onClick={() => getData()}
                        style={{
                          backgroundColor: "#174379",
                          color: "#fff",
                          padding: "5px",
                          borderRadius: "5px",
                          marginTop: "2px",
                        }}
                      />{" "}
                      &nbsp; &nbsp;
                      {data && data?.length !== 0 && (
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button btn mybtn"
                          table="table-to-xls"
                          filename={`Trial-Balance`}
                          sheet="tablexls"
                          buttonText="Download as XLS"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <br></br>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    {
                      <table
                        class="table table-striped table-bordered overflow-x mt-3"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th scope="col" style={{ textAlign: "center" }}>
                              #
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Name
                            </th>

                            <th scope="col" style={{ textAlign: "center" }}>
                              DR
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              CR
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length !== 0 &&
                            data
                              .filter((ele) => ele.Total !== 0)
                              .map((ele, index) => {
                                if (ele?.Total >= 0) {
                                  DrAmount = DrAmount + Math.abs(ele?.Total);
                                } else {
                                  CrAmount = CrAmount + Math.abs(ele?.Total);
                                }

                                return (
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{ele?.oppositeLedger?.Name}</td>
                                    <td
                                      style={{
                                        textAlign: "end",
                                      }}
                                    >
                                      {ele?.Total >= 0 && ele?.Total
                                        ? coverAmountIntoCurrent(
                                            Math.abs(ele?.Total)
                                          )
                                        : ""}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "end",
                                      }}
                                    >
                                      {ele?.Total < 0 && ele?.Total
                                        ? coverAmountIntoCurrent(
                                            Math.abs(ele?.Total)
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                );
                              })}

                          <tr>
                            <td></td>

                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "end",
                              }}
                            >
                              Total:-
                            </td>
                            <td
                              style={{
                                textAlign: "end",
                                fontWeight: "bold",
                              }}
                            >
                              {coverAmountIntoCurrent(DrAmount)}
                            </td>
                            <td
                              style={{
                                textAlign: "end",
                                fontWeight: "bold",
                              }}
                            >
                              {coverAmountIntoCurrent(CrAmount)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default TrialBalance;
