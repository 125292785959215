const headersPos = [
  "Code",
  "Branch",
  "Name",
  "AliasName",
  "Mobile",
  "Email",
  "OpeningBalance",
  "AadhaarNumber",
  "BankName",
  "IFSC",
  "MaritalStatus",
  "GSTINNumber",
  "PanNumber",
  "EntityType",
  "Address",
  "ParentPOS",
  "ReportingToMotor",
  "BankAccountNo",
  "Examined",
  "ExaminedStatus",
  "ExaminedRemark",
  "EntityType",
  "ReferenceCriteria",
  "PayoutCycle",
  "City",
  "Pincode",
  "ReportingToNonMotor",
  "LastName",
  "Salutation",
  "MiddleName",
];

const headers = [
  "Sr.No.",
  "Insurance Company",
  "Policy Number",
  "Insured Name",
  "Customer Type",
  "Business Prop. Date",
  "Issue Date",
  "Start Date (RSD)",
  "Expiry Date (RED)",
  "Vehicle Number",
  "DoRegn.",
  "YoMfg.",
  "Vehicle Age",
  "Product",
  "Sub Product",
  "Vehicle Class",
  "Policy Type",
  "Business Type",
  "Make",
  "Model",
  "Variant",
  "Fuel Type",
  "CC/Watt",
  "GVW",
  "Seater",
  "IDV/Sum insured",
  "POS Name",
  "TP Premium",
  "CPA",
  "Legal Liability",
  "OD Premium",
  "Add On Premium",
  "Commisionable Premium",
  "NCB",
  "ODD %",
  "IMT Type",
  "LOB",
  "Mandate Letter",
  "Nil Dep",
  "Remarks",
  "Policy Copy Link",
];

const requiredFields = [
  "Insurance Company",
  "Policy Number",
  "Insured Name",
  "Business Prop. Date",
  "Issue Date",
  "Start Date (RSD)",
  "Expiry Date (RED)",
  "DoRegn.",
  "Product",
  "Sub Product",
  "Business Type",
  "POS Name",
  "LOB",
  "Mandate Letter",
  "Nil Dep",
];

const businessTypeMappedData = {
  NEW: "NEW",
  USED: "USED",
  "Roll Over": "Roll Over",
  Renewal: "Renewal",
};

const validateFields = (dataObject) => {
  const missingFields = [];

  requiredFields.forEach((field) => {
    if (field === "Business Prop. Date" && dataObject[field]) {
      const date = dataObject[field].split("-").reverse().join("-");
      // console.log("date", date);
      const isValidDate = new Date(date);
      // console.log("isValidDate", isValidDate);
      if (isValidDate === "Invalid Date") {
        missingFields.push({
          valid: false,
          policyNumber: dataObject["Policy Number"],
          fieldName: field,
          fieldValue: dataObject[field],
          row: dataObject,
        });
      }
    }

    if (field === "Issue Date" && dataObject[field]) {
      const date = dataObject[field].split("-").reverse().join("-");
      const isValidDate = new Date(date);

      if (isValidDate === "Invalid Date") {
        missingFields.push({
          valid: false,
          policyNumber: dataObject["Policy Number"],
          fieldName: field,
          fieldValue: dataObject[field],
          row: dataObject,
        });
      }
    }

    if (field === "Start Date (RSD)" && dataObject[field]) {
      const date = dataObject[field].split("-").reverse().join("-");
      const isValidDate = new Date(date);

      if (isValidDate === "Invalid Date") {
        missingFields.push({
          valid: false,
          policyNumber: dataObject["Policy Number"],
          fieldName: field,
          fieldValue: dataObject[field],
          row: dataObject,
        });
      }
    }

    if (field === "Expiry Date (RED)" && dataObject[field]) {
      const date = dataObject[field].split("-").reverse().join("-");
      const isValidDate = new Date(date);

      if (isValidDate === "Invalid Date") {
        missingFields.push({
          valid: false,
          policyNumber: dataObject["Policy Number"],
          fieldName: field,
          fieldValue: dataObject[field],
          row: dataObject,
        });
      }
    }

    if (field === "DoRegn." && dataObject[field]) {
      const date = dataObject[field].split("-").reverse().join("-");
      const isValidDate = new Date(date);

      if (isValidDate === "Invalid Date" && dataObject?.LOB === "Motor") {
        missingFields.push({
          valid: false,
          policyNumber: dataObject["Policy Number"],
          fieldName: field,
          fieldValue: dataObject[field],
          row: dataObject,
        });
      }
    }
    if (!dataObject[field] && dataObject?.LOB === "Motor") {
      missingFields.push({
        valid: false,
        policyNumber: dataObject["Policy Number"],
        fieldName: field,
        fieldValue: dataObject[field],
        row: dataObject,
      });
    }
  });

  return missingFields;
};

export const validateHeaders = (responseHeaders) => {
  const missingHeaders = [];

  // Check for missing or mismatched required headers
  headers.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

export const validateHeadersPos = (responseHeaders) => {
  const missingHeaders = [];

  // Check for missing or mismatched required headersPos
  headersPos.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

export const createMapping = (dataArray, keyField, valueField) => {
  return dataArray.reduce((acc, item) => {
    acc[item[keyField].trim().toLowerCase()] = item[valueField];
    return acc;
  }, {});
};

export const validateAndUpdateField = (
  dataArray,
  keyName,
  fieldName,
  mapping
) => {
  const errorPolicyData = [];
  const data = dataArray.map((item) => {
    const mappedId = item?.[keyName]
      ? mapping?.[item?.[keyName]?.trim().toLowerCase()]
      : null;

    if (!mappedId && item[keyName]) {
      errorPolicyData.push({
        valid: false,
        policyNumber: item["Policy Number"],
        fieldName: keyName,
        fieldValue: item[keyName],
        row: item,
      });
    }
    return {
      ...item,
      [fieldName]: mappedId,
    };
  });

  return { data, error: errorPolicyData };
};

export const updateVariant = (mapData, data, mappingObj) => {
  const errorPolicyData = [];
  const { Make, Model } = mappingObj;

  const variantMap = mapData.reduce((acc, item) => {
    const variant = (item["VariantName"] || "").trim();
    const make = (item?.Make?.Make || "").trim();
    const model = (item?.Model?.Model || "").trim();

    const key = `${variant}${make}${model}`.toLowerCase();

    acc[key] = item;
    return acc;
  }, {});

  const updateData = data.map((item) => {
    let mappedData = null;

    const variantKey = `${(item?.Variant ?? "")?.trim()}${(
      item?.Make ?? ""
    )?.trim()}${(item?.Model ?? "")?.trim()}`.toLowerCase();

    mappedData = variantMap[variantKey];

    if (
      !mappedData &&
      Object.keys(Make).length > 0 &&
      Object.keys(Model).length > 0
    ) {
      const makeKey = `${(item?.Make ?? "")?.trim()}`.toLowerCase();
      const modelKey = `${(
        item?.Model?.toString() ?? ""
      )?.trim()}`.toLowerCase();
      const make = Make[makeKey];
      const model = Model[modelKey];

      if (!make && item?.LOB === "Motor") {
        errorPolicyData.push({
          valid: false,
          id: `${item?.["SNo."]}`,
          policyNumber: item["Policy Number"] || "",
          fieldName: "Make",
          fieldValue: item?.Make,
          row: item,
        });
      }

      if (!model && item?.LOB === "Motor") {
        errorPolicyData.push({
          valid: false,
          id: `${item?.["SNo."]}`,
          policyNumber: item["Policy Number"],
          fieldName: "Model",
          fieldValue: item?.Model,
          row: item,
        });
      }

      mappedData = {
        _id: null,
        Make: { _id: make },
        Model: { _id: model },
      };
    }

    if (item?.LOB === "Motor" && !item["Make"]?.trim()) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        policyNumber: item["Policy Number"],
        fieldName: "Make",
        fieldValue: item?.Make,
        row: item,
      });
    }
    if (!mappedData && item["Make"] && item?.LOB === "Motor") {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        policyNumber: item["Policy Number"],
        fieldName: "Make",
        fieldValue: item?.Make,
        row: item,
      });
    }

    if (item?.LOB === "Motor" && !item["Model"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        policyNumber: item["Policy Number"],
        fieldName: "Model",
        fieldValue: item?.Model,
        row: item,
      });
    }

    if (!mappedData && item["Model"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        policyNumber: item["Policy Number"],
        fieldName: "Model",
        fieldValue: item?.Model,
        row: item,
      });
    }

    if (!mappedData && item["Variant"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        policyNumber: item["Policy Number"],
        fieldName: "Variant",
        fieldValue: item?.Variant,
        row: item,
      });
    }

    return {
      ...item,
      MakeModal: mappedData?.Make?._id || null,
      Model: mappedData?.Model?._id || null,
      Variant: mappedData?._id || null,
    };
  });
  return { data: updateData, error: errorPolicyData };
};

export const updateRTO = (mapData, data) => {
  const errorPolicyData = [];
  const variantMap = mapData.reduce((acc, item) => {
    acc[item?.RTOCode.trim().toLowerCase()] = item;
    return acc;
  }, {});

  const updateData = data.map((item) => {
    if (item?.LOB === "Non Motor") {
      return item;
    }

    const mappedData =
      variantMap[
        `${item["Vehicle Number"]
          ?.toString()
          .trim()
          ?.toLowerCase()
          ?.slice(0, 4)}`
      ];

    if (!mappedData && item["Vehicle Number"]) {
      errorPolicyData.push({
        valid: false,
        policyNumber: item["Policy Number"],
        fieldName: "Vehicle Number",
        fieldValue: item["Vehicle Number"],
        row: item,
      });
    }

    const errorFields = validateFields(item);

    if (errorFields && errorFields.length > 0) {
      errorPolicyData.push(...errorFields);
    }

    return {
      ...item,
      RTO: mappedData?._id,
    };
  });
  return { data: updateData, error: errorPolicyData };
};

// product
export const updateInsuranceType = (mapData, data, user, branchMapping) => {
  const errorPolicyData = [];
  const insTypeMap = mapData.reduce((acc, item) => {
    acc[item?.InsuranceType.trim().toLowerCase()] = item;
    return acc;
  }, {});

  const updateData = data.map((item) => {
    const keyName =
      item["Vehicle Class"] || item["Sub Product"] || item["Product"] || null;

    const mappedData = insTypeMap[keyName.trim().toLowerCase()];

    const mappedDataBusinessType =
      businessTypeMappedData?.[item?.["Business Type"]];

    if (!mappedData && item["Product"]) {
      errorPolicyData.push({
        valid: false,
        policyNumber: item["Policy Number"],
        fieldName: "Product",
        fieldValue: item["Product"],
        row: item,
      });
    }

    if (!mappedData && item["Sub Product"]) {
      errorPolicyData.push({
        valid: false,
        policyNumber: item["Policy Number"],
        fieldName: "Sub Product",
        fieldValue: item["Sub Product"],
        row: item,
      });
    }

    if (!mappedData && item["Vehicle Class"]) {
      errorPolicyData.push({
        valid: false,
        policyNumber: item["Policy Number"],
        fieldName: "Vehicle Class",
        fieldValue: item["Vehicle Class"],
        row: item,
      });
    }

    if (!mappedDataBusinessType) {
      errorPolicyData.push({
        valid: false,
        businessType: item["Business Type"],
        fieldName: "Business Type",
        fieldValue: item?.["Business Type"],
        row: item,
      });
    }

    let InsuranceUnderFlow = [];

    if (
      item["Product"] &&
      mappedData &&
      mappedData?.InsuranceUnderFlow.length >= 1
    ) {
      const root = mappedData?.InsuranceUnderFlow[0];
      if (root?.InsuranceType === item["Product"].trim()) {
        InsuranceUnderFlow.push(root?._id);
      } else {
        errorPolicyData.push({
          valid: false,
          policyNumber: item["Policy Number"],
          fieldName: "Product",
          fieldValue: item["Product"],
          row: item,
        });
      }
    }

    if (
      item["Sub Product"] &&
      mappedData &&
      mappedData?.InsuranceUnderFlow.length >= 2
    ) {
      const subProduct = mappedData?.InsuranceUnderFlow[1];
      if (subProduct?.InsuranceType === item["Sub Product"].trim()) {
        InsuranceUnderFlow.push(subProduct?._id);
      } else {
        errorPolicyData.push({
          valid: false,
          policyNumber: item["Policy Number"],
          fieldName: "Sub Product",
          fieldValue: item["Sub Product"],
          row: item,
        });
      }
    }

    if (
      item["Vehicle Class"] &&
      mappedData &&
      mappedData?.InsuranceUnderFlow.length >= 3
    ) {
      const vehicleClass = mappedData?.InsuranceUnderFlow[2];
      if (vehicleClass?.InsuranceType === item["Vehicle Class"].trim()) {
        InsuranceUnderFlow.push(vehicleClass?._id);
      } else {
        errorPolicyData.push({
          valid: false,
          policyNumber: item["Policy Number"],
          fieldName: "Vehicle Class",
          fieldValue: item["Vehicle Class"],
          row: item,
        });
      }
    }

    if (mappedData) {
      InsuranceUnderFlow.push(mappedData?._id);
    }

    const ODPremium = item?.["OD Premium"];
    const TPPremium = item?.["TP Premium"];
    const PAPremium = item?.["CPA"];
    const LLPremium = item?.["Legal Liability"];
    const AddOnPremium = item?.["Add On Premium"];

    const ODPremiumGST = ((18 / 100) * (ODPremium ?? 0)).toFixed(2);
    const AddOnPremiumGST = ((18 / 100) * (AddOnPremium ?? 0)).toFixed(2);
    let TPPremiumGST = 0;
    if (mappedData) {
      TPPremiumGST = ((mappedData?.GST / 100) * (TPPremium ?? 0)).toFixed(2);
    }
    const PAPremiumGST = ((18 / 100) * (PAPremium ?? 0)).toFixed(2);
    const LLPremiumGST = ((18 / 100) * (LLPremium ?? 0)).toFixed(2);

    const NETPremiumData =
      +(ODPremium ?? 0) +
      +(AddOnPremium ?? 0) +
      +(TPPremium ?? 0) +
      +(PAPremium ?? 0) +
      +(LLPremium ?? 0);

    const TotalGSTAmount =
      +ODPremiumGST +
      +AddOnPremiumGST +
      +TPPremiumGST +
      +PAPremiumGST +
      +LLPremiumGST;

    const GrossPremiumData = TotalGSTAmount + NETPremiumData;

    return {
      ...item,
      PolicyNumber: item?.["Policy Number"],
      InsureeName: item["Insured Name"],
      IssueDate: item["Issue Date"].trim(),
      RSD: item["Start Date (RSD)"].trim(),
      RED: item["Expiry Date (RED)"].trim(),
      InsuranceType: mappedData?._id,
      InsuranceUnderFlow: InsuranceUnderFlow,
      RegistrationDate:
        item?.LOB === "Motor" && item["DoRegn."] ? item["DoRegn."].trim() : "",
      IMTType: item?.["IMT Type"] || "Not Applicable",
      BusinessType: item["Business Type"],
      VehicleNumber: item?.["Vehicle Number"],
      YearOfManufacture: item?.["YoMfg."],
      ODPremium: ODPremium,
      ODPremiumGST: ODPremiumGST,
      TPPremium: TPPremium,
      TPPremiumGST: TPPremiumGST,
      PAPremium: PAPremium,
      PAPremiumGST: PAPremiumGST,
      LLPremium: LLPremium,
      LLPremiumGST: LLPremiumGST,
      AddOnPremium: AddOnPremium,
      AddOnPremiumGST: AddOnPremiumGST,
      NETPremium: NETPremiumData,
      GrossPremium: GrossPremiumData,
      Engine: item?.["CC/Watt"],
      nillDepth: item?.["Nil Dep"],
      MandateLetter: item?.["Mandate Letter"],
      ODDiscount: item?.["ODD %"],
      IDV_Sum: item?.["IDV/Sum insured"],
      policyCopyLink: item?.["Policy Copy Link"],
      PA: PAPremium > 0 ? "With PA" : "Without PA",
      CustomerType: item?.["Customer Type"],
      EnteredBy: user?._id,
      EnteredByModel: user?.userType,
      Branch: branchMapping[item?.["POS"]],
      Status: "PENDING",
      DateOfBirth:
        item?.LOB === "Non-Motor" && item["DoRegn."]
          ? item["DoRegn."].trim()
          : "",
      IsNonMotorPolicy: item?.LOB === "Non-Motor" ? true : false,
    };
  });
  return { data: updateData, error: errorPolicyData };
};
