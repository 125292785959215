import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import { MdPendingActions } from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import { AiOutlineStop } from "react-icons/ai";
import { FiRefreshCcw } from "react-icons/fi";
import { MdOutlineRefresh } from "react-icons/md";
import { BsShieldCheck } from "react-icons/bs";
import { Tab, TablePagination, Tabs, Tooltip } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import {
  convertDateStringToDate,
  coverAmountIntoCurrent,
  removeEmptyValues,
} from "../../Utils/usefullFunction";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { useDispatch, useSelector } from "react-redux";
import DateRange from "../DateRangeModule/DateRange";
import useGettingData from "../Hooks/useGettingData";
import MultiInsuranceTypeFilter from "../../Utils/MultiInsuranceTypeFilter";
import ExcelExport from "../utils/ExcelExport/ExcelExport";
import { columns, transformData } from "./helper";

const QC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((store) => store.user);
  const today = new Date();

  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [startCreatedAtDate, setStartCreatedAtDate] = useState(firstDay);
  const [endCreatedAtDate, setEndCreatedAtDate] = useState(lastDay);
  const [data, setData] = useState([]);
  const [TabsValue, setTabsValue] = useState("PENDING");
  const [LOB, setLOB] = useState("");
  const [Premium, setPremium] = useState({});
  const [POSOpt, setPOSOpt] = useState([]);
  const {
    getData: getInsuranceCompany,
    Loading: CompanyLoading,
    Options: InsuranceCompanyOpt,
  } = useGettingData("/InsuranceCompany", [
    {
      _id: "",
      AliasName: "-- Select Company --",
    },
  ]);

  let {
    getData: getBranch,
    Loading: BranchLoading,
    Options: BranchOpt = [
      {
        _id: "",
        BranchName: "-- Select Branch --",
      },
      user?.Branch,
    ],
  } = useGettingData("/Branch", [
    {
      _id: "",
      BranchName: "-- Select Branch --",
    },
  ]);
  const [IsFirstRendering, setIsFirstRendering] = useState(true);
  const [inputData, setInput] = useState("");
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [POSLoading, setPOSLoading] = useState(false);
  const [Status, setStatus] = useState({});
  const [Policy, setPolicy] = useState({
    startDate: firstDay,
    endDate: lastDay,
    filterOn: "IssueDate",
  });
  const [Branch, setBranch] = useState({
    BranchName: "-- Select Branch --",
    _id: "",
  });
  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    AliasName: " -- Select Company --",
  });

  const [POS, setPOS] = useState({
    _id: "",
    Name: "-- Select POS --",
  });

  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  const filterDataHandler = async ({
    EraserHandler = false,
    StopLoading = false,
    notCallOther = true,
  }) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");
    try {
      !StopLoading && setLoading(true);
      removeEmptyValues(Policy);

      let que = {};
      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS["_id"]) {
        que["POS"] = POS["_id"];
      }

      if (Branch["_id"] || (user && user?.Branch?.BranchCode !== "0")) {
        que["Branch"] =
          user && user?.Branch?.BranchCode === "0"
            ? Branch["_id"]
            : user?.Branch?._id;
      }

      if (!EraserHandler) {
        que = {
          ...que,
          ...Policy,
          InsuranceType: SelectedInsuranceTypeData,
        };
      } else {
        que = {};
        que["startDate"] = firstDay;
        que["endDate"] = lastDay;
        que["PayoutCycle"] = "";
        que["InsuranceType"] = [];
      }
      const {
        data: { data, totalDocs },
      } = await axiosInstance.post("/Policy/getPolicyByFilter", {
        page: pagination + 1,
        limit: rowsPerPage,
        TabsValue,
        startCreatedAtDate,
        endCreatedAtDate,
        inputData,
        ...que,
      });

      setTotalDocs(totalDocs);
      setData(data);
      onSetPolicyFilter("SavedData", data);
      onSetPolicyFilter("SavedTotalDocs", totalDocs);
      getPremium({ que });
      if (notCallOther) {
        getStatus({ que });
      }
      !StopLoading && setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getPremium = async ({ que = {} }) => {
    try {
      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyPremium", {
        page: pagination + 1,
        limit: rowsPerPage,
        startCreatedAtDate,
        endCreatedAtDate,
        TabsValue,
        inputData,
        ...que,
      });

      setPremium(Premium[0]);
    } catch (error) {}
  };
  const getStatus = async ({ que = {} }) => {
    try {
      const {
        data: { StatusCount },
      } = await axiosInstance.post("/Policy/getPolicyStatus", {
        page: pagination + 1,
        limit: rowsPerPage,
        startCreatedAtDate,
        endCreatedAtDate,
        TabsValue,
        inputData,
        ...que,
      });
      setStatus(StatusCount);
    } catch (error) {}
  };

  const EraserHandler = async () => {
    dispatch({
      type: "setPolicyFilterDefaultValue",
    });
    const newObj = {
      startDate: firstDay,
      endDate: lastDay,
      InsuranceCompany: "",
      PolicyType: "",
      BusinessType: "",
      PayoutCycle: "",
    };
    setInput("");
    setPolicy(newObj);
    filterDataHandler({ EraserHandler: true });
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
    setSelectedInsuranceType((pre) => [reSetData]);
  };
  const setDateHandler = (ranges) => {
    setPolicy((pre) => {
      return {
        ...pre,
        startDate: convertDateStringToDate(ranges.startDate),
        endDate: convertDateStringToDate(ranges.endDate),
      };
    });
  };

  const onSetPolicyFilter = (key, value) => {
    dispatch({
      type: "updatePolicyFilters",
      key: key,
      value: value,
    });
  };

  const InsuranceTypeFilter = (key, value) => {
    setSelectedInsuranceType(value);
  };

  useEffect(() => {
    if (user && user?.Branch && user?.Branch?.BranchCode !== "0") {
      setBranch(user?.Branch);
    }
  }, [user]);

  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
      );
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {}
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          "/InsuranceType?Root=true&InsuranceCategory=${LOB}"
        );
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${
              InsuranceType[index - 1]
            }&InsuranceCategory=${LOB}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };
  useEffect(() => {
    if (IsFirstRendering) {
      setIsFirstRendering(false);

      filterDataHandler({});
    } else {
      filterDataHandler({
        notCallOther: false,
      });
    }
  }, [pagination, rowsPerPage, TabsValue, Branch]);

  useEffect(() => {
    if (SelectedInsuranceType.length === 0 || LOB) {
      callInsuranceData();
    }
  }, [LOB]);

  const onChangeSetValue = (e) => {
    setPolicy({ ...Policy, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (IsFirstRendering) {
      return;
    }
    const timer = setTimeout(
      () => filterDataHandler({ EraserHandler: false, StopLoading: true }),
      1000
    );
    return () => clearTimeout(timer);
  }, [inputData]);

  const getPolicyPos = async () => {
    let { startDate, endDate } = Policy;
    setPOSLoading(true);
    try {
      const {
        data: { POS },
      } = await axiosInstance.post("/Policy/getPolicyPos", {
        TabsValue,
        startDate,
        endDate,
      });

      setPOSOpt([
        {
          _id: "",
          Name: "-- Select POS --",
        },
        ...POS,
      ]);
      setPOSLoading(false);
    } catch (error) {
      setPOSLoading(false);
    }
  };
  const dateFilterCombo = [
    { label: "Issue Date", value: "IssueDate" },
    { label: "Start Date (RSD)", value: "RSD" },
    // { label: "Created At", value: "createdAt" },
  ];

  const getData = async () => {
    try {
      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");

      let que = {};
      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS && POS["_id"]) {
        que["POS"] = POS["_id"];
      }
      if (Branch["_id"]) {
        que["Branch"] = Branch["_id"];
      }
      if (SelectedInsuranceTypeData.length > 0) {
        que["InsuranceType"] = SelectedInsuranceTypeData;
      }

      que["ClusterCalculations"] = "No";

      const {
        data: { data },
      } = await axiosInstance.post("/Policy/getPolicyByFilterForExcel", {
        ...Policy,
        filterOn: Policy.filterOn,
        LOB: Policy?.LOB || "",
        TabsValue: TabsValue,
        inputData,
        user: { _id: user?._id, branchCode: user?.Branch?.BranchCode },
        ...que,
      });

      const rows = await transformData(data);

      return {
        columns,
        rows,
        fileName: `QC Policy-${Policy?.startDate}-To-${Policy?.endDate}`,
      };
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "10px" }}>
        <div className="row CreateCard p-4">
          <div
            className="container filters"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              gap: "20px",
              zoom: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                gap: "15px",
              }}
            >
              <SearchInputFilter
                setInput={setInput}
                inputData={inputData}
                PlaceHolder="Policy No., Vehicle No., Insuree Name*"
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <div onClick={getInsuranceCompany}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={InsuranceCompanyOpt}
                    isLoading={CompanyLoading}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["AliasName"]}
                    onChange={setInsuranceCompany}
                    value={InsuranceCompany}
                    styles={{ backgroundColor: "#F5F5F6", height: "42px" }}
                  />
                </div>
                <div
                  onClick={() =>
                    user?.Branch?.BranchCode === "0" && getBranch()
                  }
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={
                      BranchOpt.length === 0 ? [user?.Branch] : BranchOpt
                    }
                    isDisabled={user?.Branch?.BranchCode !== "0"}
                    isLoading={BranchLoading}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["BranchName"]}
                    value={Branch}
                    onChange={setBranch}
                    styles={{ height: "42px" }}
                  />
                </div>
                <div
                  className="POS-SELECTIBOX"
                  onClick={getPolicyPos}
                  style={{ flex: "1 1 300px", minWidth: "450px" }}
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={POSOpt}
                    noOptionsMessage={() => "No POS Found"}
                    isLoading={POSLoading}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["Name"]}
                    onChange={setPOS}
                    value={POS}
                    styles={{ backgroundColor: "#F5F5F6", height: "42px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between pt-3 align-items-center flex-wrap gap-3">
            <div className="d-flex gap-3 align-items-center flex-wrap">
              <div className="d-flex align-items-center gap-2">
                <div>
                  <input
                    className="form-control"
                    style={{ width: "120px" }}
                    value="Create Date"
                    readOnly
                  />
                </div>
                <input
                  className="form-control"
                  type="date"
                  name="startCreatedAtDate"
                  style={{ height: "42px", maxWidth: "140px" }}
                  value={startCreatedAtDate}
                  onChange={(e) => setStartCreatedAtDate(e.target.value)}
                  required
                />
                <span>-</span>
                <input
                  className="form-control"
                  type="date"
                  name="endCreatedAtDate"
                  style={{ height: "42px", maxWidth: "140px" }}
                  value={endCreatedAtDate}
                  onChange={(e) => setEndCreatedAtDate(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <select
                  className="form-select SelectBoxWith"
                  name="filterOn"
                  value={Policy?.filterOn}
                  onChange={(e) => onChangeSetValue(e)}
                  required
                >
                  {dateFilterCombo &&
                    dateFilterCombo.map((data) => (
                      <option value={data.value}>{data.label}</option>
                    ))}
                </select>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  value={Policy?.startDate}
                  onChange={(e) => onChangeSetValue(e)}
                  required
                />
                <span>-</span>
                <input
                  className="form-control"
                  type="date"
                  name="endDate"
                  value={Policy?.endDate}
                  onChange={(e) => onChangeSetValue(e)}
                  required
                />
              </div>
              <div>
                <DateRange setDateHandler={setDateHandler} />
              </div>
            </div>
            <div>
              <FiRefreshCcw
                size={32}
                onClick={() => filterDataHandler({})}
                style={{
                  backgroundColor: "#174379",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />{" "}
              &nbsp;
              <Tooltip content="Reset">
                <MdOutlineRefresh
                  onClick={EraserHandler}
                  size={32}
                  style={{
                    backgroundColor: "#f99d36",
                    color: "#fff",
                    padding: "5px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
            <div className="d-flex align-items-center gap-3">
              <select
                className="form-select"
                name="LOB"
                value={LOB}
                onChange={(e) => {
                  onSetPolicyFilter("SelectedInsuranceType", [
                    { data: [], value: "" },
                  ]);
                  setLOB(e.target.value);
                }}
                required
              >
                <option value="">All</option>
                <option value="Motor">Motor</option>
                <option value="Non-Motor">Non-Motor</option>
              </select>
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsuranceTypeFilter
                          key={data.value}
                          Options={data}
                          setSelectedInsuranceType={InsuranceTypeFilter}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                        />
                      )
                  )}
              </div>
            </div>
          </div>

          <div className=" p-2 d-flex justify-content-center">
            <ul class="nav nav-pills  shadow-sm">
              <li class="nav-item nav-link" style={{ color: "#295394" }}>
                {`${coverAmountIntoCurrent(Premium?.GrossPremium)}`}
                <span> Gross Premium</span>
              </li>

              <li class="nav-item nav-link" style={{ color: "#295394" }}>
                {`${coverAmountIntoCurrent(Premium?.NetPremium)}`}
                <span> NET Premium</span>
              </li>
              {/* <li class="nav-item nav-link" style={{ color: "#295394" }}>
                {`${coverAmountIntoCurrent(Premium?.ODPremium)}`}
                <span> OD Premium</span>
              </li> */}
            </ul>
          </div>
          <div
            className="container-fluid"
            style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
          >
            <div className="row">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "1px",
                }}
              >
                <div className="col-lg-8" style={{ float: "left" }}>
                  <Tabs
                    value={TabsValue}
                    onChange={(ele, value) => setTabsValue(value)}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary icon position tabs example"
                  >
                    <Tab
                      icon={<BiTimer style={{ color: "blue" }} />}
                      value="ALL"
                      iconPosition="start"
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          {`All ${Status["ALL"] ?? ""}`}
                        </span>
                      }
                    />

                    <Tab
                      icon={<BiTimer style={{ color: "blue" }} />}
                      value="PENDING"
                      iconPosition="start"
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          {`Pending ${Status["PENDING"] ?? ""}`}
                        </span>
                      }
                    />
                    <Tab
                      icon={<BsShieldCheck style={{ color: "green" }} />}
                      value="APPROVED"
                      iconPosition="start"
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          {`Approved ${Status["APPROVED"] ?? ""}`}
                        </span>
                      }
                    />
                    <Tab
                      icon={<MdPendingActions style={{ color: "#E8960F" }} />}
                      value="HOLD"
                      iconPosition="start"
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          {`Hold ${Status["HOLD"] ?? ""}`}
                        </span>
                      }
                    />
                    <Tab
                      icon={<AiOutlineStop style={{ color: "red" }} />}
                      value="REJECTED"
                      iconPosition="start"
                      label={
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          {`Rejected ${Status["REJECTED"] ?? ""}`}
                        </span>
                      }
                    />
                  </Tabs>
                </div>
                <div
                  className="flex-right-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ExcelExport getData={getData} />
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <div
              className=" p-2 container"
              style={{
                zoom: "80%",
              }}
            >
              {data && (
                <table class="table table-striped   table-bordered  overflow-x mt-1">
                  <thead>
                    <tr>
                      <th scope="col" style={{ textAlign: "center" }}>
                        SNo.
                      </th>
                      <th style={{ textAlign: "left" }}>Insurer</th>
                      <th scope="col" style={{ textAlign: "left" }}>
                        Policy Number
                      </th>
                      <th scope="col" style={{ textAlign: "left" }}>
                        Insured Name
                      </th>

                      <th scope="col" style={{ textAlign: "left" }}>
                        POS
                      </th>

                      <th scope="col" style={{ textAlign: "left" }}>
                        Vehicle No.
                      </th>

                      <th style={{ textAlign: "right", width: "160px" }}>
                        Net Premium
                      </th>
                      <th style={{ textAlign: "right", width: "160px" }}>
                        Gross Premium
                      </th>
                      <th style={{ textAlign: "left", width: "140px" }}>
                        Branch
                      </th>
                      {/* {TabsValue === "APPROVED" && (
                        <th scope="col" style={{ textAlign: "center" }}>
                          Points
                        </th>
                      )} */}
                      {TabsValue === "REJECTED" && (
                        <th scope="col" style={{ textAlign: "center" }}>
                          Remarks
                        </th>
                      )}

                      <th scope="col" style={{ textAlign: "center" }}>
                        Approve
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data?.length !== 0 &&
                      data.map((data, index) => (
                        <tr key={index}>
                          <th scope="row" style={{ textAlign: "center" }}>
                            {index + 1}
                          </th>
                          <td>
                            {" "}
                            <tr
                              className="d-flex"
                              style={{
                                width: "100px",
                              }}
                            >
                              {" "}
                              <img
                                src={`${REACT_APP_FileURL}${data?.InsuranceCompany?.Logo}`}
                                style={{ width: "3vw", borderRadius: "10px" }}
                                loading="lazy"
                                alt=""
                              />
                              &nbsp;&nbsp;{" "}
                              <div>
                                <div> {data?.InsuranceCompany?.AliasName}</div>
                              </div>
                            </tr>
                          </td>
                          <td>
                            <Link
                              to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                              target="_blank"
                              rel="noreferrer"
                              state={{
                                color: "#2424d1",
                              }}
                            >
                              {data?.PolicyNumber}
                            </Link>
                          </td>
                          <td>{data?.InsureeName}</td>
                          <td>
                            {`${data?.POS?.Salutation ?? ""} ${
                              data?.POS?.Name ?? ""
                            } ${data?.POS?.MiddleName ?? ""} ${
                              data?.POS?.LastName ?? ""
                            }`}{" "}
                          </td>
                          {/* <td>{data?.IssueDate}</td> */}
                          <td> {data?.VehicleNumber}</td>
                          {/* <td>{data?.Broker?.Name}</td> */}
                          {/* <td>{data?.InsureeName}</td> */}
                          {/* <td>{data?.InsuranceCompany?.Name}</td> */}

                          <td className="text-end">
                            {" "}
                            {`${coverAmountIntoCurrent(data?.NETPremium)}`}
                          </td>
                          <td className="text-end">
                            {" "}
                            {`${coverAmountIntoCurrent(data?.GrossPremium)}`}
                          </td>
                          <td className="text-start">
                            {" "}
                            {data?.Branch?.BranchName}
                          </td>

                          {/* {TabsValue === "APPROVED" && (
                            <td className="text-end">{`${coverAmountIntoCurrent(
                              data?.Commission?.TotalAmountToPay
                            )}`}</td>
                          )} */}

                          {TabsValue === "REJECTED" && (
                            <td style={{ textAlign: "center" }}>
                              {data?.QCRemark}
                            </td>
                          )}
                          {/* <td>{data?.MakeModal?.Name}</td>

                            <td>{data?.BusinessType}</td>

                            <td>{data?.RSD}</td>
                            <td>{data?.RegistrationDate}</td>
                            <td>{data?.IssueDate}</td>
                            <td>{data?.YearOfManufacture}</td> */}
                          <td>
                            {
                              <Link
                                to={`/admin/QC/${data._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn mybtn"
                                state={{
                                  value: data,
                                }}
                              >
                                Approve
                              </Link>
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {
                <div
                  style={{
                    backgroundColor: "#1a2e45d7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    marginTop: "5px",
                  }}
                >
                  <TablePagination
                    component="div"
                    className="text-white"
                    rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                    count={totalDocs ?? 0}
                    page={pagination}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              }
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default QC;

//    AllKey={AllKey}
// data={filteredData}
// editUrl={"/admin/Policy"}
// Mode={true}
// deleteFunction={deletePolicy}
