import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import { Box, Fade } from "@mui/material";
import "./QC.css";
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
const CommissionTable = ({ handleClose, open, ModalData }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "90vh",
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 40,
    p: 4,
    borderRadius: "5px",
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Commission Table
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <table
                class="table table-striped table-dark"
                style={{
                  zoom: "65%",
                }}
              >
                <thead>
                  <tr>
                    {Object.keys(ModalData).map((ele) => (
                      <th scope="col">{ele}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(ModalData).map((ele) => (
                      <td>{ModalData[ele]}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </Typography>
            <AiFillCloseCircle className="closedIcon" onClick={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CommissionTable;
