import { useCallback } from "react";
import { ToastError } from "../../Utils/Tost";

const useHttpErrorHandler = () => {
  const handleHttpError = useCallback((error) => {
    if (error?.response?.data?.message) {
      ToastError(error?.response?.data?.message);
    }
   else if (error?.response?.data?.Message) {
      ToastError(error?.response?.data?.Message);
    } else if (error.message) {
      ToastError(error?.message);
    } else if (error) {
      ToastError(error);
    }
  }, []);

  return handleHttpError;
};

export default useHttpErrorHandler;
