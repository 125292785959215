import { useState } from "react";
import { axiosInstance } from "../../config";

const useGettingData = (url, initialOpt=[]) => {
  const [Loading, setLoading] = useState(false);
  const [Options, setOptions] = useState([]);
  const getData = async () => {
    try {
      if (Options.length > 0) {
        return false;
      }
      setLoading(true);
      const {
        data: { data },
      } = await axiosInstance.get(url);
      setOptions([...initialOpt, ...data]);
      setLoading(false);
    } catch (error) {
      console.log("check error: " + error);
      setLoading(false);
    }
  };
  return {
    getData,
    Loading,
    Options,
  };
};

export default useGettingData;
