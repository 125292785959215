import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { REACT_APP_FileURL } from "../../config";
import { FaEye } from "react-icons/fa";
import AlertDialogSlide from "../../Utils/AlertDialogSlide";
import "./Table.css";
const TableTransition = ({
  AllKey,
  data,
  deleteFunction,
  editUrl,
  Mode,
  isProfileImage,
  IsPos,
  EditId,
  isPolicy,
  isBank,
  IsLedgerGroup,
  IsLedgerEntry,
  isInsuranceType,
  isRtoGroupChecker,
  isPayoutGrid,
  isInsuranceCompany,
  isRto,
}) => {
  const [open, setOpen] = useState(false);
  const [SelectId, setSelectId] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AlertDialogSlide
        open={open}
        handleClose={handleClose}
        Title="Do you want to delete!"
        deleteFunction={deleteFunction}
        SelectId={SelectId}
      />
      <div
        class="table-responsive"
        style={{
          zoom: "85%",
        }}
      >
        {AllKey && (
          <>
            {data && data.length !== 0 && (
              <table class="table table-striped table-bordered overflow-x mt-3">
                <thead id="tableResize">
                  <tr>
                    <th scope="col" style={{ textAlign: "center" }}>
                      SNo.
                    </th>
                    {isProfileImage && (
                      <th scope="row" style={{ textAlign: "center" }}>
                        Image
                      </th>
                    )}
                    {isInsuranceCompany && <th scope="row">Logo</th>}
                    {isRtoGroupChecker && (
                      <>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Company Name
                        </th>
                        {/* <th scope="row">List_Of_Rto</th> */}
                      </>
                    )}
                    {AllKey &&
                      AllKey.map((data, index) => (
                        <th
                          key={index}
                          scope="col"
                          style={{ textAlign: "center" }}
                        >
                          {data}
                        </th>
                      ))}

                    {isBank && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          Branch
                        </th>
                      </>
                    )}

                    {IsPos && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          Pan Card
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          adharcardFrontImage
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          adharcardBackImage
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          MarkSheet
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          Cheque
                        </th>
                      </>
                    )}
                    {isRtoGroupChecker && (
                      <>
                        {/* <th scope="row">Company_Name</th> */}
                        <th style={{ textAlign: "center" }} scope="row">
                          List of RTO
                        </th>
                      </>
                    )}
                    {isPayoutGrid && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          Insurance Type
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          Insurance Company
                        </th>
                        {/* <th scope="row">MakeModal</th> */}
                      </>
                    )}

                    {isInsuranceType && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          Under
                        </th>
                      </>
                    )}
                    {isPolicy && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          NewPolicyCopy
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          RC1
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          RC2
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          PreviousPolicy1
                        </th>
                        <th style={{ textAlign: "center" }} scope="row">
                          PreviousPolicy2
                        </th>
                      </>
                    )}
                    {IsLedgerGroup && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          Under
                        </th>
                      </>
                    )}
                    {IsLedgerEntry && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          Group
                        </th>
                      </>
                    )}
                    {isRto && (
                      <>
                        <th style={{ textAlign: "center" }} scope="row">
                          State
                        </th>
                      </>
                    )}
                    <th style={{ textAlign: "center" }} scope="col">
                      Action
                    </th>
                    {/* <div className="col-md-2 btn btn-secondary">EXTRA OPTIONS</div> */}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((value, index) => (
                      <tr key={value._id}>
                        <th scope="row">
                          {index + 1}

                          {/* {(pagination * rowsPerPage) + index + 1} */}
                        </th>
                        {isProfileImage && (
                          <th scope="row">
                            <img
                              src={`${REACT_APP_FileURL}${value.profilePic}`}
                              alt=""
                              height={80}
                              width={80}
                            />
                          </th>
                        )}
                        {isInsuranceCompany && (
                          <td
                            style={{
                              width: "80px",
                            }}
                          >
                            <img
                              src={`${REACT_APP_FileURL}${value?.Logo}`}
                              loading="lazy"
                              alt=""
                              height={60}
                              width={60}
                              className="Default-InsuranceCompanyLogoImage"
                              style={{
                                borderRadius: "50%",
                                border: "1px solid gray",
                              }}
                            />
                          </td>
                        )}
                        {AllKey.map((element, index) => {
                          let isShow = false;
                          if (
                            element === "ReportingTo" ||
                            element === "Manufacturer" ||
                            element === "InsuranceType"
                          ) {
                            isShow = true;
                          }

                          return (
                            <>
                              {isShow ? (
                                <>
                                  {element === "ReportingTo" && (
                                    <>
                                      <td key={index}>{value[element].Name}</td>
                                    </>
                                  )}
                                  {element === "Manufacturer" && (
                                    <>
                                      <td key={index}>
                                        {value[element].ManufacturerName}
                                      </td>
                                    </>
                                  )}
                                  {element === "InsuranceType" && (
                                    <>
                                      <td key={index}>
                                        {value[element]?.InsuranceType ||
                                          value[element]}
                                      </td>
                                    </>
                                  )}
                                </>
                              ) : (
                                <td key={index}>
                                  {!(value[element] instanceof Object) &&
                                    value[element]}
                                </td>
                              )}
                            </>
                          );
                        })}

                        {isPayoutGrid && (
                          // <span class="badge text-bg-info m-2 p-2">{`${ele.InsuranceType}`}  &nbsp; </span>
                          <>
                            <td>
                              {" "}
                              {`${value?.InsuranceType?.map(
                                (ele) => `${ele.InsuranceType}`
                              )}`}
                            </td>
                            <td> {`${value?.InsuranceCompany?.Name}`}</td>
                          </>
                        )}
                        {IsPos && (
                          <>
                            <td>
                              <img
                                src={`${REACT_APP_FileURL}${value.panCard}`}
                                alt=""
                                height={80}
                                width={80}
                              />
                            </td>
                            <td>
                              <img
                                src={`${REACT_APP_FileURL}${value.adharcardFrontImage}`}
                                alt=""
                                height={80}
                                width={80}
                              />
                            </td>
                            <td>
                              <img
                                src={`${REACT_APP_FileURL}${value.adharcardBackImage}`}
                                alt=""
                                height={80}
                                width={80}
                              />
                            </td>
                            <td>
                              <img
                                src={`${REACT_APP_FileURL}${value.MarkSheet}`}
                                alt=""
                                height={80}
                                width={80}
                              />
                            </td>
                            <td>
                              <img
                                src={`${REACT_APP_FileURL}${value.Cheque}`}
                                alt=""
                                height={80}
                                width={80}
                              />
                            </td>
                          </>
                        )}

                        {isBank && (
                          <>
                            <td> {`${value?.Branch?.BranchName}`}</td>
                          </>
                        )}
                        {isInsuranceType && (
                          <>
                            <td>
                              {" "}
                              {`${
                                value?.InsuranceUnder?.InsuranceType ?? "Root"
                              }`}
                            </td>
                          </>
                        )}
                        {isRtoGroupChecker && (
                          <>
                            {/* <th scope="row">InsuranceCompany</th> */}
                            <td>{`${value?.InsuranceCompany?.Name ?? ""}`}</td>
                            <td>
                              {value?.ListOfRto?.map((ele) => (
                                <span class="badge text-bg-info m-2 p-2">{`${ele.RTOName}(${ele.RTOCode})`}</span>
                              ))}
                            </td>
                            {/* <th scope="row">List Of Rto</th> */}
                            {/* <td>{`${value?.Under?.Name ?? ""}`}</td> */}
                          </>
                        )}
                        {IsLedgerGroup && (
                          <>
                            <td>{`${value?.Under?.Name ?? ""}`}</td>
                          </>
                        )}
                        {isRto && (
                          <>
                            <td>{`${value?.State?.StateName ?? ""}`}</td>
                          </>
                        )}
                        {IsLedgerEntry && (
                          <>
                            <td>{`${value?.Group?.Name ?? ""}`}</td>
                          </>
                        )}
                        <td>
                          {" "}
                          {value?.createdBy !== "By System" && (
                            <div className="d-flex gap-3">
                              <div>
                                <Link
                                  className="col-md-1"
                                  state={{
                                    value,
                                    Mode,
                                    view: true,
                                  }}
                                  to={
                                    EditId
                                      ? `${editUrl}/${value?._id}`
                                      : `${editUrl}`
                                  }
                                >
                                  <FaEye
                                    size={35}
                                    style={{
                                      backgroundColor: "#E8960F",
                                      color: "#fff",
                                      padding: "7px",
                                      borderRadius: "5px",
                                    }}
                                  />{" "}
                                </Link>
                              </div>
                              <div>
                                <Link
                                  className="col-md-1"
                                  state={{
                                    value,
                                    Mode,
                                  }}
                                  to={
                                    EditId
                                      ? `${editUrl}/${value?._id}`
                                      : `${editUrl}`
                                  }
                                >
                                  <BiEdit
                                    size={35}
                                    style={{
                                      backgroundColor: "green",
                                      color: "#fff",
                                      padding: "7px",
                                      borderRadius: "5px",
                                    }}
                                  />{" "}
                                </Link>
                              </div>
                              <div>
                                <AiFillDelete
                                  size={35}
                                  style={{
                                    backgroundColor: "#A50406",
                                    color: "#fff",
                                    padding: "7px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectId(value?._id);
                                    // const isTrue = window.confirm(
                                    //   "Do you want to delete!"
                                    // );
                                    // if (isTrue) {
                                    //   deleteFunction(value?._id);
                                    // }
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TableTransition;
