import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableTransition from "../TableTransition/TableTransition";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
const Club = () => {
  const [data, setData] = useState();
  const [AllKey, setAllKey] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [firstRender, setFirstRender] = useState(false);
  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/Club?page=${pagination + 1}&limit=${rowsPerPage}`
      );
      let Keys = [];
      if (data[0]) {
        Keys = Object.keys(data[0]);
      }
      setTotalDocs(totalDocs);
      setAllKey(
        Keys.filter(
          (e) =>
            e !== "_id" &&
            e !== "sNo" &&
            e !== "createdAt" &&
            e !== "__v" &&
            e !== "updatedAt"
        )
      );
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteClub = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/Club/${_id}`);

      if (success) {
        getDataByPageNation();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const getDataByPageNation = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Club?page=${
          pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}`
      );
      let Keys = [];
      if (data[0]) {
        Keys = Object.keys(data[0]);
      }

      setAllKey(
        Keys.filter(
          (e) =>
            e !== "_id" && e !== "createdAt" && e !== "__v" && e !== "updatedAt"
        )
      );
      setData(data);
    } catch (error) {}
  };
  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    }
    getDataByPageNation();
  }, [pagination, rowsPerPage, inputData]);
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, ClubName..."
      />
      <div
        className="container-fluid"
        style={{ marginTop: "70px", zoom: "85%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div className="row">
                  <div className="col-10" style={{ float: "left" }}></div>
                  <div className="col-2" style={{ float: "left" }}>
                    <Link
                      className="btn mybtn"
                      to="/admin/masters/Club"
                      club={{
                        Mode: false,
                        value: "",
                      }}
                    >
                      Add Club
                    </Link>
                  </div>
                </div>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    <TableTransition
                      AllKey={AllKey}
                      data={data}
                      editUrl={"/admin/masters/Club"}
                      Mode={true}
                      rowsPerPage
                      deleteFunction={deleteClub}
                    />
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      >
                        <TablePagination
                          component="div"
                          className="text-white"
                          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                          count={totalDocs ?? 0}
                          page={pagination}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Club;
