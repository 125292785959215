import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { MultiSelect } from "react-multi-select-component";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import Select from "react-select";
const convertSelectBoxToValueData = (arr) => {
  if (arr.length === 0) return [];
  const filterArr = arr.map((ele) => ele.value).filter((ele) => ele !== "");
  let uniqueArr = new Set([...filterArr]);
  let fromArr = Array.from(uniqueArr);
  return fromArr;
};

const convertInsuranceType = (arr) => {
  if (arr.length === 0) return null;
  let myArr = arr.map((ele) => ele.value).filter((ele) => ele !== "");
  return myArr[myArr.length - 1];
};

const CreateRtoGroupChecker = () => {
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [RTOGroupName, setRTOGroupName] = useState(value?.GroupName || "");
  const [InsuranceCompany, setInsuranceCompany] = useState(
    value?.InsuranceCompany ?? {
      _id: "",
      AliasName: "-- Select Insurance Company --",
    }
  );
  const [StateOpt, setStateOpt] = useState([]);
  const [State, setState] = useState({
    _id: "",
    StateName: "-- Select State --",
  });

  const [PolicyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [PolicyType, setPolicyType] = useState(
    value?.PolicyType ? value?.PolicyType._id : null
  );
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [ListOfRto, setListOfRto] = useState([]);
  const [ListOfRtoOpt, setListOfRtoOpt] = useState([]);
  const [ListOfRtoLog, setListOfRtoLog] = useState([]);
  const [Seater, setSeater] = useState({
    seaterFrom: value?.seaterFrom,
    seaterTo: value?.seaterTo,
  });
  const [GVW, setGVW] = useState({
    GVWFrom: value?.GVWFrom,
    GVWTo: value?.GVWTo,
  });
  const [DEF, setDEF] = useState(value?.DEF ?? lastDay);
  const [DET, setDET] = useState(value?.DET ?? lastDay);
  const [inputData, setInput] = useState("");
  const navigate = useNavigate();
  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/RtoGroupChecker", {
        GroupName: RTOGroupName,
        InsuranceCompany: InsuranceCompany._id ?? "",
        DEF,
        DET,
        ...GVW,
        ...Seater,
        PolicyType,
        ListOfRto: convertSelectBoxToValueData(ListOfRto),
        InsuranceType: convertInsuranceType(SelectedInsuranceType),
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/masters/RtoGroupChecker/data");
      } else {
        ToastSusses(message);
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
      // ToastError(error?.response?.data?.message);
    }
  };
  const handleEdit = async () => {
    const MyDataObj = {};
    if (String(DEF) !== value?.DEF) {
      MyDataObj["OldListOfRto"] = value.ListOfRto.reduce(
        (acc, it) => [...acc, it._id],
        []
      );
    }

    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/RtoGroupChecker/${value?._id}`, {
        GroupName: RTOGroupName,
        InsuranceCompany: InsuranceCompany._id ?? "",
        DEF,
        DET,
        ...GVW,
        ...Seater,
        PolicyType,
        // ListOfRtoLog: value?.ListOfRtoLog ?? [],
        ListOfRto: convertSelectBoxToValueData(ListOfRto),
        InsuranceType: convertInsuranceType(SelectedInsuranceType) || null,
        DateLog: `${value?.DEF ?? DEF} TO ${DEF}`,
        preDEF: value?.DEF ?? DEF,
        OldListOfRto: value.ListOfRto.reduce((acc, it) => [...acc, it._id], []),
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/RtoGroupChecker/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const getData = async () => {
    try {
      const [incCompany, state, policyType] = await Promise.all([
        axiosInstance.get("/InsuranceCompany"),
        axiosInstance.get(`/State`),
        axiosInstance.get("/PolicyType"),
      ]);

      setPolicyTypeOpt(policyType?.data?.data);
      setInsuranceCompanyOpt(incCompany?.data?.data);
      setStateOpt(state?.data?.data);
    } catch (error) {}
  };

  const getRTO = async (_id) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/RTO/SelectBoxDropdown?State=${_id}`);
      console.log("data", data);
      setListOfRtoOpt(data);
    } catch (error) {}
  };
  const getRTOPromiseOptions = async (options, inputValue = "") => {
    if (!inputValue) {
      return options;
    }
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/RTO/SelectBoxDropdown?inputData=${inputValue}&State=${State?._id}`
      );
      return data;
    } catch (error) {}
  };
  const getDataById = async () => {
    try {
      const {
        data: {
          data: {
            ListOfRto = [],
            ListOfRtoLog = [],
            InsuranceType = "",
            ...REST
          },
        },
      } = await axiosInstance.get(`/RtoGroupChecker/getDataById/${value?._id}`);

      if (ListOfRto) {
        const RTO = await ListOfRto.map((ele) => {
          return {
            label: `${ele.RTOName}-(${ele.RTOCode})`,
            value: ele._id,
          };
        });
        setListOfRto(RTO);
        setListOfRtoOpt(RTO);
        InsuranceType
          ? callInsuranceData([
              ...InsuranceType.InsuranceUnderFlow,
              InsuranceType._id,
            ])
          : rootInsuranceType();
      } else {
        setListOfRto([]);
      }

      setListOfRtoLog(ListOfRtoLog);
    } catch (error) {}
  };
  const removeHandler = (index) => {
    const ListOfRtoArr = ListOfRto;
    ListOfRtoArr.splice(index, 1);
    setListOfRto([...ListOfRtoArr]);
  };
  const callInsuranceData = async (InsuranceType = []) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          "/InsuranceType?Root=true&InsuranceCategory=Motor"
        );
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      rootInsuranceType();
    }
  };

  const rootInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        "/InsuranceType?Root=true&InsuranceCategory=Motor"
      );
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (Mode) {
      getDataById();
      return;
    }
    callInsuranceData([]);
  }, []);

  const selectRtoData = (e) => {
    const returnUnique = (arrOFRto = []) => {
      let jsonObject = arrOFRto.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      return uniqueArray;
    };
    if (ListOfRtoOpt.length === e.length) {
      setListOfRto((pre) => returnUnique([...e, ...pre]));
    } else {
      setListOfRto(e);
    }
  };
  return (
    <>
      <Header setInput={setInput} inputData={inputData} />
      <div className="MinDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row ">
            <div className="CreateCard">
              <div className="card-body">
                <h4 className="card-title">
                  {Mode ? "Edit RTO Group" : "Create New RTO Group"}
                </h4>
                <hr />
                <div className="row ">
                  <div className="col-4">
                    <label className="my-2">RTO Group Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name=" RTO Group Name"
                      value={RTOGroupName}
                      onChange={(e) => setRTOGroupName(e.target.value)}
                      placeholder="Enter RTO Group Name"
                      required //done
                      disabled={view}
                    />
                  </div>
                  <div className="col-4">
                    <label className="my-2">DEF (From)</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Date"
                      name="DEF"
                      value={DEF}
                      onChange={(e) => setDEF(e.target.value)}
                      placeholder="Enter FromAge "
                      required //done
                    />
                  </div>
                  <div className="col-4">
                    <label className="my-2">DEF (To)</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Date"
                      name="DET"
                      value={DET}
                      onChange={(e) => setDET(e.target.value)}
                      placeholder="Enter ToAge "
                      required //done
                    />
                  </div>
                  <div className="col-4 ">
                    <label className="my-2">Policy Type</label>
                    <select
                      disabled={view}
                      class="form-select"
                      aria-label="Default select example"
                      name="PolicyType"
                      value={PolicyType || null}
                      onChange={(e) => setPolicyType(e.target.value)}
                    >
                      <option value="">-- Select Policy Type --</option>
                      {PolicyTypeOpt &&
                        PolicyTypeOpt.map((data) => (
                          <option key={data?._id} value={data?._id}>
                            {data.PolicyTypeName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <label className="my-2">Insurance Company</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          AliasName: "-- Select Insurance Company --",
                        },
                        ...InsuranceCompanyOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["AliasName"]}
                      onChange={(ele) => {
                        setInsuranceCompany(ele);
                      }}
                      value={InsuranceCompany}
                    />
                  </div>
                  <div className="col-4">
                    <label className="my-2">State</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          StateName: "-- Select State --",
                        },
                        ...StateOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["StateName"]}
                      onChange={(ele) => {
                        setState(ele);
                        if (ele._id) {
                          getRTO(ele._id);
                        }
                      }}
                      value={State}
                    />
                  </div>

                  {
                    <div className="col-md-4 align-items-center">
                      <label className="my-2">RTO Data</label>
                      <MultiSelect
                        options={ListOfRtoOpt}
                        value={ListOfRto}
                        onChange={(e) => {
                          selectRtoData(e);
                        }}
                        labelledBy="Select"
                        disabled={view}
                        // filterOptions={getRTOPromiseOptions}
                      />
                    </div>
                  }
                  {SelectedInsuranceType &&
                    SelectedInsuranceType?.length > 0 &&
                    SelectedInsuranceType.map(
                      (data, index) =>
                        data &&
                        data?.data?.length > 0 && (
                          <MultiInsurance
                            key={index}
                            Options={data}
                            setSelectedInsuranceType={setSelectedInsuranceType}
                            view={view}
                            SelectedInsuranceType={SelectedInsuranceType}
                            index={index}
                            OptionsValue={data.value}
                            InsuranceCompany={InsuranceCompany?._id}
                            col="col-md-4"
                            isLabel={true}
                          />
                        )
                    )}
                  <div className="col-2">
                    <label className="my-2">GVW From</label>
                    <input
                      className="form-control"
                      type="number"
                      name="GVW"
                      value={GVW?.GVWFrom}
                      onChange={(e) =>
                        setGVW((prev) => ({ ...prev, GVWFrom: e.target.value }))
                      }
                      placeholder="Enter GVW"
                      required //done
                      disabled={view}
                    />
                  </div>
                  <div className="col-2">
                    <label className="my-2">GVW To</label>
                    <input
                      className="form-control"
                      type="number"
                      name="GVW"
                      value={GVW?.GVWTo}
                      onChange={(e) =>
                        setGVW((prev) => ({ ...prev, GVWTo: e.target.value }))
                      }
                      placeholder="Enter GVW"
                      required //done
                      disabled={view}
                    />
                  </div>
                  <div className="col-2">
                    <label className="my-2">Seater From</label>
                    <input
                      className="form-control"
                      type="text"
                      name="Seater"
                      value={Seater?.seaterFrom}
                      onChange={(e) =>
                        setSeater((prev) => ({
                          ...prev,
                          seaterFrom: e.target.value,
                        }))
                      }
                      placeholder="Enter Seating Capacity"
                      required //done
                      disabled={view}
                    />
                  </div>
                  <div className="col-2">
                    <label className="my-2">Seater To</label>
                    <input
                      className="form-control"
                      type="text"
                      name="Seater"
                      value={Seater?.seaterTo}
                      onChange={(e) =>
                        setSeater((prev) => ({
                          ...prev,
                          seaterTo: e.target.value,
                        }))
                      }
                      placeholder="Enter Seating Capacity"
                      required //done
                      disabled={view}
                    />
                  </div>
                </div>

                <div className="d-flex gap-3 ">
                  <button
                    className="btn mybtn"
                    style={{ marginTop: 28 }}
                    onClick={() =>
                      navigate("/admin/masters/RtoGroupChecker/data")
                    }
                  >
                    Go Back
                  </button>
                  {!view && (
                    <button
                      className="btn mybtn"
                      style={{ marginTop: 28 }}
                      onClick={() => {
                        Mode ? handleEdit() : handleCreate();
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="CreateCard">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h4 className="card-title">Added RTO Lists</h4>
                  <h4 className="card-title">
                    {" "}
                    RTO Length :- {ListOfRto?.length ?? 0}
                  </h4>
                </div>
                <hr />

                <div
                  style={{
                    zoom: "80%",
                  }}
                >
                  {ListOfRto &&
                    ListOfRto?.length !== 0 &&
                    ListOfRto.map((data, index) => (
                      <span
                        class="badge text-bg-info m-2 p-2"
                        key={data.value}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const isTrue = window.confirm(
                            `Do you want to delete! ${data.label} `
                          );
                          if (isTrue) {
                            removeHandler(index);
                          }
                        }}
                      >
                        {data.label}{" "}
                        <MdDeleteForever
                          style={{
                            fontSize: "20px",
                          }}
                        />
                      </span>
                    ))}
                </div>
              </div>
            </div>
            {ListOfRtoLog && ListOfRtoLog?.length !== 0 && (
              <div className="CreateCard">
                <div className="card-body">
                  <h4 className="card-title">RTO Logs</h4>
                  <hr />
                  {/* <br /> */}
                  <div
                    className="table-responsive"
                    style={{
                      zoom: "70%",
                    }}
                  >
                    <table class="table">
                      <thead>
                        <tr
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <th scope="col">#</th>
                          <th scope="col">Date</th>
                          <th scope="col">Added</th>
                          <th scope="col">Removed</th>
                          <th scope="col">Created_By</th>
                          <th scope="col"> Modified</th>
                          {/* <th scope="col">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {ListOfRtoLog.map((data, index) => (
                          <tr
                            key={data._id}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{data.Date}</td>
                            <td>
                              {data.Added.length === 0 ? (
                                <span class="badge text-bg-success m-2 p-2">
                                  Zero Added
                                </span>
                              ) : (
                                data.Added.map((ele) => (
                                  <span class="badge text-bg-success m-2 p-2">
                                    {`${ele.RTOName} (${ele.RTOCode})`}
                                  </span>
                                ))
                              )}
                            </td>
                            <td>
                              {data.Removed.length === 0 ? (
                                <span class="badge text-bg-danger m-2 p-2">
                                  Zero Removed
                                </span>
                              ) : (
                                data.Removed.map((ele) => (
                                  <span class="badge text-bg-danger m-2 p-2">
                                    {`${ele.RTOName} (${ele.RTOCode})`}
                                  </span>
                                ))
                              )}
                            </td>
                            <td>{/* {ele.RTOName} */}</td>
                            <td>{index === 0 ? "Created" : "Edit"}</td>
                            {/* <td>
                              <span class="badge rounded-pill bg-warning text-dark p-2 m-2">
                                Undo
                              </span>
                              <span class="badge rounded-pill bg-secondary p-2 m-2">
                                Approved
                              </span>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateRtoGroupChecker;
