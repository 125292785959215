import React, { useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

const CreateFuelType = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};

  const [FuelType, setFuelType] = useState(value?.FuelType || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/FuelType", {
        FuelType,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/FuelType/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/FuelType/${value?._id}`, {
        FuelType,
      });
      if (success) {
        // ToastSusses(message);
        ToastSusses(message);
        navigate("/admin/masters/FuelType/data");
      } else {
        ToastSusses(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="card-body">
              <h4 className="card-title">
                <h5>
                  {view
                    ? "View Policy Type"
                    : Mode
                    ? "Edit FuelType"
                    : "Create FuelType"}
                </h5>
              </h4>
              <hr />
              <div data-repeater-item="" className="outer">
                <div className="col-6" style={{ float: "left", padding: 15 }}>
                  <label>FuelType</label>
                  <input
                    className="form-control "
                    type="text"
                    name="FuelType"
                    value={FuelType}
                    onChange={(e) => setFuelType(e.target.value)}
                    placeholder="Enter FuelType Name"
                    required //done
                    disabled={view}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/FuelType/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateFuelType;
