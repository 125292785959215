import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Alert, AlertTitle } from "@mui/material";
import * as XLSX from "xlsx";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  validateHeadersExcelPayout,
  updatePayoutInsuranceType,
  updateRTOCluster,
  updateVariant,
} from "../../lib/validateExcel";
import { createMapping } from "../../lib/validateData";
import "./styles.css";
import { axiosInstance, REACT_APP_FileURL } from "../../config";

const Business_TypeData = {
  NEW: "NEW",
  USED: "USED",
  "Roll Over": "Roll Over",
  Renewal: "Renewal",
};

const NCB_STATUS = {
  Yes: "Yes",
  No: "No",
};

const CustomeType = {
  Individual: "Individual",
  Corporate: "Corporate",
};

const NillDepth = {
  Yes: "Yes",
  No: "No",
};
const PA_TYPE = {
  "Without PA": "Without PA",
  "With PA": "With PA",
};

const IMPT_TYPE = {
  "IMT 23": "IMT 23",
  "IMT 34": "IMT 34",
  "Not Available": "Not Available",
};

const getLastAvailableProperty = (obj) => {
  return (
    obj?.["Vehicle Class"] || obj?.["Sub Product"] || obj?.["Product"] || null
  );
};

const transformData = (data) => {
  const result = data.reduce(
    (acc, curr) => {
      const { Variant, Make, Model } = curr;
      const insuranceCompany = curr?.["Insurance Company"];
      const RTOCluster = curr?.["RTO Cluster"];
      const fuelType = curr?.["Fuel Type"] || "";
      const DEF = curr["DEF (From)"];
      const DET = curr["DET (To)"];

      acc.DEF = DEF;
      acc.DET = DET;

      if (Variant) acc.variants.add(Variant);
      if (Make) acc.Make.add(Make);
      if (Model) acc.Model.add(Model);
      if (fuelType) acc.fuelType.add(fuelType);
      if (insuranceCompany) acc.insuranceCompany.add(insuranceCompany.trim());
      // if (RTOCluster) acc.RTOCluster.add(RTOCluster.trim());

      if (RTOCluster) {
        acc.RTOCluster.add(RTOCluster.replace(/\s*\(.*?\)/g, ""));
      }

      if (getLastAvailableProperty(curr)) {
        acc.insuranceType.add(
          getLastAvailableProperty(curr).replace(/\s*\(.*?\)/g, "")
        );
      }
      return acc;
    },
    {
      variants: new Set(),
      Make: new Set(),
      Model: new Set(),
      fuelType: new Set(),
      insuranceCompany: new Set(),
      RTOCluster: new Set(),
      insuranceType: new Set(),
      DEF: "",
      DET: "",
    }
  );

  return {
    variants: Array.from(result.variants),
    Make: Array.from(result.Make),
    Model: Array.from(result.Model),
    insuranceCompany: Array.from(result.insuranceCompany),
    RTOCluster: Array.from(result.RTOCluster),
    fuelType: Array.from(result.fuelType),
    insuranceType: Array.from(result.insuranceType),
    DEF: result.DEF,
    DET: result.DET,
  };
};

const ImportPayoutGrid = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    variants: [],
    Make: [],
    Model: [],
    RTOCluster: [],
    insuranceCompany: [],
    insuranceType: [],
    fuelType: [],
  });
  const [fileData, setFileData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const response = validateHeadersExcelPayout(headers);

      if (!response?.valid) {
        setError(response);
        return;
      }
      const rows = jsonData.slice(1);

      const transformedData = rows.reduce((acc, row) => {
        if (row.length === 0) return acc;

        const transformedRow = Object.fromEntries(
          row
            .map((cell, index) => {
              const header = headers[index];

              if (
                header.toLowerCase().includes("def") ||
                header.toLowerCase().includes("det")
              ) {
                const date = XLSX.SSF.parse_date_code(cell);
                const formattedDate = `${date.y}-${String(date.m).padStart(
                  2,
                  "0"
                )}-${String(date.d).padStart(2, "0")}`;
                return [header, formattedDate];
              }

              return [header, cell];
            })
            .filter(([key, value]) => key !== undefined && value !== undefined)
        );

        acc.push(transformedRow);
        return acc;
      }, []);

      const uniqueData = Array.from(
        new Set(
          transformedData.map((row) => {
            const { Reciveable, "SNo.": _, ...rest } = row;
            return JSON.stringify(rest);
          })
        )
      ).map((uniqueRow) => {
        return transformedData.find((row) => {
          const { "SNo.": _, Reciveable, ...rest } = row;
          return JSON.stringify(rest) === uniqueRow;
        });
      });

      setHeaders(headers);
      setFileData(uniqueData);
      const resultData = transformData(uniqueData);
      setData(resultData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const fileType = file ? file.name.split(".").pop().toLowerCase() : "";

      if (fileType === "xls" || fileType === "xlsx") {
        parseExcel(file);
      } else {
        alert("Please upload a XLSX, or XLS file.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getDataForPayoutGridImport = async () => {
    try {
      setLoading(true);
      const { data: response } = await axiosInstance.post(
        "/PayoutGrid/getDataForPayoutGridImport",
        data
      );
      if (response && response.data) {
        const {
          insuranceCompany,
          policyType,
          insuranceType,
          fuelType,
          makes,
          model,
          variants,
          RTOCluster,
        } = response.data;

        const validationError = [];
        const mappingsInsuranceType = createMapping(
          insuranceType,
          "InsuranceType",
          "_id"
        );

        const insCompMapping = createMapping(
          insuranceCompany,
          "AliasName",
          "_id"
        );
        const rtoclusterMapping = createMapping(RTOCluster, "GroupName", "_id");
        const policyTypeMapping = createMapping(
          policyType,
          "PolicyTypeName",
          "_id"
        );
        const fuelTypeMapping = createMapping(fuelType, "FuelType", "_id");

        const mapping = {
          Make: createMapping(makes, "Make", "_id"),
          Model: createMapping(model, "Model", "_id"),
        };

        const result = fileData.map((acc) => {
          const insCompany = acc["Insurance Company"]?.toLowerCase() || "";
          const RTOCluster = acc["RTO Cluster"]?.toLowerCase() || "";
          const policyType = acc["Policy Type"]?.toLowerCase() || "";
          const fuelType = acc["Fuel Type"]?.toLowerCase() || "";
          const basedOn = acc["Based On"] || "";
          const businessType = Business_TypeData[acc["Business Type"]];
          const imtType = IMPT_TYPE[acc["IMT Type"]];
          const ncbstatus = NCB_STATUS[acc["NCB Status"]];
          const customerType = CustomeType[acc["Customer Type"]];
          const nilldepth = NillDepth[acc["Nil Dep"]];
          const PAType = PA_TYPE[acc["PA"]];
          const insCompId = insCompMapping?.[insCompany] || null;
          const rtoClusterId = rtoclusterMapping?.[RTOCluster] || null;
          const policyTypeId = policyTypeMapping?.[policyType] || null;

          if (acc["IMT Type"] && !imtType) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "IMT Type",
              fieldValue: acc["IMT Type"],
              row: { ...acc, errMsg: "Invalid IMT Type !" },
            });
          }

          if (acc["PA"] && !PAType) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "PA",
              fieldValue: acc["PA"],
              row: { ...acc, errMsg: "Invalid PA !" },
            });
          }

          if (acc["NCB Status"] && !ncbstatus) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "NCB Status",
              fieldValue: acc["NCB Status"],
              row: { ...acc, errMsg: "Invalid NCB Status !" },
            });
          }

          if (acc["Nil Dep"] && !nilldepth) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "Nil Dep",
              fieldValue: acc["Nil Dep"],
              row: { ...acc, errMsg: "Invalid Nil Dep!" },
            });
          }

          if (acc["Customer Type"] && !customerType) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "Customer Type",
              fieldValue: acc["Customer Type"],
              row: { ...acc, errMsg: "Invalid Customer Type !" },
            });
          }

          if (acc["Business Type"] && !businessType) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "Business Type",
              fieldValue: acc["Business Type"],
              row: { ...acc, errMsg: "Invalid Business Type !" },
            });
          }

          if (!acc["Insurance Company"] || !insCompId) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "Insurance Company",
              fieldValue: acc["Insurance Company"],
              row: { ...acc, errMsg: "Insurance Company not found!" },
            });
          }
          if (!acc["RTO Cluster"] || !rtoClusterId) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "RTO Cluster",
              fieldValue: acc["RTO Cluster"],
              row: { ...acc, errMsg: "RTO Cluster not found!" },
            });
          }

          if (acc["Policy Type"] && !policyTypeId) {
            validationError.push({
              valid: false,
              id: `${acc?.["SNo."]}`,
              fieldName: "Policy Type",
              fieldValue: acc["Policy Type"],
              row: { ...acc, errMsg: "Policy Type not found!" },
            });
          }

          return {
            ...acc,
            CCFrom: acc["CC From"],
            CCTo: acc["CC To"],
            GVWFrom: acc["GVW From"],
            nillDepth: acc["Nil Dep"],
            GVWTo: acc["GVW To"],
            seaterFrom: acc["Seater From"],
            seaterTo: acc["Seater To"],
            BasedOn: basedOn,
            ODDFrom: acc?.["ODD (From)"],
            ODDTo: acc?.["ODD (To)"],
            NCBSTATUS: acc["NCB Status"],
            CustomerType: acc["Customer Type"] || "",
            IMTType: acc["IMT Type"],
            BusinessType: acc["Business Type"],
            InsuranceCompany: insCompId,
            RTOCluster: rtoClusterId,
            PolicyType: policyTypeMapping?.[policyType] || null,
            FuelType: fuelTypeMapping?.[fuelType] || null,
            DEF: acc?.["DEF (From)"],
            DET: acc?.["DET (To)"],
            FromAge: acc?.["From Age"],
            ToAge: acc?.["To Age"],
            Reciveable:
              basedOn === "OD+TP" || basedOn === "OD+TP (Exc. PA)"
                ? parseFloat(acc?.["Reciveable"]?.split(",")[0]?.trim()) || 0
                : parseFloat(acc?.["Reciveable"]) || 0,
            ReciveableTP:
              basedOn === "OD+TP" || basedOn === "OD+TP (Exc. PA)"
                ? parseFloat(acc?.["Reciveable"]?.split(",")[1]?.trim()) || 0
                : undefined,
          };
        });

        const finalData = Object.values(result);
        let updatedData = finalData;

        const mapWithObj = insuranceType.reduce((acc, curr) => {
          const { _id } = curr;
          acc[_id] = curr;
          return acc;
        }, {});

        const { data: insTypeUpdate, error: insTypeErr } =
          updatePayoutInsuranceType(
            mappingsInsuranceType,
            updatedData,
            mapWithObj
          );

        updatedData = insTypeUpdate;

        if (insTypeErr.length > 0) {
          validationError.push(...insTypeErr);
        }

        const { data: variantUpdate, error: variantErr } = updateVariant(
          variants,
          updatedData,
          mapping
        );

        if (variantErr.length > 0) {
          validationError.push(...variantErr);
        }

        const { data: clusterUpdate, error: clusterErr } = updateRTOCluster(
          RTOCluster,
          variantUpdate
        );

        if (clusterErr.length > 0) {
          validationError.push(...clusterErr);
        }

        const backendData = response.data.payoutGrids;

        clusterUpdate.forEach((item) => {
          const found = backendData.find((backendItem) => {
            const backendGVWFrom = backendItem["GVWFrom"] ?? 0;
            const backendGVWTo = backendItem["GVWTo"] ?? 0;
            const itemGVWFrom = item["GVWFrom"] ?? 0;
            const itemGVWTo = item["GVWTo"] ?? 0;

            const isGVWRangeAcceptable =
              backendGVWFrom === 0 && backendGVWTo === 0
                ? true
                : !(itemGVWTo <= backendGVWFrom || itemGVWFrom >= backendGVWTo);

            const backendCCFrom = backendItem["CCFrom"] ?? 0;
            const backendCCTo = backendItem["CCTo"] ?? 0;
            const itemCCFrom = item["CCFrom"] ?? 0;
            const itemCCTo = item["CCTo"] ?? 0;

            const isCCRangeAcceptable =
              backendCCFrom === 0 && backendCCTo === 0
                ? true
                : !(itemCCTo <= backendCCFrom || itemCCFrom >= backendCCTo);

            const backendSeaterFrom = backendItem["seaterFrom"] ?? 0;
            const backendSeaterTo = backendItem["seaterTo"] ?? 0;
            const itemSeaterFrom = item["seaterFrom"] ?? 0;
            const itemSeaterTo = item["seaterTo"] ?? 0;

            const isSeaterRangeAcceptable =
              backendSeaterFrom === 0 && backendSeaterTo === 0
                ? true
                : !(
                    itemSeaterTo <= backendSeaterFrom ||
                    itemSeaterFrom >= backendSeaterTo
                  );

            const backendODDFrom = backendItem["ODDFrom"] ?? 0;
            const backendODDTo = backendItem["ODDTo"] ?? 0;
            const itemODDFrom = item["ODDFrom"] ?? 0;
            const itemODDTo = item["ODDTo"] ?? 0;

            const isODDRangeAcceptable =
              backendODDFrom === 0 && backendODDTo === 0
                ? true
                : !(itemODDTo <= backendODDFrom || itemODDFrom >= backendODDTo);

            const backendAgeFrom = backendItem["FromAge"] ?? 0;
            const backendAgeTo = backendItem["ToAge"] ?? 0;
            const itemAgeFrom = item["FromAge"] ?? 0;
            const itemAgeTo = item["ToAge"] ?? 0;

            const isAgeRangeAcceptable =
              backendAgeFrom === 0 && backendAgeTo === 0
                ? true
                : !(itemAgeTo <= backendAgeFrom || itemAgeFrom >= backendAgeTo);

            return (
              (item["DEF"] || "") === (backendItem["DEF"] || "") &&
              (item["DET"] || "") === (backendItem["DET"] || "") &&
              (item["BasedOn"] || "") === (backendItem["BasedOn"] || "") &&
              (item["BusinessType"] || "") ===
                (backendItem["BusinessType"] || "") &&
              (item["InsuranceCompany"]?.trim().toLowerCase() || "") ===
                (backendItem["InsuranceCompany"]?.trim().toLowerCase() || "") &&
              (item["PolicyType"]?.trim().toLowerCase() || "") ===
                (backendItem["PolicyType"]?.trim().toLowerCase() || "") &&
              (item["FuelType"]?.trim().toLowerCase() || "") ===
                (backendItem["FuelType"]?.trim().toLowerCase() || "") &&
              (item["MakeModal"]?.trim().toLowerCase() || "") ===
                (backendItem["MakeModal"]?.trim().toLowerCase() || "") &&
              (item["Model"]?.trim().toLowerCase() || "") ===
                (backendItem["Model"]?.trim().toLowerCase() || "") &&
              (item["Variant"]?.trim().toLowerCase() || "") ===
                (backendItem["Variant"]?.trim().toLowerCase() || "") &&
              (item["InsuranceType"] || "") ===
                (backendItem["InsuranceType"] || "") &&
              (item["RTOCluster"] || "") === (backendItem["RTOGroup"] || "") &&
              (item["IMTType"] || "") === (backendItem["IMTType"] || "") &&
              (item["NCBSTATUS"] || "") === (backendItem["NCBSTATUS"] || "") &&
              (item["PA"] || "") === (backendItem["PA"] || "") &&
              (item["Customer Type"] || "") ===
                (backendItem["CustomerType"] || "") &&
              isGVWRangeAcceptable &&
              isCCRangeAcceptable &&
              isSeaterRangeAcceptable &&
              isODDRangeAcceptable &&
              isAgeRangeAcceptable
            );
          });

          if (found) {
            validationError.push({
              valid: false,
              id: `${item?.["SNo."]}`,
              fieldName: "RTO Cluster",
              fieldValue: item["RTO Cluster"],
              row: { ...item, errMsg: "Data already exists" },
            });
          }
        });

        if (validationError.length > 0) {
          const uniqueArray = [];
          const seen = new Map();

          for (const obj of validationError) {
            if (!seen.has(obj.id)) {
              seen.set(obj.id, true);
              uniqueArray.push(obj);
            }
          }

          setError({
            message: "Validation failed!",
            data: uniqueArray,
            errorList: validationError,
          });
          return;
        }

        // Post to backend
        const postResponse = await axiosInstance.post(
          "/PayoutGrid/postManyPayoutGrid",
          { entries: clusterUpdate }
        );

        if (postResponse?.data?.success) {
          ToastSusses(postResponse?.data?.message);
        }
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || "An error occurred.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = (event) => {
    event.preventDefault();

    const fileUr = `${REACT_APP_FileURL}/images/Template/PayoutGrid-Template.xlsx`;
    window.open(fileUr, "_blank");
  };

  return (
    <>
      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ListDesigncol">
                        <h4 className="card-title">Import Payout Grid</h4>
                      </div>
                      <button
                        onClick={handleTemplateDownload}
                        className="btn mybtn"
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  {error && (
                    <Alert severity="error">
                      {error?.message && (
                        <AlertTitle>Error : {error?.message}</AlertTitle>
                      )}
                      {error?.missingHeaders &&
                        error?.missingHeaders?.length > 0 && (
                          <AlertTitle>
                            {error?.missingHeaders.join(", ")}
                          </AlertTitle>
                        )}
                    </Alert>
                  )}
                  <div className="container">
                    <div>
                      <div className="upload-container">
                        <input
                          type="file"
                          id="file_upload"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          color="warning"
                          type="button"
                          // disabled={loading || error || fileData.length === 0}
                          disabled={loading || fileData.length === 0}
                          onClick={getDataForPayoutGridImport}
                        >
                          {loading ? "In Progress..." : "Import"}
                        </button>
                      </div>
                    </div>
                    {error && error?.data && error?.data?.length !== 0 && (
                      <div className="mt-8">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Preview Error Data
                        </div>
                        <div
                          // className=" p-2 container"
                          style={{
                            zoom: "80%",
                            height: "60vh",
                            border: "2px solid black",
                            resize: "vertical", // Allow vertical resizing only
                            overflow: "auto",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr>
                                {headers &&
                                  headers.length > 0 &&
                                  headers.map((header) => {
                                    let hasError;
                                    if (error && error?.errorList) {
                                      hasError = error?.errorList.find(
                                        (error) => error.fieldName === header
                                      );
                                    }
                                    return (
                                      <th
                                        style={{
                                          backgroundColor: hasError
                                            ? "#f8d7da"
                                            : "",
                                        }}
                                        scope="col"
                                      >
                                        {header}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {error?.data.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {headers.map((item, index) => {
                                      let hasError;
                                      if (error && error?.errorList) {
                                        hasError = error?.errorList.find(
                                          (err) =>
                                            err?.fieldValue === data?.row[item]
                                        );
                                      }

                                      return (
                                        <td
                                          style={{
                                            backgroundColor:
                                              hasError &&
                                              hasError?.fieldValue ===
                                                data?.row[item]
                                                ? "#f8d7da"
                                                : "",
                                          }}
                                          key={index}
                                        >
                                          {data?.row[item]}
                                          {hasError &&
                                            hasError?.fieldValue ===
                                              data?.row[item] && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {hasError?.row?.errMsg || ""}
                                              </div>
                                            )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default ImportPayoutGrid;
