import React, { useEffect, useState } from "react";
import { ToastError, ToastInfo, ToastSusses } from "../../Utils/Tost";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Select from "react-select";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Purchase.css";
import { convertDateStringToDate, reversDate } from "../../Utils/usefullFunction";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import DateRange from "../DateRangeModule/DateRange";
const CreatePurchase = () => {
  const today = new Date();
  const navigate = useNavigate();
  const lastDay = today.toISOString().substr(0, 10);
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [EnterDate, setEnterDate] = useState(lastDay);
  const [inputData, setInput] = useState();
  const [loading, setLoading] = useState(false);
  const [Remark, setRemark] = useState(value?.Remark || "");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  // const [TDSPercent, setTDSPercent] = useState(value?.TDSPercent || 0);
  // const [TDSAccount, setTDSAccount] = useState({
  //   _id: "",
  //   Name: "-- Select TDS Account --",
  // });
  const [Broker, setBroker] = useState({
    _id: "",
    Name: "-- Select Broker --",
  });

  const [BrokerOpt, setBrokerOpt] = useState([]);
  const [DebitAccount, setDebitAccount] = useState({
    _id: "",
    Name: "-- Select Debit Account --",
  });
  const getBroker = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Broker/getBrokerOpt");
      setBrokerOpt([
        {
          _id: "",
          Name: "-- Select Broker --",
        },
        ...data,
      ]);
    } catch (error) { }
  };
  const [DebitAccountOpt, setDebitAccountOpt] = useState([]);
  // const [TDSOpt, setTDSOpt] = useState([]);
  const [Data, setData] = useState([]);
  const [POSOpt, setPOSOpt] = useState([]);
  const [SelectedPolicyOpt, setSelectedPolicyOpt] = useState([]);

  const [POS, setPOS] = useState({
    _id: "",
    Name: "-- Select POS --",
  });

  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalAmountOfSelectPolicy, setTotalAmountOfSelectPolicy] = useState(0);

  const getPos = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        showFiled: {
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          GSTINNumber: 1,
          _id: 1,
          PayoutCycle: 1
        },
      });
      setPOSOpt([
        {
          _id: "",
          Name: "-- Select POS --",
        },
        ...data,
      ]);
    } catch (error) { }
  };
  const getApprovedPolicy = async ({ POS, Broker }) => {
    setLoading(true);
    let que = { POS };

    if (inputData) {
      que["inputData"] = inputData;
    }
    if (Broker) {
      que["Broker"] = Broker;
    }
    if (StartDate) {
      que["StartDate"] = StartDate;
    }
    if (EndDate) {
      que["EndDate"] = EndDate;
    }

    try {
      const {
        data: { data = [], TotalAmount = {} },
      } = await axiosInstance.post("/Purchase/getIsPurchase", {
        ...que,
      })
      setTotalAmount(TotalAmount);
      
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPos();
  }, []);

  const selectPolicyHandler = (isChecked, _id, Amount = 0) => {
    if (isChecked) {
      setSelectedPolicyOpt((pre) => [...pre, _id]);
      setTotalAmountOfSelectPolicy((pre) => pre + Amount);
    } else {
      const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);
      SelectedPolicyOpt.splice(SelectedPolicy, 1);
      setSelectedPolicyOpt([...SelectedPolicyOpt]);
      setTotalAmountOfSelectPolicy((pre) => pre - Amount);
    }
  };

  const checkIsPresentOrNot = (_id) => {
    const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);

    return SelectedPolicy === -1 ? false : true;
  };

  const SelectAllDeleteAll = (isChecked) => {
    if (!isChecked) {
      setSelectedPolicyOpt([]);
      setTotalAmountOfSelectPolicy(0);
    } else {
      let Total = 0;
      const selectALL = Data.map((ele) => {
        let amount = ele?.Commission?.TotalAmountToPay ?? 0;
        Total = Total + amount;
        return ele?._id;
      });
      setSelectedPolicyOpt([...selectALL]);
      setTotalAmountOfSelectPolicy(Total);
    }
  };

  const handleCreate = async () => {
    if (!DebitAccount._id) {
      return ToastInfo("Debit Account is Required");
    }
    // if (!TDSAccount._id) {
    //   return ToastInfo("TDS Account is Required");
    // }
    const AmountToGive = TotalAmountOfSelectPolicy;
    const isGSTNumber =
      POS?.GSTINNumber && POS?.GSTINNumber.length === 15 ? true : false;

    let GstAmount = 0;
    // let TDSAmount = Math.round((AmountToGive * TDSPercent) / 100);

    if (isGSTNumber) {
      GstAmount = Math.round(AmountToGive * (18 / 100));
    }
    const NetAmount = Math.round(AmountToGive + GstAmount);

    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Purchase", {
        CreditAccount: POS._id,
        DebitAccount: DebitAccount._id,
        // TDSAccount: TDSAccount._id,
        EnterDate,
        Remark,
        // TDSPercent,
        TotalAmount: AmountToGive,
        NetAmount,
        SelectedPolicyOpt,
        // TDSAmount,
        GstAmount,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Purchase");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };


  const getLedgerMaster = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/LedgerEntry/getDebitAccounts`);

      setDebitAccountOpt(data);
    } catch (error) {
      
    }
  };
  const setDateHandler = (ranges) => {

    setEndDate(convertDateStringToDate(ranges.endDate))
    setStartDate(convertDateStringToDate(ranges.startDate))

  }
  const getLedgerMasterForTds = async () => {
    try {
      // const {
      //   data: { data },
      // } = await axiosInstance.get(
      //   `/LedgerEntry?Group=648ac8ca7df926cfdd2ddccb`
      // ); // 	Duties and Tax
      // setTDSOpt(data);
    } catch (error) { }
  };


  useEffect(() => {
    getLedgerMaster();
    getLedgerMasterForTds();
    getBroker()
  }, []);

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          {!POS._id ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="selectPosDiv">
                <Select
                  className="select-BoxUi"
                  classNamePrefix="select"
                  options={POSOpt}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => {
                    const Name = `${option["Salutation"] ?? ""}${option["MiddleName"] ?? ""
                      } ${option["Name"] ?? ""} ${option["LastName"] ?? ""}`;
                    return Name;
                  }}
                  onChange={(ele) => {
                    getApprovedPolicy({ POS: ele._id });
                    setPOS(ele);
                  }}
                  value={POS}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div
                className="card-body CreateCard"
              // style={{
              //   height: "30vh",
              // }}
              >
                <h4 className="card-title">
                  {/* {Mode ? "Edit Receipt" : "Create Receipt"} */}
                  Create Purchase
                </h4>
                <hr />
                <br />
                <div className="row mx-2 ">
                  <div className="col-md-4 align-items-center">
                    <label>Credit Account</label>
                    <input
                      disabled={true}
                      className="form-control "
                      type="input"
                      value={`${POS["Salutation"] ?? ""} ${POS["MiddleName"] ?? ""
                        } ${POS["Name"] ?? ""} ${POS["LastName"] ?? ""}`}
                      placeholder="Enter Receipt"
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>EnterDate</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="date"
                      name="EnterDate"
                      value={EnterDate}
                      onChange={(e) => setEnterDate(e.target.value)}
                      placeholder="Enter Receipt "
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Debit Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select Debit Account --",
                        },
                        ...DebitAccountOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setDebitAccount}
                      value={DebitAccount}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Remark</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="Remark"
                      value={Remark}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Remark"
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Payout Cycle</label>
                    <select
                      class="form-select"
                      name="PayoutCycle"
                      aria-label="Default select example"

                      value={POS?.PayoutCycle}
                      disabled={true}
                      required
                    >
                      <option value={1}>Daily</option>
                      <option value={7}>Weekly</option>
                      <option value={10}>10 Days</option>
                      <option value={15}>15 Days</option>
                      <option value={30}>Monthly</option>
                    </select>
                  </div>
                  {/* <div className="col-md-4 align-items-center">
                    <label>TDS Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select TDS Account --",
                        },
                        ...TDSOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setTDSAccount}
                      value={TDSAccount}
                    />
                  </div> */}
                  {/* <div className="col-md-4 align-items-center">
                    <label>TDS Percent</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="number"
                      name="TDSPercent"
                      value={TDSPercent}
                      onChange={(e) => setTDSPercent(e.target.value)}
                      placeholder="Remark"
                      required //done
                    />
                  </div> */}
                </div>
              </div>
              <div className="card-body CreateCard">
                {/* <div
                  className="p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div className="d-flex gap-3">
                      <SearchInputFilter
                        setInput={setInput}
                        inputData={inputData}
                        PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                      />
                      <div className="col">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button btn mybtn"
                          table="table-to-xls"
                          filename={`Billing-oF-${EnterDate}-${
                            POS["Salutation"] ?? ""
                          }${POS["MiddleName"] ?? ""}${POS["Name"] ?? ""}${
                            POS["LastName"] ?? ""
                          }`}
                          sheet="tablexls"
                          buttonText="Download as XLS"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div
                  className=" p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div style={{
                    display: "flex",
                    gap: "10px"
                  }}>



                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="form-control "
                        type="date"
                        name="startDate"
                        value={StartDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required //done
                      />

                      <p>TO</p>
                      <input
                        className="form-control "
                        type="date"
                        name="endDate"
                        value={EndDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required //done
                      />
                          <DateRange setDateHandler={setDateHandler} />
                    </div>
                    <div className="">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={BrokerOpt}
                        // defaultValue={{
                        //   _id:"-- Please Select Variant --"
                        // }}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        value={Broker}
                        onChange={(ele) => {
                          setBroker(ele);
                        }}
                        styles={{ height: "42px" }}
                      />
                    </div>

                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => getApprovedPolicy({ Broker: Broker._id, POS: POS._id, StartDate, EndDate })}
                    >
                      <MdSend />
                    </div>
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // setEndDate(lastDay);
                        // setStartDate(firstDay);
                        // getDataEr();
                      }}
                    >
                      <BsEraser />
                    </div>
                  </div>
                </div>

                <div className="PolicyCounts">
                  <div className="PolicyCounts-Item" style={{
                    display: "flex",
                    gap: "5px"
                  }}>
                    <div>
                      <b>Selected Policy :-</b> {SelectedPolicyOpt.length}
                    </div>
                  </div>

                  <div className="PolicyCounts-Item">
                    {" "}
                    <b>Total Amount :-</b> {TotalAmountOfSelectPolicy}
                  </div>
                </div>

                {loading ? (
                  <div
                    className=" p-2 "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <div
                    className=" p-2 "
                    style={{
                      zoom: "70%",
                    }}
                  >
                    {Data && (
                      <table
                        class="table table-striped   table-bordered  overflow-x mt-3"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="selectBoxForTable">
                              <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                                value="Car"
                                checked={
                                  SelectedPolicyOpt.length === Data.length
                                }
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                                onClick={(e) => {
                                  SelectAllDeleteAll(e.target.checked);
                                }}
                              />
                            </th>
                            <th scope="col">S/N </th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">Vehicle No.</th>

                            <th scope="col">Amount To Pay</th>
                          </tr>
                          {/* <tr>
                            <th scope="col" className="selectBoxForTable">
                              <p>#</p>
                              <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                                value="Car"
                                checked={
                                  SelectedPolicyOpt.length === Data.length
                                }
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                                onClick={(e) => {
                                  SelectAllDeleteAll(e.target.checked);
                                }}
                              />
                            </th>
                            <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">Vehicle No.</th>

                            <th scope="col">Amount To Pay</th>
                          </tr>
                          </tr> */}
                        </thead>

                        <tbody>
                          {Data &&
                            Data?.length !== 0 &&
                            Data?.map((data, index) => (
                              <tr key={data._id}>
                                <th scope="row" className="selectBoxForTable">
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    name="vehicle2"
                                    value="Car"
                                    key={data._id}
                                    checked={checkIsPresentOrNot(data._id)}
                                    style={{
                                      width: "23px",
                                      height: "23px",
                                    }}
                                    onClick={(e) => {
                                      selectPolicyHandler(
                                        e.target.checked,
                                        data._id,
                                        data?.Commission?.TotalAmountToPay
                                      );
                                    }}
                                  />
                                </th>
                                <th scope="row">
                                  <p>{index + 1}</p>
                                </th>
                                <th>{reversDate(data?.IssueDate)}</th>
                                <th>
                                  <tr> {data?.InsuranceCompany?.Name}</tr>
                                  <tr> {data?.InsuranceType?.InsuranceType}</tr>
                                  <tr> RSD:-{reversDate(data?.RSD)}</tr>
                                  <tr> {data?.InsureeName}</tr>
                                  <tr> {data?.Broker?.Name}</tr>
                                </th>

                                <th>
                                  <tr>
                                    <Link
                                      to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      {data?.PolicyNumber}
                                    </Link>{" "}
                                  </tr>
                                  <tr> {data?.MakeModal?.Name}</tr>

                                  <tr> {data?.Variant}</tr>
                                  <tr> YOM- {data?.YearOfManufacture}</tr>
                                  <tr> {data?.PolicyType?.PolicyTypeName}</tr>
                                </th>

                                <th>
                                  <tr>Gross </tr>
                                  <tr>Net </tr>
                                  <tr>TP</tr>
                                  <tr>OD</tr>
                                  <tr>Flat</tr>
                                </th>

                                <th>
                                  <tr>
                                    <br />
                                  </tr>
                                  {data?.BasedOn.includes("NET") ? (
                                    <tr>
                                      {data?.Commission?.CommissionToPayNET /
                                        10 +
                                        "X NET"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("TP") ? (
                                    <tr>
                                      {data?.Commission?.CommissionToPayTP /
                                        10 +
                                        "X TP"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("OD") ? (
                                    <tr>
                                      {data?.Commission?.CommissionToPayOD /
                                        10 +
                                        "X OD"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("FLAT") ? (
                                    <tr>
                                      {data?.Commission?.CommissionToPayFLAT +
                                        "FLAT"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                </th>

                                <th>
                                  <tr> ₹ {Math.round(data?.GrossPremium)}</tr>
                                  <tr> ₹ {data?.NETPremium}</tr>
                                  <tr> ₹ {data?.TPPremium}</tr>
                                  <tr> ₹ {data?.ODPremium}</tr>
                                </th>
                                <th>
                                  <tr>
                                    {" "}
                                    {data?.PaymentMode?.TypeOfPaymentMode ??
                                      data?.PaymentMode}
                                  </tr>
                                  <tr> {data?.PolicyType?.Name}</tr>
                                  <tr> {data?.VehicleNumber}</tr>
                                  {/* <tr> {data?.ODPremium}</tr> */}
                                </th>
                                <th>
                                  <tr>
                                    {" "}
                                    ₹ {data?.Commission?.TotalAmountToPay}
                                  </tr>
                                </th>
                              </tr>
                            ))}
                          <tr>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>

                            <th scope="row">
                              ₹ {TotalAmount?.AmountToPay ?? 0}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      ></div>
                    }
                  </div>
                )}

                <div className="d-flex gap-3 ">
                  <button
                    type="submit"
                    className="btn mybtn"
                    // style={{ marginTop: 28 }}
                    onClick={() => {
                      setTotalAmount(0);
                      setData([]);
                      setTotalAmountOfSelectPolicy(0);
                      setSelectedPolicyOpt([]);
                      setPOS({
                        _id: "",
                        Name: "-- Select POS --",
                      });
                      setBroker({
                        _id: "",
                        Name: "-- Select Broker --",
                      })
                      setEndDate("")
                      setStartDate("")
                    }}
                  >
                    Go Back
                  </button>

                  {!view && (
                    <button
                      type="submit"
                      className="btn mybtn"
                      // style={{ marginTop: 28 }}
                      onClick={() => {
                        handleCreate();
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreatePurchase;
