import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
} from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
const CreateBank = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [AccountNumber, setAccountNumber] = useState(
    value?.AccountNumber || ""
  );
  const [Branch, setBranch] = useState(value?.Branch?._id || "");
  const [BranchData, setBranchData] = useState();
  const [Balance, setBalance] = useState(value?.Balance || "");

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Bank", {
        Name,
        AccountNumber,
        Balance: Math.round(Balance),
        Branch,
      });

      
      if (success) {
        ToastSusses(message);
        navigate("/admin/Bank/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Bank/${value?._id}`, {
        Name,
        AccountNumber,
        Balance: Balance,
        Branch,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/Bank/data");
      } else {
        ToastSusses(message);
      }

      // navigate("/admin/Bank/data");
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getBranch = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Branch");

      setBranchData(data);
    } catch (error) {}
  };
  useEffect(() => {
    getBranch();
  }, []);
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <h4 className="card-title">
                    {view
                      ? "View Bank"
                      : Mode
                      ? "Edit Bank"
                      : "Create New Bank"}
                  </h4>
                  <hr />
                  <br />
                  <div className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item="" className="outer">
                        <div
                          className="col-md-6 col-12"
                          style={{ float: "left", padding: 15 }}
                        >
                          <label className="form-label" htmlFor="formname">
                            Bank Name
                          </label>
                          <input
                            disabled={view}
                            className="form-control "
                            type="text"
                            name="Name"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Bank Name"
                            required //done
                          />
                        </div>
                        <div
                          className="col-md-6 col-12"
                          style={{ float: "left", padding: 15 }}
                        >
                          <label className="form-label" htmlFor="formemail">
                            Account Number
                          </label>
                          <input
                            disabled={view}
                            className="form-control "
                            type="text"
                            name="AccountNumber"
                            value={AccountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            placeholder="AccountNumber"
                            required //done
                          />
                        </div>
                        <div
                          className="col-md-6 col-12 align-items-center"
                          style={{ float: "left", padding: 15 }}
                        >
                          <label>Branch</label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setBranch(e.target.value)}
                            value={Branch}
                            disabled={view}
                            required
                          >
                            {BranchData &&
                              BranchData.map((data) => (
                                <option value={data._id}>
                                  {data.BranchName}
                                </option>
                              ))}
                            {/* <option value="self">self</option> */}
                          </select>
                        </div>
                        <div
                          className="col-md-6 col-12"
                          style={{ float: "left", padding: 15 }}
                        >
                          <label className="form-label" htmlFor="formemail">
                            Opening Balance
                          </label>
                          <input
                            disabled={view}
                            className="form-control "
                            type="number"
                            name="Balance"
                            value={Balance}
                            onChange={(e) => setBalance(e.target.value)}
                            placeholder="Balance"
                            required //done
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/Bank/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateBank;
