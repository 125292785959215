import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useLocation } from "react-router-dom";
const ReportHOC = ({ ReportComponent, isWhat }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const For = queryParams.get("For");

  if (For) {
    isWhat = For;
  }
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row" style={{ marginTop: "70px", zoom: "85%" }}>
          <div className="col-2">
            <div
              className=" text-center "
              style={{
                marginTop: "1.5rem",
              }}
            >
              <div
                style={{
                  boxShadow: "rgb(201, 201, 201) 1px -1px 6px 1px",

                  backgroundColor: "#bbbbbb3d",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <h4 className="mt-2">Reports</h4>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                  className="isReportFiled"
                >
                  <div
                    className={isWhat === "EnteredBy" ? "CategoryButton" : ""}
                  >
                    <Link
                      to="/admin/Entry/Report"
                      class={
                        isWhat === "EnteredBy"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Policy Entry
                    </Link>
                  </div>
                  <div
                    className={isWhat === "ApprovedBy" ? "CategoryButton" : ""}
                  >
                    <Link
                      to="/admin/Approved/Report"
                      class={
                        isWhat === "ApprovedBy"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Approved
                    </Link>
                  </div>
                  <div className={isWhat === "POS" ? "CategoryButton" : ""}>
                    <Link
                      to="/admin/POS/Report"
                      className={
                        isWhat === "POS"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      POS
                    </Link>
                  </div>
                  <div
                    className={
                      isWhat === "InsuranceCompany" ? "CategoryButton" : ""
                    }
                  >
                    <Link
                      to="/admin/Company/Report"
                      class={
                        isWhat === "InsuranceCompany"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Company
                    </Link>
                  </div>
                  <div className={isWhat === "Branch" ? "CategoryButton" : ""}>
                    <Link
                      to="/admin/Branch/Report"
                      class={
                        isWhat === "Branch"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Branch
                    </Link>
                  </div>
                  <div className={isWhat === "Broker" ? "CategoryButton" : ""}>
                    <Link
                      to="/admin/Broker/Report"
                      class={
                        isWhat === "Broker"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Broker
                    </Link>
                  </div>
                  <div
                    className={
                      isWhat === "ReportingManger" ? "CategoryButton" : ""
                    }
                  >
                    <Link
                      to="/admin/ReportingManger/Report"
                      class={
                        isWhat === "ReportingManger"
                          ? "text-white text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        //   fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Reporting Manger
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-10">{ReportComponent}</div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ReportHOC;
