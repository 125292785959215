import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Footer from "../Footer/Footer";

import Header from "../Header/Header";
import Select from "react-select";
const ViewContra = () => {
  const MASTER_DB = ["CASH", "BANK"];
  const { id } = useParams();
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);

  const [EntryDate, setEntryDate] = useState(lastDay);

  const [Remark, setRemark] = useState("");

  const [Contra, setContra] = useState([]);
  // value?.Contra
  const [FromModal, setFromModal] = useState();
  const [CashBankAccount, setCashBankAccount] = useState();
  // const [PartyAccountOptFor2, setPartyAccountOptFor2] = useState([]);

  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/Contra/single/${id}`);
      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      setContraHandler(value?.Contra);
      setEntryDate(value?.EntryDate);
      setCashBankAccountHandler(value);
    } catch (error) {}
  };

  const setContraHandler = (Contra) => {
    const myArr = Contra?.map((ele) => {
      const { LedgerEntry: Entry, PartyAccount: Account, _id, ...rest } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }

      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      return obj;
    });

    setContra(myArr ?? []);
  };
  const setCashBankAccountHandler = (value) => {
    const { LedgerEntry: Entry, CashBankAccount: Bank } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (Bank) {
      const { _id, Name } = Bank;
      obj = {
        _id,
        Name,
      };
    }
    setCashBankAccount(obj);
  };
  useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>View Contra</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  //   onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter Contra "
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>Credit Account</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  value={CashBankAccount}
                />
             
              </div>
              <div className="col-md-3 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  //   onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Debit Account</th>

                        <th scope="col">Amount</th>
                        <th scope="col">Reff. No.</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {Contra &&
                        Contra.length > 0 &&
                        Contra?.map((ele, index) => (
                          <tr>
                            <td scope="row">{index + 1}</td>
                            <td>{ele?.Name || ele?.PartyAccount?.Name}</td>
                            <td>{ele?.Account}</td>
                            <td>{ele?.RefNumber}</td>
                          </tr>
                        ))}
                      <tr>
                        <th scope="row"></th>
                        <td>Total</td>
                        <td>
                          {Contra &&
                            Contra.length > 0 &&
                            Contra.reduce((sum, a) => sum + a.Account, 0)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <Link className="btn mybtn" to="/admin/Contra/data">
              Go Back
            </Link>
            <Link
              className="btn mybtn"
              to={`/admin/Contra?_id=${id}&Mode=true`}
            >
              Edit
            </Link>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewContra;
