import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { BiSearchAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { convertDateStringToDate, coverAmountIntoCurrent } from "../../Utils/usefullFunction";
import Select from "react-select";
import DateRange from "../DateRangeModule/DateRange";
const ERPReport = ({ ReportOf = "POS" }) => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [loading, setLoading] = useState(false);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [data, setData] = useState();
  const [PolicyCount, setPolicyCount] = useState();
  const [BranchOpt, setBranchOpt] = useState([]);
  const [Branch, setBranch] = useState({
    _id: "",
    BranchName: "-- Select Branch --",
  });
  const setDateHandler = (ranges) => {

    setEndDate(convertDateStringToDate(ranges.endDate))
    setStartDate(convertDateStringToDate(ranges.startDate))

  }

  const [POS, setPOS] = useState({
    _id: "",
    Name: "-- Select POS --",
  });
  const [POSOpt, setPOSOpt] = useState([]);
  const getBranch = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Branch`);
      setBranchOpt([
        {
          _id: "",
          BranchName: "-- Select Branch --",
        },
        ...data,
      ]);
    } catch (error) { }
  };
  const getPos = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        showFiled: {
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });

      const POS = data.map(
        ({
          Name = "",
          Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
            _id,
          };
        }
      );
      setPOSOpt([
        {
          _id: "",
          Name: "-- Select POS --",
        },
        ...POS,
      ]);
    } catch (error) { }
  };
  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { data, PolicyCount },
      } = await axiosInstance.get(
        `/Policy/CompanyReportOfPolicy?startDate=${StartDate}&endDate=${EndDate}&ReportOf=${ReportOf}&POS=${POS._id}&Branch=${Branch._id}`
      );

      setData(data);
      setPolicyCount(PolicyCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    if (ReportOf === "POS") {
      getBranch()
      getPos()
    }

  }, [ReportOf]);

  return (
    <>
      {/* <Header /> */}

      <div className="row">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div
              className="col-lg-8"
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "baseline",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <input
                className="form-control "
                type="date"
                style={{
                  maxWidth:"150px"
                }}
                name="startDate"
                value={StartDate}
                onChange={(e) => setStartDate(e.target.value)}
                // placeholder="Enter  PolicyNumber"
                required //done
              />

              <p>-</p>
              <input
                className="form-control "
                type="date"
                name="endDate"
                style={{
                  maxWidth:"150px"
                }}
                value={EndDate}
                onChange={(e) => setEndDate(e.target.value)}
                // placeholder="Enter  PolicyNumber"
                required //done
              />
                <DateRange setDateHandler={setDateHandler} />
              {ReportOf === "POS" && <>
                <div className="">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={POSOpt}
                    // defaultValue={{
                    //   _id:"-- Please Select Variant --"
                    // }}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["Name"]}
                    onChange={setPOS}
                    value={POS}
                    styles={{ height: "42px" }}
                  />
                </div>
                <div className="">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={BranchOpt}
                    // defaultValue={{
                    //   _id:"-- Please Select Variant --"
                    // }}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["BranchName"]}
                    value={Branch}
                    onChange={setBranch}
                    styles={{ height: "42px" }}
                  />
                </div>
              </>}
            </div>
            <div className="">
              <BiSearchAlt
                size={32}
                onClick={() => getData()}
                style={{
                  backgroundColor: "#174379",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  marginTop: "2px",
                }}
              />
              &nbsp;
              <Link>
                <RxReset
                  onClick={() => {
                    setStartDate(firstDay);
                    setEndDate(lastDay);
                    setBranch({
                      _id: "",
                      BranchName: "-- Select Branch --",
                    })
                    setPOS({
                      _id: "",
                      Name: "-- Select POS --",
                    })
                  }}
                  size={32}
                  style={{
                    backgroundColor: "#E8960F",
                    color: "#fff",
                    padding: "5px",
                    borderRadius: "5px",
                    marginTop: "2px",
                  }}
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-10" style={{ float: "left" }}></div>
            <div className="col-2" style={{ float: "left" }}></div>
          </div>
          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <>
              <div
                class="table-responsive"
                style={{
                  zoom: "85%",
                }}
              >
                <table class="table table-striped table-bordered mt-3">
                  <thead>
                    <tr>
                      <th scope="col" style={{ textAlign: "center" }}>
                        S/No.
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        Name
                      </th>
                      <th scope="row">Count</th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        GrossPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        NETPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        ODPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        TPPremium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        Commisionable Premium
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        TotalPolicyProfit
                      </th>
                      <th scope="row" style={{ textAlign: "center" }}>
                        TotalAmountToPay
                      </th>

                      <th scope="row" style={{ textAlign: "center" }}>
                        Profit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((ele, index) => {
                        const {
                          Name = "",
                          Count = 0,
                          GrossPremium,
                          NETPremium,
                          ODPremium,
                          TPPremium,
                          PolicyProfit,
                          TotalAmountToPay,
                          TotalPolicyProfit,
                          CommisionablePremium,
                        } = ele;
                        return (
                          <tr key={ele._id}>
                            <td> {index + 1}</td>
                            {/* <td>{Name}</td> */}
                            <td><Link to={`/admin/Check/Commission?_id=${ele._id}&StartDate=${StartDate}&EndDate=${EndDate}&For=${ReportOf}`}>{Name}</Link></td>
                            <td>{Count}</td>
                            <td>{coverAmountIntoCurrent(GrossPremium)}</td>
                            <td>{coverAmountIntoCurrent(NETPremium)}</td>
                            <td>{coverAmountIntoCurrent(ODPremium)}</td>
                            <td>{coverAmountIntoCurrent(TPPremium)}</td>
                            <td>{coverAmountIntoCurrent(CommisionablePremium)}</td>
                            <td>{coverAmountIntoCurrent(TotalPolicyProfit)}</td>
                            <td>{coverAmountIntoCurrent(TotalAmountToPay)}</td>
                            <td> <b>{coverAmountIntoCurrent(PolicyProfit)}</b></td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td> </td>
                      <th>Total:</th>
                      <th>{PolicyCount?.Count}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.GrossPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.NETPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.ODPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.TPPremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.CommisionablePremium)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.TotalPolicyProfit)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.TotalAmountToPay)}</th>
                      <th>{coverAmountIntoCurrent(PolicyCount?.PolicyProfit)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        {/* end col */}
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default ERPReport;
