import React, { useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const arrayToObject = (array) => {
  return array.reduce((acc, it) => {
    return { ...acc, [it["Name"]]: it["value"] };
  }, {});
};
const CreateRBAC = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [Roll, setRoll] = useState(value?.Roll || "");
  const [Description, setDescription] = useState(value?.Description || "");
  const [AllRoles, setAllRoles] = useState([
    // { Name: "Read", value: value?.Read ?? true },
    // { Name: "Create", value: value?.Create ?? false },
    // { Name: "Edit", value: value?.Edit ?? false },
    // { Name: "Delete", value: value?.Delete ?? false },
    // { Name: "Download", value: value?.Download ?? false },
    { Name: "Masters", value: value?.Masters ?? false },
    { Name: "Data Entry", value: value?.Policy ?? false },
    { Name: "Vouchers", value: value?.Vouchers ?? false },
    { Name: "QC", value: value?.QC ?? false },
    { Name: "Books", value: value?.Books ?? false },
    { Name: "Reports", value: value?.Reports ?? false },
    
    { Name: "CP Margin", value: value?.CPMargin ?? false },
  ]);
  const navigate = useNavigate();
  const handleCreate = async () => {
    if (!Roll) {
      return ToastWarn("Roll is required");
    }
    const MyRollRoles = arrayToObject(AllRoles);
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/RBAC", {
        Roll,
        Description,
        ...MyRollRoles,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/AccessControl/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    if (!Roll) {
      return ToastWarn("Roll is required");
    }
    const MyRollRoles = arrayToObject(AllRoles);
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/RBAC/${value?._id}`, {
        Roll,
        Description,
        ...MyRollRoles,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/AccessControl/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const HandleRoll = (index) => {
    const MyRoles = [...AllRoles];
    MyRoles[index]["value"] = !MyRoles[index]["value"];
    setAllRoles([...MyRoles]);
  };
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              zoom: "90%",
            }}
          >
            <h5>
              {view
                ? "View Access Control"
                : Mode
                  ? "Edit Access Control"
                  : "Create New Access Control"}
            </h5>
            <hr />

            <div className="row">
              <div className="col-6">
                <div className="m-3">
                  <label className="form-label" htmlFor="formname">
                    Roll Name
                  </label>
                  <input
                    className="form-control "
                    type="text"
                    name="Roll"
                    value={Roll}
                    onChange={(e) => setRoll(e.target.value)}
                    placeholder="Enter Roll Name"
                    required //done
                    disabled={view}
                  />
                </div>
                <div className="m-3">
                  <label className="form-label" htmlFor="formemail">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    value={Description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={view}
                  />

                  {/* <input
                                            className="form-control "
                                            type="text"
                                            name="Description"
                                            value={Description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Description Name"
                                            required //done
                                            disabled={view}
                                        /> */}
                </div>
              </div>
              <div
                className="col-6"
                style={{
                  borderLeft: "1px solid gray",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr auto",
                  }}
                >
                  {AllRoles &&
                    AllRoles.map((roles, index) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          padding: "1.4rem",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "emoji",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                        key={roles.Name}
                      >
                        <label>{roles.Name}</label>
                        <input
                          className=""
                          style={{
                            height: "17px",
                            width: "17px",
                          }}
                          type="checkbox"
                          name={roles.Name}
                          checked={roles.value}
                          onClick={() => HandleRoll(index)}
                          // placeholder="Description Name"
                          // required //done
                          disabled={view}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                type="submit"
                className="btn mybtn"
                onClick={() => navigate("/admin/masters/AccessControl/data")}
              >
                Go Back
              </button>
              &nbsp;
              {!view && (
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={() => {
                    Mode ? handleEdit() : handleCreate();
                  }}
                >
                  Submit
                </button>
              )}
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateRBAC;
