import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/Footer";
import { BiEdit } from "react-icons/bi";
import Header from "../Header/Header";
import Select from "react-select";
import { coverAmountIntoCurrent } from "../../Utils/usefullFunction";
const CreateGeneralVoucher = () => {
  const MASTER_DB = ["LedgerGroup", "POS", "Broker", "Employee"];
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const Mode = Boolean(queryParams.get("Mode"));
  const [EntryDate, setEntryDate] = useState(lastDay);
  const [IsCrDr, setIsCrDr] = useState("CR");
  const [TotalCR, setTotalCR] = useState(0);
  const [TotalDR, setTotalDR] = useState(0);

  //   const [CashBankAccount, setCashBankAccount] = useState(
  //     value?.CashBankAccount || ""
  //   );
  const [Remark, setRemark] = useState("");
  const [From, setFrom] = useState();
  //   const [CashBankAccountOpt, setCashBankAccountOpt] = useState([]);

  const [PartyAccount, setPartyAccount] = useState({
    _id: "",
    Name: "-- Select --",
  });
  const [PartyAccountOpt, setPartyAccountOpt] = useState();
  const [Account, setAccount] = useState(0);
  const [RefNumber, setRefNumber] = useState("");

  const [GeneralVoucher, setGeneralVoucher] = useState([]);
  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      if (TotalCR !== TotalDR) {
        return ToastError("DR Amount and CR Amount is not Equal");
      }
      if (RefNumber || Account || From) {
        return ToastWarn("Add to List First");
      }
      //   if (!CashBankAccount) {
      //     return ToastWarn("Cash Bank Account is Required");
      //   }

      const {
        data: { success, message },
      } = await axiosInstance.post("/General", {
        EntryDate,
        // CashBankAccount,
        Remark,
        GeneralVoucher,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Journal/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    if (TotalCR !== TotalDR) {
      return ToastError("DR Amount and CR Amount is not Equal");
    }
    if (RefNumber || Account || From) {
      return ToastWarn("Add to List First");
    }
    try {
      //   if (!CashBankAccount) {
      //     return ToastWarn("Cash Bank Account is Required");
      //   }
      const {
        data: { success, message },
      } = await axiosInstance.put(`/General/${_id}`, {
        EntryDate,
        // CashBankAccount,
        Remark,
        GeneralVoucher,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Journal/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const removeHandler = (index) => {
    const GeneralArr = GeneralVoucher;
    GeneralArr.splice(index, 1);
    setGeneralVoucher([...GeneralArr]);
  };

  const editHandler = (data, index) => {
    if (Account || RefNumber || Account) {
      return ToastWarn("Please Add To List First");
    }

    if (data.From === "POS") {
      if (data?.PartyAccount) {
        setPartyAccount({ _id: data?.PartyAccount, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    if (data.From === "Employee") {
      if (data?.Employee) {
        setPartyAccount({ _id: data?.Employee, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    if (data.From === "Broker") {
      if (data?.Broker) {
        setPartyAccount({ _id: data?.Broker, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
    }
    if (data.From === "LedgerGroup") {
      if (data?.LedgerEntry) {
        setPartyAccount({ _id: data?.LedgerEntry, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }

    getPos(data.From);
    setAccount(data.Account);
    setRefNumber(data.RefNumber);
    setFrom(data.From);
    setIsCrDr(data.IsCrDr);
    if (data.IsCrDr === "CR") {
      setTotalCR((pre) => pre - Number(data.Account));
    } else {
      setTotalDR((pre) => pre - Number(data.Account));
    }
    const GeneralArr = GeneralVoucher;
    GeneralArr.splice(index, 1);
    setGeneralVoucher([...GeneralArr]);
  };
  const addHandler = () => {
    if (!PartyAccount || !Account) {
      return ToastWarn("Please enter a valid field");
    }

    const que = {};
    const PartyAccountData = PartyAccount;

    if (From === "POS") {
      que["PartyAccount"] = PartyAccountData._id;
    } else if (From === "LedgerGroup") {
      que["LedgerEntry"] = PartyAccountData._id;
    } else if (From === "Broker") {
      que["Broker"] = PartyAccountData._id;
    } else if (From === "Employee") {
      que["Employee"] = PartyAccountData._id;
    }
    // if (From === "POS") {
    //   que["PartyAccount"] = PartyAccountData._id;
    // }
    // if (From === "LedgerGroup") {
    //   que["LedgerEntry"] = PartyAccountData._id;
    // }

    if (IsCrDr === "CR") {
      que["CR"] = Account;
      que["DR"] = 0;

      setTotalCR((pre) => pre + Number(Account));
    } else {
      que["DR"] = Account;
      que["CR"] = 0;
      setTotalDR((pre) => pre + Number(Account));
    }

    if (!PartyAccountData?._id) {
      return ToastWarn("Please enter a Party Account");
    }
    setGeneralVoucher([
      ...GeneralVoucher,
      {
        ...que,
        Account: Number(Account),
        RefNumber,
        Name: PartyAccountData.Name,
        From: From,
        IsCrDr: IsCrDr,
      },
    ]);
    setPartyAccount({
      _id: "",
      Name: "-- Select --",
    });

    setIsCrDr("CR");
    setFrom("");
    setRefNumber("");
    setAccount(0);
  };

  const getBank = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Bank");

      //   setCashBankAccountOpt(data);
    } catch (error) {}
  };

  const getPos = async (isData) => {
    try {
      if (isData === "Broker") {
        const {
          data: { data: Broker },
        } = await axiosInstance.get("/Broker");

        const LedgerGroup = Broker.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
      if (isData === "Employee") {
        const {
          data: { data: EmployeeDetails },
        } = await axiosInstance.get("/Employee/selectBoxDropdown");

        const EMP = EmployeeDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
            };
          }
        );
        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...EMP,
        ]);
      }
      if (isData === "POS") {
        const {
          data: { data: posDetails },
        } = await axiosInstance.post("/Pos/getPosByField", {
          showFiled: {
            Name: 1,
            Salutation: 1,
            MiddleName: 1,
            LastName: 1,
            _id: 1,
          },
        });

        const POS = posDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
            };
          }
        );
        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...POS,
        ]);
      }

      if (isData === "LedgerGroup") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBank();
  }, []);

  const GeneralVoucherHandler = (GeneralVoucher) => {
    const myArr = GeneralVoucher?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Employee: EmployeeDetails,
        Broker: BrokerDetails,
        _id,
        ...rest
      } = ele;

      let obj;

      if (EmployeeDetails) {
        const { _id: Employee, Name } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name,
        };
      }
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }
      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      return obj;
    });

    const TotalDr = GeneralVoucher.reduce((sum, a) => sum + Number(a.DR), 0);
    const TotalCr = GeneralVoucher.reduce((sum, a) => sum + Number(a.CR), 0);
    setTotalCR(TotalCr);
    setTotalDR(TotalDr);
    setGeneralVoucher(myArr);
  };
  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/General/single/${_id}`);

      setRemark(value?.Remark);
      setEntryDate(value?.EntryDate);
      GeneralVoucherHandler(value?.GeneralVoucher);
    } catch (error) {}
  };
  useEffect(() => {
    if (_id) {
      getDataById();
    }
  }, [_id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>{Mode ? "Edit Journal" : "Create Journal"}</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-4 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter General "
                  required //done
                />
              </div>

              <div className="col-md-4 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div className="col-md-3 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={From}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    getPos(e.target.value);
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label required>Select Account</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOpt}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setPartyAccount}
                  value={PartyAccount}
                />
                {/* <select
                 
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccount"
                  value={PartyAccount}
                  onChange={(e) => {
                    setPartyAccount(e.target.value);
                  }}
                  required
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOpt &&
                    PartyAccountOpt.map((data) => (
                      <option value={JSON.stringify(data)}>{data.Name}</option>
                    ))}
                </select> */}
              </div>
              <div className="col-md-3 align-items-center">
                <label required>Amount</label>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <input
                    className="form-control "
                    type="Number"
                    name="Amount"
                    value={Account}
                    onChange={(e) => setAccount(e.target.value)}
                    placeholder="Amount"
                    style={{
                      flex: 3,
                    }}
                    required //done
                  />
                  <select
                    style={{
                      flex: 1,
                    }}
                    class="form-select"
                    aria-label="Default select example"
                    name="PartyAccount"
                    value={IsCrDr}
                    onChange={(e) => {
                      setIsCrDr(e.target.value);
                    }}
                    required
                  >
                    <option value="CR">CR</option>
                    <option value="DR">DR</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 align-items-center">
                <label>Narration</label>
                <input
                  className="form-control "
                  type="text"
                  name="Ref Numbe"
                  value={RefNumber}
                  onChange={(e) => setRefNumber(e.target.value)}
                  placeholder="Enter Narration"
                  required //done
                />
              </div>
              <div
                className="col-md-3 align-items-center"
                style={{
                  paddingTop: "1.4rem",
                }}
              >
                <button
                  className="btn mybtn"
                  onClick={() => {
                    addHandler();
                  }}
                >
                  Add to list
                </button>
              </div>
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Account</th>
                        <th scope="col">Narration</th>
                        <th scope="col">DR.</th>
                        <th scope="col">CR.</th>
                        {/* <th scope="col">Amount</th> */}

                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {GeneralVoucher &&
                        GeneralVoucher.length > 0 &&
                        GeneralVoucher.map((ele, index) => (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{ele?.Name}</td>
                            <td>{ele?.RefNumber}</td>
                            <td>{coverAmountIntoCurrent(ele?.DR)}</td>
                            <td>{coverAmountIntoCurrent(ele?.CR)}</td>

                            {/* <td>{ele?.Account}</td> */}
                            <td>
                              <BiEdit
                                size={32}
                                style={{
                                  backgroundColor: "green",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => editHandler(ele, index)}
                              />
                              &nbsp;
                              <AiFillDelete
                                size={32}
                                style={{
                                  backgroundColor: "#A50406",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => {
                                  const isTrue = window.confirm(
                                    "Do you want to delete!"
                                  );
                                  if (isTrue) {
                                    removeHandler(index);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td></td>

                        <td>
                          {" "}
                          {coverAmountIntoCurrent(TotalDR)}
                          {/* {General.reduce((sum, a) => sum + Number(a.DR), 0)} */}
                        </td>

                        <td>
                          {coverAmountIntoCurrent(TotalCR)}
                          {/* {General.reduce((sum, a) => sum + Number(a.CR), 0)} */}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                className="btn mybtn "
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CreateGeneralVoucher;
