import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { REACT_APP_FileURL } from "../config";
import { ReturnFullName, reversDate } from "./usefullFunction";
const NewUnbilledCase = ({ fileName, filterDataHandlerForExcel }) => {
  const [loading, setLoading] = useState();
  const stateRTO = {
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chhattisgarh",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OD: "Odisha",
    OR: "Odisha",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UK: "Uttarakhand",
    WB: "West Bengal",
    UA: "Uttarakhand",
    // union Territories
    AN: "Andaman and Nicobar Islands",
    CH: "Chandigarh",
    DN: "Dadra and Nagar Haveli and Daman and Diu",
    DL: "Delhi",
    JK: "Jammu and Kashmir",
    LA: "Ladakh",
    LD: "Lakshadweep",
    PY: "Puducherry",
  };
  const KeyArray = [
    "Branch",
    "InsuranceCompany",
    "PolicyType",
    "PolicyNumber",
    "BUSINESS PROPOSAL DATE",
    "POS",
    "ISSUE DATE",
    "START DATE",
    "EXPIRY DATE",
    "InsureeName",
    "RTO STATE",
    "RTO CITY",
    "RTO CODE",
    "VehicleNumber",
    "DOR",
    "Category",
    "MakeModal",
    "Variant Name",
    "FUEL TYPE",
    "InsuranceUnderFlow",
    "Point Of Sale",
    "GrossPremium",
    "NETPremium",
    "TPPremium",
    "LL / CPA",
    "ODPremium",
    "CommisionablePremium",
    "BasedOn",
    "Point",
    "Percent",
    "PaymentMode",
    "BusinessType",
    "RM",
    "YOM",
    "NCB",
    "Broker",
    "OurPoint",
    "OurPercent",
    "EnteredBy",
    "ApprovedBy",
    "createdAt",
    "updatedAt",
    "Policy Copy",
  ];
  const downloadFile = async () => {
    try {
      setLoading(true);
      const excelData = await filterDataHandlerForExcel();

      const newArr = [];

      let MaxCountLengthOfInsuranceType = 0;
      excelData.forEach((value) => {
        const { InsuranceUnderFlow = [] } = value;
        MaxCountLengthOfInsuranceType = Math.max(
          MaxCountLengthOfInsuranceType,
          InsuranceUnderFlow.length
        );
      });

      let policyCopyUrl = "";
      const createInitialKey = () => {
        const obj = {};
        let startingIndex = 65;
        let addon = 0;
        KeyArray.forEach((ele, i) => {
          if (startingIndex > 90) {
            startingIndex = 65;
            addon++;
          }
          if (ele === "InsuranceUnderFlow") {
            for (
              let index = 1;
              index < MaxCountLengthOfInsuranceType;
              index++
            ) {
              obj[ReturnCharCode(startingIndex, addon)] = `SubCat${index}`;
              startingIndex++;
            }
            return;
          }
          if (ele === "Policy Copy") {
            console.log(
              ReturnCharCode(startingIndex, addon),
              "Policy Number Field"
            );
            policyCopyUrl = ReturnCharCode(startingIndex, addon);
          }

          obj[ReturnCharCode(startingIndex, addon)] = ele;
          startingIndex++;
        });

        newArr.push(obj);
      };
      createInitialKey();
      const createValues = async () => {
        await excelData.forEach(async (value) => {
          const obj = {};
          let startingIndex = 65;
          let addOn = 0;
          for (const key of KeyArray) {
            if (startingIndex > 90) {
              startingIndex = 65;
              addOn++;
            }

            if (key === "Branch") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.POS?.ReportingTo?.Branch?.BranchName;
              startingIndex++;
              continue;
            }
            // if (key === "POS") {
            //   obj[ReturnCharCode(startingIndex, addOn)] = ReturnFullName(
            //     value?.POS
            //   );
            //   startingIndex++;
            //   continue;
            // }
            if (key === "Policy Copy") {
              obj[
                ReturnCharCode(startingIndex, addOn)
              ] = `${REACT_APP_FileURL}${value?.NewPolicyCopy}`;
              startingIndex++;
              continue;
            }

            if (key === "START DATE") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.RSD
              );
              startingIndex++;
              continue;
            }
            if (key === "FUEL TYPE") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.FuelType;
              startingIndex++;
              continue;
            }
            if (key === "Variant Name") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.Variant;
              startingIndex++;
              continue;
            }
            if (key === "RTO CODE") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.VehicleNumber?.slice(0, 4);
              startingIndex++;
              continue;
            }
            if (key === "RTO CITY") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.RTO?.RTOName;
              startingIndex++;
              continue;
            }
            if (key === "RTO STATE") {
              const RTOCode = value?.VehicleNumber?.slice(
                0,
                2
              )?.toLocaleUpperCase();

              obj[ReturnCharCode(startingIndex, addOn)] = stateRTO[RTOCode];
              startingIndex++;
              continue;
            }
            if (key === "EXPIRY DATE") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.RED
              );
              startingIndex++;
              continue;
            }
            if (key === "PolicyType") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.PolicyType?.PolicyTypeName;
              startingIndex++;
              continue;
            }
            if (key === "PolicyNumber") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.PolicyNumber;
              startingIndex++;
              continue;
            }

            if (key === "Category") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.InsuranceUnderFlow[0]?.InsuranceType;
              startingIndex++;
              continue;
            }
            if (key === "DOR") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.RegistrationDate
              );
              startingIndex++;
              continue;
            }
            if (key === "YOM") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.YearOfManufacture;
              startingIndex++;
              continue;
            }
            if (key === "LL / CPA") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.LLPAPremium;
              startingIndex++;
              continue;
            }

            if (key === "OurPoint") {
              // myObj[key] = value?.Commission?.PolicyProfit ;
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.Commission?.TotalPolicyProfit;
              startingIndex++;
              continue;
            }
            if (key === "OurPercent") {
              let OurPercent = [];
              if (value["BasedOn"]) {
                value?.BasedOn?.split("+").forEach((element) => {
                  if (element === "FLAT") {
                    // let difference =
                    //   value["Commission"][`Reciveable${element}`] -
                    //   value["Commission"][`CommissionToPay${element}`];
                    let difference =
                      value["Commission"][`Reciveable${element}`];
                    OurPercent.push(difference + "FLAT");
                  }
                  if (element === "OD") {
                    // let difference =
                    //   value["Commission"][`Reciveable${element}`] -
                    //   value["Commission"][`CommissionToPay${element}`];

                    let difference =
                      value["Commission"][`Reciveable${element}`];
                    OurPercent.push(difference / 10 + "x OD");
                  }
                  if (element === "TP") {
                    // let difference =
                    //   value["Commission"][`Reciveable${element}`] -
                    //   value["Commission"][`CommissionToPay${element}`];
                    let difference =
                      value["Commission"][`Reciveable${element}`];
                    OurPercent.push(difference / 10 + "x TP");
                  }
                  if (element === "NET") {
                    // let difference =
                    //   value["Commission"][`Reciveable${element}`] -
                    //   value["Commission"][`CommissionToPay${element}`];
                    let difference =
                      value["Commission"][`Reciveable${element}`];
                    OurPercent.push(difference / 10 + "x NET");
                  }
                });
              }

              if (OurPercent.length > 0) {
                obj[ReturnCharCode(startingIndex, addOn)] =
                  OurPercent.join(" + ");
              }
              // myObj[`OurPercentOnFLAT`] = value?.Commission?.ReciveableFLAT ;
              // myObj[`OurPercentOnOD`] = value?.Commission?.ReciveableOD ;
              // myObj[`OurPercentOnTP`] = value?.Commission?.ReciveableTP ;
              startingIndex++;
              continue;
            }

            if (key === "Point Of Sale") {
              if (value["POS"]) {
                const {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = value["POS"];
                obj[
                  ReturnCharCode(startingIndex, addOn)
                ] = `${Salutation} ${Name} ${MiddleName} ${LastName}`;
                startingIndex++;
                continue;
              }
            }
            if (key === "RM") {
              if (value["POS"]) {
                const { ReportingTo } = value["POS"];

                if (ReportingTo) {
                  let {
                    Name = "",
                    Salutation = "",
                    MiddleName = "",
                    LastName = "",
                  } = ReportingTo;
                  obj[
                    ReturnCharCode(startingIndex, addOn)
                  ] = `${Salutation} ${Name} ${MiddleName} ${LastName}`;
                  startingIndex++;
                  continue;
                }
              }
            }
            if (key === "InsuranceCompany") {
              obj[ReturnCharCode(startingIndex, addOn)] = value[key]?.Name;
              startingIndex++;
              continue;
            }
            if (key === "Broker") {
              obj[ReturnCharCode(startingIndex, addOn)] = value[key]?.Name;
              startingIndex++;
              continue;
            }
            if (key === "MakeModal") {
              obj[ReturnCharCode(startingIndex, addOn)] = value[key]?.Name;
              startingIndex++;
              continue;
            }
            if (key === "ApprovedBy") {
              if (value[key]) {
                let {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = value[key];
                obj[
                  ReturnCharCode(startingIndex, addOn)
                ] = `${Salutation} ${Name} ${MiddleName} ${LastName}`;
                startingIndex++;
                continue;
              }
            }
            if (key === "EnteredBy") {
              if (value[key]) {
                let {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = value[key];
                obj[
                  ReturnCharCode(startingIndex, addOn)
                ] = `${Salutation} ${Name} ${MiddleName} ${LastName}`;
                startingIndex++;
                continue;
              }
            }
            if (key === "createdAt") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value[key].split("T")[0]
              );
              startingIndex++;
              continue;
            }
            if (key === "updatedAt") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value[key].split("T")[0]
              );
              startingIndex++;
              continue;
            }
            if (key === "BUSINESS PROPOSAL DATE" || key === "ISSUE DATE") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value["IssueDate"]
              );
              startingIndex++;
              continue;
            }

            if (key === "PaymentMode") {
              if (typeof value?.PaymentMode === "object") {
                obj[ReturnCharCode(startingIndex, addOn)] =
                  value?.PaymentMode?.TypeOfPaymentMode;
              } else {
                obj[ReturnCharCode(startingIndex, addOn)] = value?.PaymentMode;
              }

              startingIndex++;
              continue;
            }
            if (key === "CommisionablePremium") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.Commission?.CommisionablePremium ?? 0;

              startingIndex++;
              continue;
            }
            if (key === "Point") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.Commission?.TotalAmountToPay;
              startingIndex++;
              continue;
            }

            if (key === "Percent") {
              let Percent = [];
              if (value["BasedOn"]) {
                value?.BasedOn?.split("+").forEach((element) => {
                  if (element === "FLAT") {
                    Percent.push(
                      value["Commission"][`CommissionToPay${element}`] + "FLAT"
                    );
                  }
                  if (element === "OD") {
                    Percent.push(
                      value["Commission"][`CommissionToPay${element}`] / 10 +
                        "X OD"
                    );
                  }
                  if (element === "TP") {
                    Percent.push(
                      value["Commission"][`CommissionToPay${element}`] / 10 +
                        "X TP"
                    );
                  }
                  if (element === "NET") {
                    Percent.push(
                      value["Commission"][`CommissionToPay${element}`] / 10 +
                        "X NET"
                    );
                  }
                });
              }

              if (Percent.length > 0) {
                obj[ReturnCharCode(startingIndex, addOn)] = Percent.join(" + ");
              }
              startingIndex++;
              continue;
            }

            if (key === "InsuranceUnderFlow") {
              for (
                let index = 1;
                index < MaxCountLengthOfInsuranceType;
                index++
              ) {
                obj[ReturnCharCode(startingIndex, addOn)] =
                  value?.InsuranceUnderFlow[index]?.InsuranceType;
                startingIndex++;
              }
              continue;
            }
            obj[ReturnCharCode(startingIndex, addOn)] = value[key];

            startingIndex++;
          }

          newArr.push(obj);
        });
      };

      await createValues();

      const wb = XLSX.utils.book_new();

      const sheet = XLSX.utils.json_to_sheet(newArr, {
        skipHeader: true,
      });

      for (let index = 1; index <= newArr.length; index++) {
        sheet[`D${index}`].z = "0";

        const hyperlinkOptions = {
          Target: `${sheet[`${policyCopyUrl}${index}`].v}`, // Replace with your actual link
          Tooltip: "Click here to download Pdf",
        };
        sheet[`D${index}`].l = hyperlinkOptions;
        sheet[`${policyCopyUrl}${index}`].l = hyperlinkOptions;
        // sheet[`D${index}`].s = { alignment: { horizontal: 'right' } };
        // XLSX.utils.format_cell(sheet[`D${index}`] || {}).s = { alignment: { horizontal: 'right' } };
      }

      //

      XLSX.utils.book_append_sheet(wb, sheet, "Policy");
      const workbookBlob = workbook2blob(wb);
      var headerIndexes = [];
      newArr.forEach((data, index) =>
        data["A"] === "Sl No." ? headerIndexes.push(index) : null
      );

      const dataInfo = {
        titleCell: "A1",
        //   titleRange: "A1:AG2",
        tbodyRange: `A1:S${newArr.length}`,
        theadRange:
          headerIndexes?.length >= 1
            ? `A${headerIndexes[0] + 1}:S${headerIndexes[0] + 1}`
            : null,
      };

      return addStyle(workbookBlob, dataInfo).then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute("download", fileName);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    } catch (error) {
      console.log(error, "check this");
      setLoading(false);
    }
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("D").width(30);

        // if(dataInfo.tbodyRange){
        //     sheet.rang(dataInfo.tbodyRange).style({
        //         horizontalAlignment:"left"
        //     })
        // }
      });

      return workbook.outputAsync().then((workbookBlob) => {
        setLoading(false);
        return URL.createObjectURL(workbookBlob);
      });
    });
  };
  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wboutput = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wboutput)], {
      type: "application/octet-stream",
    });

    return blob;
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  return (
    <>
      {loading ? (
        <img
          src={require("../backend/utils/UiImages/Download.gif")}
          alt="My GIF"
          style={{
            width: "35px",
            height: "34px",
          }}
        />
      ) : (
        <Tooltip content="Download Excel">
          <AiOutlineFileExcel
            onClick={downloadFile}
            size={35}
            style={{
              backgroundColor: "green",
              color: "#fff",
              padding: "7px",
              borderRadius: "5px",
              width: "35px",
              height: "34px",
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

const ReturnCharCode = (startingIndex, addOn) => {
  if (addOn === 0) {
    return String.fromCharCode(startingIndex);
  }

  return `${String.fromCharCode(64 + addOn)}${String.fromCharCode(
    startingIndex
  )}`;
};

export default NewUnbilledCase;
