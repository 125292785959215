import React, { useEffect, useState } from "react";
import "./PurchaseBilling.css";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import { convertTimeToIndianTimeZone } from "../../Utils/usefullFunction";
const PurchaseBilling = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [TDSOpt, setTDSOpt] = useState([]);
  const [Remark, setRemark] = useState();
  const [TDSPercent, setTDSPercent] = useState(0);
  const [Data, setData] = useState([]);

  const [TotalAmount, setTotalAmount] = useState(0);
  const [POS, setPOS] = useState({});
  const [NetAmount, setNetAmount] = useState(0);
  const getSinglePurchase = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Purchase/getGenerateReport/${id}`);

      setRemark(data?.Remark);
      setTDSPercent(data?.TDSPercent);
      setTotalAmount(data?.TotalAmount);
      setNetAmount(data?.NetAmount);
      setPOS(data?.CreditAccount);
      setData(data?.Policy);
    } catch (error) {}
  };

  useEffect(() => {
    getSinglePurchase();
  }, []);
  return (
    <div
      className="container-fluid pos-bill-com-box"
      style={{
        margin: "1rem 0rem",
      }}
    >
      <div className="Heading">
        <p>Statement - June, 2020</p>
      </div>
      <div className="pos-details">
        <div className=" pos-address">
          <p>Sakshi Goyal</p>
          <p>108/1 sarrafa bazar panchdara east Muzaffarnagar U.P</p>
          <p>Muzaffarnagar - 251002</p>
        </div>
        <div className="pos-account">
          <div className="pos-account-data">
            <p>Tele RM: </p>
            <p>Pradeep Kumar</p>
          </div>
          <div className="pos-account-data">
            <p>Field RM: </p>
            <p>Gaurav Chawla</p>
          </div>
          <div className="pos-account-data">
            <p>Payout Period: </p>
            <p>1-June-2020 to 30-June-2020</p>
          </div>
          <div className="pos-account-data">
            <p>Processing Date: </p>
            <p>July 10, 2020</p>
          </div>
          <div className="pos-account-data">
            <p>GST No: </p>
            <p></p>
          </div>
          <div className="pos-account-data">
            <p>PAN No: </p>
            <p>ALE****90R</p>
          </div>
          <div className="pos-account-data">
            <p>Account Number: </p>
            <p>1588****2834 (ICICI BANK LIMITED)</p>
          </div>
          <div className="pos-account-data">
            <p>Account Number: </p>
            <p>1588****2834 (ICICI BANK LIMITED)</p>
          </div>
          <div className="pos-account-data">
            <p>IFSC Code: </p>
            <p>ICIC0001588</p>
          </div>
          <div className="pos-account-data">
            <p>IPOS Certification Date: </p>
            <p>Jun 20, 2017</p>
          </div>
        </div>

        <div></div>
      </div>
      <div className="Heading">
        <p>Commission Calculations</p>
      </div>
      <div className="table-Div">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Product</th>
              <th scope="col">Total Amount</th>
              <th scope="col">Commissionable Amount </th>
              <th scope="col">Payout</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Motor - June/2020</th>
              <td>Rs. 29,666.00</td>
              <td>Rs. 16,621.00</td>
              <td>Rs. 2,659.36</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pos-bill">
        <div className="pos-bill-data1"></div>
        <div className="pos-account">
          <div className="pos-account-data">
            <p>Amount Payable (a): </p>
            <p>Rs. 2,659.36</p>
          </div>
          <div className="pos-account-data">
            <p>TDS - Base (b) @ 5% of Rs. 2,493.15 </p>
            <p>Rs. 93.49</p>
          </div>
          <div className="pos-account-data">
            <p>TDS - ORC (c) @ 10% of Rs. 166.21 </p>
            <p>1Rs. 12.46</p>
          </div>
          <div className="pos-account-data">
            <p>Amount Transferred = a - b - c </p>
            <p>Rs. 2,553.40</p>
          </div>
        </div>
      </div>
      <div className="Heading">
        <p>Outstanding/Recovery</p>
      </div>
      <div className="table-Div">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Base Amount</th>
              <th scope="col">ORC Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Balance (till May, 2020 )</th>
              <td>Rs. 0.31</td>
              <td>Rs. -1,756.71</td>
              {/* <td>Rs. 2,659.36</td> */}
            </tr>
            <tr>
              <th scope="row">Balance (as of now) </th>
              <td>Rs. -0.04</td>
              <td>Rs. -1,602.96</td>
              {/* <td>Rs. 2,659.36</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="Heading">
        <p>Bank Transactions</p>
      </div>
      <p className="text-center p-2">(After TDS Deduction)</p>
      <div className="table-Div">
        <table class="table table-bordered">
          <thead>
            <tr>
              {/* <div> */}
              <p>1. Date: July 10, 2020</p>
              <p>Bank Ref. Num: CMS1536847909</p>
              <p>Amount: Rs. 2,400.00</p>
              <p>Remarks: Credit Successful</p>
              {/* </div> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Total Transferred Amount: Rs. 2,400.00</th>
            </tr>
            <tr>
              <th scope="row">Total Recovery Amount: Rs. -1,603.00</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="Heading">
        <p>Transaction Details</p>
      </div>
      <p className="text-center p-2">June 2020</p>
      <div className="table-Div">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reporting Date</th>
              <th scope="col">Product Type </th>
              <th scope="col">Identifier</th>
              <th scope="col">Commissionable Amount</th>
              <th scope="col">Percentage</th>
              <th scope="col">Amount Payable</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {Data &&
              Data.map((data) => (
                <tr>
                  <td> {convertTimeToIndianTimeZone(data?.createdAt)}</td>
                  <td> {data?.InsuranceType?.InsuranceType}</td>
                  <td>{data?.VehicleNumber}</td>

                  <td>
                    {data.BasedOn.split("+").map((ele) => {
                      let isFlat = ele === "FLAT" ? true : false;

                      return isFlat ? (
                        <tr>
                          ₹ {data["Commission"][`AmountToPay${ele}`]} {ele}
                        </tr>
                      ) : (
                        <tr>
                          ₹ {data[`${ele}Premium`] ?? ""} {ele}
                        </tr>
                      );
                    })}
                  </td>

                  <td>
                    {/* {data.BasedOn.split("+").map((ele) => {
                      if (ele === "FLAT") {
                        return <></>;
                      }
                      let percentage =
                        data["Commission"][`CommissionToPay${ele}`] ?? "";
                      if (!(String(percentage).length === 1)) {
                        percentage = percentage / 10
                      }
                      return <tr>{percentage}x</tr>;
                    })} */}
                    {data.BasedOn.split("+").map((ele) => {
                      if (ele === "FLAT") {
                        return <></>;
                      }
                      let percentage =
                        data["Commission"][`CommissionToPay${ele}`] ?? "";

                      return <tr>{percentage} %</tr>;
                    })}
                  </td>
                  <td>₹ {data?.Commission?.TotalAmountToPay} </td>
                  <td>{data?.Remark}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="Heading">
        <p>Terms and Conditions</p>
      </div>
      <div className="table-Div">
        <ul>
          <li>
            Only valid, successfully issued policies for the month in Renewbuy
            system will be considered for the payout calculation.
          </li>
          <li>
            Pending cases will flow over to subsequent month of its issuance in
            Renewbuy system.
          </li>
          <li>
            Any mismatch in payout calculation for the current month will be
            adjusted in the next month respective product cycle.
          </li>
          <li>
            Terms used : TW - Two wheeler, FW – Four wheeler Pvt Car, GCV –
            Goods carrying vehicle, PCV – Passenger carrying vehicle, MISC-d –
            Miscellaneous category vehicle, CV – Commercial vehicle, RPA - Road
            protect accidental, SME - Corporate SME .
          </li>
          <li>TravAssured policies will not be part of this payout.</li>
          <li>
            For Payout or POS certification related queries, please contact your
            Relationship Manager or follow the Call Manager option in your app.
            You can also call at our partner helpline number.
          </li>
          <li>
            For correction in bank details, please create Helpdesk ticket
            through your App
          </li>
          <li>
            Payout for Motor long term policies will be paid year on year basis
            on that particular OD portion for the year.
          </li>
          <li>
            For long term policies, slabs applicable as per that current year
            will be applicable for computation of payout.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PurchaseBilling;
