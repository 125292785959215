import React, { useState, useEffect, useCallback } from "react";
import { ToastSusses } from "../../Utils/Tost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../config";

import useHttpErrorHandler from "../Hooks/useHttpErrorHandler";

const AddMake = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { view } = state || {};

  const [Model, setModel] = useState("");

  const navigate = useNavigate();
  const httpErrorHandler = useHttpErrorHandler();

  const handleCreate = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/Model", {
        Model,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Model/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  const handleEdit = async () => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Model/${id}`, {
        Model,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Model/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  const getModalById = useCallback(
    async (id) => {
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/Model/${id}`);

        if (data) {
          setModel(data?.Model);
        }
      } catch (error) {
        httpErrorHandler(error);
      }
    },
    [httpErrorHandler]
  );

  useEffect(() => {
    if (id) {
      getModalById(id);
    }
  }, [id, getModalById]);

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="card-body">
              <h4 className="card-title">
                <h5>
                  {view ? "View Model" : id ? "Edit Model" : "Create Model"}
                </h5>
              </h4>
              <hr />
              <div data-repeater-item="" className="outer">
                <div className="col-6" style={{ float: "left", padding: 15 }}>
                  <label>Model Name</label>
                  <input
                    className="form-control "
                    type="text"
                    name="Model"
                    value={Model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Enter Model Name"
                    required //done
                    disabled={view}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/Model/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                onClick={() => {
                  id ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default AddMake;
